import { MassAndBalanceStationDataInterface } from "src/app/interfaces/data/mass-and-balance-station-data-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { MassAndBalanceStation } from "./mass-and-balance-station";
import { MassAndBalanceStationType } from "./mass-and-balance-station-type";

export class GenericMassAndBalanceStation extends MassAndBalanceStation {

    /**
     * This station type
     */
    public get type() : MassAndBalanceStationType
    {
        return "generic";
    }

    public get mass(): number
    {
        return this._mass;
    }

    public set mass(w : number)
    {
        if (this.maxMass > 0) {
            w = Math.min(w, this.maxMass);
        }
        if (w >= 0) {
            this._mass = w;
        }
        this.onChange.emit();
    }

    private _mass : number;

    /**
     * 
     * @param name The station name
     * @param arm The station arm in m
     * @param maxMass The maximum weight allowed. Leave zero to set no limit.
     */
    constructor(name : string, public readonly arm : number, public readonly maxMass : number = 0, public readonly defaultMass : number = 0)
    {
        super(name);
        this._mass = defaultMass;
    }

    public clone() : MassAndBalanceStation
    {
        const station = new GenericMassAndBalanceStation(this.name, this.arm, this.maxMass, this.defaultMass);
        station.mass = this.mass;
        return station;
    }

    public toDataInterface() : MassAndBalanceStationDataInterface
    {
        return {
            arm: this.arm,
            name: this.name,
            type: "generic",
            payloads: [this.maxMass],
            defaults: [this.defaultMass]
        };
    }

    public acceptMassAndBalanceVisitor(visitor: MassAndBalanceVisitorInterface)
    {
        visitor.visitGenericStation(this);
    }

    public static fromDataInterface(int : MassAndBalanceStationDataInterface) : GenericMassAndBalanceStation
    {
        return new GenericMassAndBalanceStation(int.name, int.arm, int.payloads[0], int.defaults[0]);
    }
}
