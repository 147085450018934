import { PositionInterface } from "../interfaces/position-interface";
import { Polygon } from "./polygon";
import { Position } from "./position";

export class Helpers {

    public static radsToDegs(rad : number) : number
    {
        return rad * (180 / Math.PI);
    }

    public static degsToRads(degs : number) : number
    {
        return degs * (Math.PI / 180);
    }

    public static ktsToMs(kts : number) : number
    {
        return kts / 1.94384;
    }

    public static msToKmh(ms : number) : number
    {
        return ms * 3.6;
    }

    /**
     * Convert MPH to M/S
     * 
     * @param mph the MPH
     * @returns the converted MPH to M/S
     * */
    public static mphToMs(mph : number) : number
    {
        return mph * 0.44704;
    }

    /**
     * Convert M/S to MPH
     * 
     * @param mph the M/S
     * @returns the converted M/S to MPH
     * */
     public static msToMph(ms : number) : number
     {
        return ms / 0.44704;
     }

    /** Feet per minute to m/s */
    public static fpmToMs(fpm : number): number
    {
        return Helpers.feetToMeters(fpm) / 60;
    }

    public static metersToFeet(meters : number) : number
    {
        return meters * 3.28084;
    }

    public static feetToMeters(feet : number) : number
    {
        return feet / 3.28084;
    }

    public static async sleep(ms : number) : Promise<void>
    {
        return new Promise<void>(resolve => {
            setTimeout(resolve, ms);
        });
    }

    public static parseICAOCoords(coords : string) : number
    {
        let c = coords.toUpperCase().match("(N|S)([0-9]{2})([0-9]{2})([0-9]{2})");
        if (c) {
            let lat = (parseInt(c[4]) / 60 + parseInt(c[3])) / 60 + parseInt(c[2]);
            return (c[1] == "N" ? lat : -lat);
        }

        c = coords.toUpperCase().match("(W|E)([0-9]{3})([0-9]{2})([0-9]{2})");
        if (c) {
            let lat = (parseInt(c[4]) / 60 + parseInt(c[3])) / 60 + parseInt(c[2]);
            return (c[1] == "E" ? lat : -lat);
        }

        console.warn("Helpers.parseICAOCoords: Cannot parse " + coords);
        return 0;
    }

    public static truncateString(string : string, maxlength : number) {
        if (string.length <= maxlength) {
            return string;
        }
        return string.substring(0, maxlength - 1) + "...";
    }

    public static formatNumber(n : number, decimals : number) : string
    {
        let pow = Math.pow(10, decimals);
        return (Math.round(n * pow) / pow).toFixed(decimals);
    }

    /**
     * Format milliseconds in the HHMM format.
     * 
     * @param milliseconds The milliseconds to be formatted
     * @param separator The HH MM separator. Default empty.
     */
    public static msToHourMinutes(milliseconds : number, separator : string = "") : string
    {
        if (milliseconds > 60000 * 60 * 99.99) {
            return "9999";
        }

        let totalMinutes = (milliseconds / 1000) / 60;
        let hours = Math.floor(totalMinutes / 60);
        let minutes = Math.round(totalMinutes % 60);

        return (hours < 10 ? "0" : "") + hours + separator + (minutes < 10 ? "0" : "") + minutes;
    }

    // Function to rotate a point.
    // pt = {x,y} of point to rotate, 
    // o = {x, y} of rotation origin, 
    // a = angle of rotation in degrees.
    // returns {x, y} giving the new point.
    public static rotatePoint(pt : PositionInterface, o : PositionInterface, a : number) : PositionInterface
    {

        var angle = a * (Math.PI/180); // Convert to radians
    
        var rotatedX = Math.cos(angle) * (pt.longitude - o.longitude) - Math.sin(angle) * (pt.latitude - o.latitude) + o.longitude;
    
        var rotatedY = Math.sin(angle) * (pt.longitude - o.longitude) + Math.cos(angle) * (pt.latitude - o.latitude) + o.latitude;  
    
        return { longitude: rotatedX, latitude: rotatedY};
    }

    /**
     * Get a percent
     * @param a partial
     * @param b total
     */
    public static percent(a : number, b : number) : number
    {
        return Math.round((a / b) * 100);
    }

    /**
     * Convert a string to lower case except the first letter
     * @param str The string to convert
     * @returns 
     */
    public static toCamelCase(str : string) : string
    {
        if (str.indexOf(" ") > -1) {
            const strs = str.split(" ");
            for (let i = 0; i < strs.length; i++) {
                strs[i] = Helpers.toCamelCase(strs[i]);
            }
            return strs.join(" ");
        }

        if (!str.length) {
            return str;
        }

        return str.replace(/\S*/g, function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
    }

    /**
     * Convert square meters to acres.
     * 
     * @param squareMeters The square meters
     * @returns The acres
     */
    public static squareMetersToAcres(squareMeters : number) : number
    {
        return squareMeters / 4047;
    }

    /**
     * Convert square meters to hectare.
     * 
     * @param squareMeters The square meters
     * @returns The hectare
     */
    public static squareMetersToHectares(squareMeters : number) : number
    {
        return squareMeters / 10000;
    }

    /**
     * Fill a polygon with a matrix of points equally spaced
     * 
     * @param polygon The polygon to fill
     * @param samplingDistance The distance on x and y between sampling points
     * @returns 
     */
    public static getPointsMatrix(polygon : Polygon, samplingDistance : number) : PositionInterface[]
    {
        let points = [];
        // Get the matrix of points
        let v = polygon.vertices;
        if (v.length > 3) {
            // x direction
            let x0 = Position.fromInterface(v[0]);
            let xHdg = x0.headingTo(v[1]);
            let xDist = x0.distanceTo(v[1]);
            let xStep = xDist / Math.max(2, Math.ceil(xDist / samplingDistance));
            let xStepsCount = xDist / xStep;

            // y direction
            let y0 = Position.fromInterface(v[1]);
            let yHdg = y0.headingTo(v[2]);
            let yDist = y0.distanceTo(v[2]);
            let yStep = yDist / Math.max(2, Math.ceil(yDist / samplingDistance));
            let yStepsCount = yDist / yStep;

            // Calculate the matrix of points
            for (let x = 0; x <= xStepsCount; x++) {
                let p = x0.offset(xHdg, x * xStep);
                for (let y = 0; y <= yStepsCount; y++) {
                    points.push({
                        latitude: p.latitude,
                        longitude: p.longitude
                    });
                    p = Position.fromInterface(p).offset(yHdg, yStep);
                }
            }
        }
        return points;
    }

    public static isCloseTo(a : number, b : number, err : number = 0.01) : boolean
    {
        return Math.abs(a - b) <= Math.abs(err);
    }
}
