import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthServiceInterface, TeamInterface } from 'src/app/interfaces/auth-service-interface';

@Injectable({
  providedIn: 'root'
})
export class NoAuthService implements AuthServiceInterface {

    onSignin: Observable<void>;
    onBeforeSignout: Observable<void>;
    signedIn: Observable<boolean>;

    constructor() {
        this.onSignin = new Subject<void>();
        this.onBeforeSignout = new Subject<void>();
        this.signedIn = new Observable<boolean>(subscriber => {
            subscriber.next(true);
        });
    }

    public getUserTeams(): TeamInterface[]
    {
        return [];
    }

    public get currentUserEmail(): string
    {
        return "";
    }
    
    async signInWithCredential(email: string, password: string): Promise<any> {}
    async signOut(): Promise<any> {}
    async sendPasswordRecoveryEmail(email : string): Promise<void> {}
    async recoverPasswordWithSecret(password: string, userId : string, secret : string): Promise<void> {}
    async updatePassword(oldPassword : string, password: string, control: string): Promise<void> {}
    async validateNewPassword(password: string, control: string): Promise<void> {}
}
