import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable } from 'rxjs';
import { AircraftDataInterface } from 'src/app/interfaces/data/aircraft-data-interface';
import { AirportDataInterface } from 'src/app/interfaces/data/airport-data-interface';
import { FlagDataInterface, FlagId } from 'src/app/interfaces/data/flag-data-interface';
import { MissionDataInterface } from 'src/app/interfaces/data/mission-data-interface';
import { PilotDataInterface } from 'src/app/interfaces/data/pilot-data-interface';
import { TargetDataInterface } from 'src/app/interfaces/data/target-data-interface';
import { StorageServiceInterface } from '../../interfaces/storage-service-interface';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements StorageServiceInterface {

    public get isOnline() : Observable<boolean>
    {
        return new Observable<boolean>(subscriber => {
            subscriber.next(true);
        });
    }

    constructor(private _storage: Storage) {
        this.init();
    }
    
    public getAllAircrafts(): Observable<AircraftDataInterface[]> {
        throw new Error('Method not implemented.');
    }

    public getFlag(key: FlagId): Observable<FlagDataInterface> {
        throw new Error('Method not implemented.');
    }
    
    public setFlag(key: FlagId, value: string | number | boolean): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public getAllTargets(): Observable<TargetDataInterface[]> {
        throw new Error('Method not implemented.');
    }

    public getTarget(id: string): Promise<TargetDataInterface> {
        throw new Error('Method not implemented.');
    }

    public saveTarget(target: TargetDataInterface): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public deleteTarget(target: TargetDataInterface): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public getAllMissions(): Observable<MissionDataInterface[]>
    {
        return new Observable<MissionDataInterface[]>(subscriber => {
            this.get("mission-ids").then(async (ids : string[]) => {
                ids = ids ?? [];
                let missions : MissionDataInterface[];
                for (let id of ids) {
                    missions.push(await this.getMission(id));
                }
                subscriber.next(missions);
            });
        });
    }

    public async getFreeMissionId() : Promise<string>
    {
        let ids = await this.get("mission-ids") ?? [];
        return "mission-" + ids.length;
    }

    public async getMission(mid: string): Promise<MissionDataInterface>
    {
        return await this.get("mission-" + mid);
    }
    
    public async saveMission(mission: MissionDataInterface): Promise<string>
    {
        let id = "mission-" + mission.id;
        this.set(id, mission);
        let ids : string[] = await this.get("mission-ids") ?? [];
        if (!ids.find(id => id == mission.id)) {
            ids.push(mission.id);
            this.set("mission-ids", ids);
        }
        return id;
    }

    public async deleteMission(missionId : string) : Promise<void>
    {
        let id = "mission-" + missionId;
        let ids : string[] = await this.get("mission-ids") ?? [];
        let index = ids.findIndex(id => missionId);
        if (index != -1) {
            ids.splice(index, 1);
            this.set("mission-ids", ids);
        }
        this.set(id, null);
    }

    private async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this._storage.create();
        this._storage = storage;
    }

    // Create and expose methods that users of this service can
    // call, for example:
    public set(key: string, value: any) {
        this._storage.set(key, value);
    }

    public get(key : string) : Promise<any>
    {
        return this._storage.get(key);
    }

    public getAllAirports(): Observable<AirportDataInterface[]> 
    {
        return new Observable(subscriber => {
            this.get("airport-ids").then(async (ids : string[]) => {
                ids = ids ?? [];
                let airports = [];
                for (let id of ids) {
                    let apt = await this.get("airport-" + id);
                    if (apt) {
                        airports.push(apt);
                    }
                }
                subscriber.next(airports);
            });
        });
    }

    public async getAirport(icao: string): Promise<AirportDataInterface> 
    {
        let apt = await this.get("airport-" + icao);
        if (!!apt) {
            return apt
        }
        return null;
    }

    public async saveAirport(airport: AirportDataInterface): Promise<void> 
    {
        let ids : string[] = await this.get("airport-ids") ?? [];
        if (ids.indexOf(airport.icao) == -1) {
            ids.push(airport.icao);
            this.set("airport-ids", airport.icao);
        }
        this.set("airport-" + airport.icao, airport);
    }

    public async deleteAirport(airport: AirportDataInterface): Promise<void> 
    {
        this.set("airport-" + airport.icao, null);
    }

    public getAllPilots(): Observable<PilotDataInterface[]> 
    {
        return new Observable(subscriber => {
            this.get("pilot-ids").then(async (ids : string[]) => {
                ids = ids ?? [];
                let airports = [];
                for (let id of ids) {
                    let apt = await this.get("pilot-" + id);
                    if (apt) {
                        airports.push(apt);
                    }
                }
                subscriber.next(airports);
            });
        });
    }

    public async getPilot(email: string): Promise<PilotDataInterface> 
    {
        let apt = await this.get("pilot-" + email);
        if (!!apt) {
            return apt
        }
        return null;
    }

    public async savePilot(pilot: PilotDataInterface): Promise<void> 
    {
        let ids : string[] = await this.get("airport-ids") ?? [];
        if (ids.indexOf(pilot.email) == -1) {
            ids.push(pilot.email);
            this.set("airport-ids", pilot.email);
        }
        this.set("airport-" + pilot.email, pilot);
    }

    public async deletePilot(pilot: PilotDataInterface): Promise<void> 
    {
        this.set("pilot-" + pilot, null);
    }
}
