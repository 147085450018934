import { EventEmitter } from "@angular/core";
import { MassAndBalanceAcceptorInterface } from "src/app/interfaces/mass-and-balance-acceptor-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { MassAndBalanceStationType } from "./mass-and-balance-station-type";

/**
 * An abstract class that defines a generic mass and balance station
 */
export abstract class MassAndBalanceStation implements MassAndBalanceAcceptorInterface {
    /**
     * The station mass in Kg
     */
    public abstract readonly mass : number;

    /**
     * The station arm in m
     */
    public abstract readonly arm : number;

    public abstract readonly type : MassAndBalanceStationType;

    public onChange : EventEmitter<void>

    /**
     * The moment of this station in kg*m
     */
    public get moment() : number
    {
        return this.mass * this.arm;
    }

    /**
     * Instantiate the abstract mass and balance station
     * @param name The station name
     */
    constructor(public readonly name : string)
    {
        this.onChange = new EventEmitter<void>();
    }
    
    public abstract acceptMassAndBalanceVisitor(visitor: MassAndBalanceVisitorInterface);

    public abstract clone() : MassAndBalanceStation;
}
