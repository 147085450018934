import { EventEmitter } from "@angular/core";
import { WaypointDataInterface } from "src/app/interfaces/data/waypoint-data-interface";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";
import { PositionInterface } from "src/app/interfaces/position-interface";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { Helpers } from "../helpers";
import { Polygon } from "../polygon";
import { Position } from "../position";
import { Waypoint } from "./waypoint";
import { WaypointType } from "./waypoint-type";

export class TurnWaypoint extends Waypoint {
    
    public get type(): WaypointType
    {
        return "turnpoint";
    }

    public get delayMs() : number
    {
        return 0;
    }

    public get boundarybox() : PolygonInterface
    {
        return this._box;
    }

    public get fixed() : boolean
    {
        return false;
    }

    public get name() : string
    {
        return this._name;
    }

    public onNameChanged : EventEmitter<void>;
    public onPositionChanged : EventEmitter<void>;
    
    private _box : Polygon;

    constructor(private _name : string, public readonly position : PositionInterface, public altitudeM : number = Helpers.feetToMeters(2000))
    {
        super();

        this.onPositionChanged = new EventEmitter<void>();
        this.onNameChanged = new EventEmitter<void>();
        const cPosition = Position.fromInterface(this.position);
        const range = 250;
        this._box = new Polygon([
            cPosition.offset(315, range),
            cPosition.offset(45, range),
            cPosition.offset(135, range),
            cPosition.offset(225, range)
        ]);
    }

    public setName(name : string)
    {
        this._name = name;
        this.onNameChanged.emit();
    }

    public setPosition(p : PositionInterface) 
    {
        this.position.latitude = p.latitude;
        this.position.longitude = p.longitude;
        this.onPositionChanged.emit();
    }

    public acceptWaypointVisitor(visitor: WaypointVisitorInterface): void {
        visitor.visitTurnWaypoint(this);
    }

    public static fromTurnpointDataInterface(int : WaypointDataInterface) : TurnWaypoint
    {
        if (int.type != "turnpoint") {
            throw "Invalid interface";
        }

        return new TurnWaypoint(int.name, int.position);
    }

    public toDataInterface() : WaypointDataInterface
    {
        return super.toGenericDataInterface("");
    }
}
