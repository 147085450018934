import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { Helpers } from '../classes/helpers';
import { Position } from '../classes/position';
import { Target } from '../classes/target';
import { PositionInterface } from '../interfaces/position-interface';
import { TargetDataInterface } from '../interfaces/data/target-data-interface';
import { AsyncTranslateService } from './async-translate.service';
import { TargetsGroup } from '../classes/targets-group';
import { kml } from '@mapbox/togeojson';
import { StorageService } from './storage/storage.service';
import { Polygon } from '../classes/polygon';
import { TargetInterface } from '../interfaces/target-interface';
import { Shapefile } from '../classes/filegenerators/shapefile';
import { Sensor } from '../classes/smartbay-configuration/sensor';

import * as JSZip from 'jszip';
import * as shapefile from 'shapefile';
import { FlowStatus } from '../classes/flow-status';

export interface TargetServiceInterface
{
    get(uuid : string) : Promise<TargetInterface>;
}

interface TargetsImportOptionsInterface {
    "filter"? : string[];
    "eraseDb"? : boolean;
    "validation"? : {
        "sensor": Sensor;
        "lensIndex": number;
        "gsd": number;
    }
    "updateCb"? : (status : string, loading : boolean) => Promise<void>;
};

@Injectable({
  providedIn: 'root'
})
export class TargetService implements TargetServiceInterface {

    /**
     * An observable object that returns the list of available targets.
     * The list is NOT optimized to reduce the airwork time on each target.
     */
    public targetsObservable : Observable<TargetInterface[]>;

    public get targets() : Promise<TargetInterface[]>
    {
        return new Promise<TargetInterface[]>(async resolve => {
            await this._targetsLoadedPromise;
            const sub = this.targetsObservable.subscribe(targets => {
                sub.unsubscribe();
                resolve(targets);
            });
        });
    }

    /**
     * True if the service is already importing data
     */
    public get importing() : boolean
    {
        const now = new Date();
        return this._lastTargetImportTs > now.getTime() - this._importTimeoutMs;
    }

    private _lastTargetImportTs : number;
    private _targets : Target[];
    private _targetsSubject : Subject<TargetInterface[]>;
    private _targetsLoadedPromise : Promise<void>;
    private readonly _importTimeoutMs : number;

    constructor(
        private _storageService : StorageService,
        private _translateService : AsyncTranslateService,
        private _alertController : AlertController
    ) {
        this._targets = [];
        this._importTimeoutMs = 30000;
        this._targetsSubject = new Subject<TargetInterface[]>();
        // Use a unique subscription and then keep the targets updated
        this._targetsLoadedPromise = new Promise<void>(release => {
            let first = true;
            this._storageService.getAllTargets().subscribe(async targetInterfaces => {
                if (this.importing && !first) {
                    return;
                }
                first = false;

                this._targets = [];
                for (let targetInterface of targetInterfaces) {
                    const newTarget = Target.fromDataInterface(targetInterface);
                    this._targets.push(newTarget);
                }

                release();
                this._targetsSubject.next(this._targets);
            });
        });

        this.targetsObservable = new Observable<TargetInterface[]>(subscriber => {
            this._targetsLoadedPromise.then(() => {
                subscriber.next(this._targets);
            });
            
            const sub = this._targetsSubject.subscribe(targets => {
                subscriber.next(targets);
            });

            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });

        // Monitor the targets "importing" flag
        this._lastTargetImportTs = 0;
        this._storageService.getFlag("lastTargetImportTs").subscribe(flag => {
            this._lastTargetImportTs = parseInt(flag?.value ?? "0");
        });
    }

    /**
     * Get a target from the list of targets
     * 
     * @param uuid The target UUID
     * @returns 
     */
    public async get(uuid : string) : Promise<TargetInterface>
    {
        await this._targetsLoadedPromise;

        uuid = TargetService.cleanTargetId(uuid);

        const group = TargetsGroup.fromUuidAndTargets(uuid, this._targets);
        if (!!group) {
            return group;
        }

        // Look in the non optimized targets
        const tgt = this._targets.find(t => t.uuid == uuid);
        if (!!tgt) {
            return tgt;
        }

        // If the target was not found as is and its
        // UUID contains "-", try to split it and ge the
        // single items
        const splits = TargetService.cleanTargetId(uuid).split("-");
        if (splits.length > 1) {
            const tgts : TargetInterface[] = [];
            for (let split of splits) {
                const subTgt = this._targets.find(t => t.uuid == split);
                if (!subTgt) {
                    console.warn("Target " + split +  " not found in group " + uuid);
                }
                else {
                    tgts.push(subTgt);
                }
            }
            if (tgts.length > 0) {
                return new TargetsGroup(tgts);
            }
        }

        return null;
    }

    /**
     * Save a target to the DB.
     * 
     * @param target The target to save
     */
    public async saveTarget(target : Target)
    {
        await this._storageService.saveTarget(target.toDataInterface());
    }

    public async saveTargetBulk(targets : Target[])
    {
        await this._storageService.setFlag("lastTargetImportTs", (new Date()).getTime());
        for (let i = 0; i < targets.length; i++) {
            if (i == targets.length - 1) {
                await this._storageService.setFlag("lastTargetImportTs", (new Date()).getTime() - this._importTimeoutMs);
            }
            await this.saveTarget(targets[i]);
        }
    }

    /**
     * Delete a target from the DB.
     * 
     * @param target The target to delete
     */
    public async deleteTarget(target : Target)
    {
        await this._storageService.deleteTarget(target.toDataInterface());
    }

    /**
     * Import the targets from the specified files to the database
     * 
     * @param kmz The source kmz File
     * @param options The import options
     * @param options.filter The list of areas to import. Leave empty to load all the areas in the targets array. 
     * @param options.eraseDb True to erase the entire target DB before importing the new data
     * @param options.updateCb A callback called during the import
     * @param options.validation If present, will activate the automatic validation of the target
     * @param options.validation.sensorId ID of the sensor used to automatically validate the target
     * @param options.validation.lensIndex Index of the sensor lens used to validate the target
     * @param options.validation.gsd The GSD used to validate the target
     */
    public async importFromKmz(kmz : Blob, options : TargetsImportOptionsInterface)
    {
        if (this.importing) {
            return;
        }

        const zip = new JSZip();
        const files = await zip.loadAsync(kmz);
        for (let key in files.files) {
            if (key.toLowerCase().endsWith(".kml")) {
                const blob = await files.files[key].async('blob');
                await this.importFromKml(blob, options);
                break;
            }
        }
    }

    /**
     * Import the targets from the specified files to the database
     * 
     * @param kmlFile The source kml File
     * @param options The import options
     * @param options.filter The list of areas to import. Leave empty to load all the areas in the targets array. 
     * @param options.eraseDb True to erase the entire target DB before importing the new data
     * @param options.updateCb A callback called during the import
     * @param options.validation If present, will activate the automatic validation of the target
     * @param options.validation.sensorId ID of the sensor used to automatically validate the target
     * @param options.validation.lensIndex Index of the sensor lens used to validate the target
     * @param options.validation.gsd The GSD used to validate the target
     */
    public async importFromKml(kmlFile : Blob, options : TargetsImportOptionsInterface)
    {
        if (this.importing) {
            return;
        }

        const text = await kmlFile.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, "text/xml");

        if (!xml) {
            console.error("Invalid KML file");
            return;
        }

        // Get some localization
        const l10n = await this._translateService.get([ "target.manage.parsingTargets" ]);
        if (!!options.updateCb) {
            await options.updateCb(l10n["target.manage.parsingTargets"], true);
        }
        let targets : Target[] = [];
        const features = kml(xml)["features"];

        for (let area of features) {
            let target = await this.geoJSONtoTarget(area, options.updateCb);
            if (target == null) {
                return;
            }
            targets.push(target);
        }

        await this.import(targets, options);
    }

    /**
     * Import the targets from the specified files to the database
     * 
     * @param shp The source SHP File
     * @param dbf The source DBF File
     * @param options The import options
     * @param options.filter The list of areas to import. Leave empty to load all the areas in the targets array. 
     * @param options.eraseDb True to erase the entire target DB before importing the new data
     * @param options.updateCb A callback called during the import
     * @param options.validation If present, will activate the automatic validation of the target
     * @param options.validation.sensorId ID of the sensor used to automatically validate the target
     * @param options.validation.lensIndex Index of the sensor lens used to validate the target
     * @param options.validation.gsd The GSD used to validate the target
     */
    public async importFromShp(shp : File, dbf : File, options: TargetsImportOptionsInterface)
    {
        if (this.importing) {
            return;
        }

        // Get some localization
        const l10n = await this._translateService.get([
            "target.manage.readingShp",
            "target.manage.readingDbf",
            "target.manage.parsingTargets"
        ]);

        // Read the SHP file
        if (!!options.updateCb) {
            await options.updateCb(l10n["target.manage.readingShp"], true);
        }
        const shpArrayBuffer = await new Promise<ArrayBuffer>(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as ArrayBuffer);
            }
            reader.readAsArrayBuffer(shp);
        });
        
        // Read the DBF file
        if (!!options.updateCb) {
            await options.updateCb(l10n["target.manage.readingDbf"], true);
        }
        const dbfArrayBuffer = await new Promise<ArrayBuffer>(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as ArrayBuffer);
            }
            reader.readAsArrayBuffer(dbf);
        });

        // Get the shp content
        if (!!options.updateCb) {
            await options.updateCb(l10n["target.manage.parsingTargets"], true);
        }
        let targets : Target[] = [];

        const me = this;
        let error = false;
        await new Promise<void>(async resolve => {
            shapefile.open(shpArrayBuffer, dbfArrayBuffer).then(source => {
                source.read().then(async function add(result) {
                    if (result.done) {
                        resolve();
                        return;
                    }

                    let target= await me.geoJSONtoTarget(result.value, options.updateCb);
                    if (target == null) {
                        error = true;
                        resolve();
                        return;
                    }

                    targets.push(target);
                    
                    source.read().then(add);
                });
            }).catch(error => console.error(error.stack));
        });
        
        if (!error) {
            await this.import(targets, options);
        }
    }

    /**
     * Convert a GeoJSON object to a Target instance.
     * 
     * @param json The GeoJSON of a specific area
     * @param updateCb The callback called during the area update
     * @returns A promise of a Target
     */
    private async geoJSONtoTarget(json : any, updateCb? : (status : string, loading : boolean) => Promise<void>) : Promise<Target>
    {
        let vertices : PositionInterface[] = [];

        let id = json.properties.unicode ?? json.properties.UNICODE ?? json.properties.name ?? json.properties.NAME;

        // Invalid JSON format
        if (!json.geometry || !json.properties) {
            if (!!updateCb) {
                await updateCb("Error", false);
            }
            console.error("Invalid file format.", json);
            const l10n = await this._translateService.get(["target.manage.importError", "common.ok"], {
                "id": id,
                "note": "Invalid file format"
            })
            const alert = await this._alertController.create({
                message: l10n["target.manage.importError"],
                buttons: [l10n["common.ok"]]
            });
            await alert.present();
            return null;
        }

        // Invalid geometry type
        if (json.geometry.type.toLowerCase() != "polygon") {
            if (!!updateCb) {
                await updateCb("Error", false);
            }
            console.error("Invalid geometry type", json);
            const l10n = await this._translateService.get(["target.manage.importError", "common.ok", "common.warning"], {
                "id": id,
                "note": "Geometry " + json.geometry.type + " not supported"
            })
            const alert = await this._alertController.create({
                header: l10n["common.warning"],
                message: l10n["target.manage.importError"],
                buttons: [l10n["common.ok"]]
            });
            await alert.present();
            return null;
        }

        for (let i = 0; i < json.geometry.coordinates[0].length; i++) {
            const lat = json.geometry.coordinates[0][i][1];
            const lon = json.geometry.coordinates[0][i][0];
            let invalid = typeof lat != "number" || typeof lon != "number" || lat > 90 || lat < -90 || lon > 180 || lon < -180;

            // Check if this point is already known
            if (!!vertices.find(p => Position.fromInterface(p).distanceTo(new Position(lat, lon)) <= 1)) {
                continue;
            }

            if (invalid) {
                if (!!updateCb) {
                    await updateCb("Error", false);
                }
                console.error("Invalid coordinates format. (not a number, too large values)", json);
                const l10n = await this._translateService.get(["target.manage.importError", "common.ok", "common.warning"], {
                    "id": id ?? "",
                    "note": "Invalid coordinates format"
                })
                const alert = await this._alertController.create({
                    header: l10n["common.warning"],
                    message: l10n["target.manage.importError"],
                    buttons: [l10n["common.ok"]]
                });
                await alert.present();
                return null;
            }
            vertices.push(new Position(lat, lon));
        }

        // Invalid vertices count
        if (vertices.length < 3) {
            console.error("Invalid vertices count (" + vertices.length + ")", json);
            const l10n = await this._translateService.get(["target.manage.importError", "common.ok", "common.warning"], {
                "id": id ?? "",
                "note": "Invalid vertices count"
            })
            const alert = await this._alertController.create({
                header: l10n["common.warning"],
                message: l10n["target.manage.importError"],
                buttons: [l10n["common.ok"]]
            });
            await alert.present();
        }

        if (!id) {
            const poly = new Polygon(vertices);
            if (!!updateCb) {
                await updateCb("Error", false);
            }
            const l10n = await this._translateService.get([
                "target.manage.missingUnicode",
                "target.manage.unicode",
                "target.manage.missingUnicodeTip",
                "common.ok",
                "common.cancel"
            ], {
                "id": id,
                "position": Position.toString(poly.center)
            });
            const alert = await this._alertController.create({
                message: l10n["target.manage.missingUnicodeTip"],
                subHeader: l10n["target.manage.missingUnicode"],
                cssClass: "large-alert",
                buttons: [
                    {
                        text: l10n["common.cancel"],
                        role: "cancel"
                    },
                    {
                        text: l10n["common.ok"],
                        handler: (data) => {
                            id = data.unicode;
                            return !!data.unicode;
                        }
                    }
                ],
                inputs: [
                    {
                        label: "Unicode",
                        placeholder: l10n["target.manage.unicode"],
                        type: "text",
                        name: "unicode"
                    }
                ]
            });
            await alert.present();
            await alert.onDidDismiss();
        }

        if (!id) {
            return null;
        }
        return new Target(id, vertices);
    }

    /**
     * Import the targets from the specified array
     * 
     * @param targets The targets array to import
     * @param options The import options
     * @param options.filter The list of areas to import. Leave empty to load all the areas in the targets array. 
     * @param options.eraseDb True to erase the entire target DB before importing the new data
     * @param options.updateCb A callback called during the import
     * @param options.validation If present, will activate the automatic validation of the target
     * @param options.validation.sensorId ID of the sensor used to automatically validate the target
     * @param options.validation.lensIndex Index of the sensor lens used to validate the target
     */
    public async import(targets : Target[], options: TargetsImportOptionsInterface)
    {
        if (this.importing) {
            return;
        }

        await this._storageService.setFlag("lastTargetImportTs", (new Date()).getTime());
        // Set the flag every now and then, until we're importing the data
        const interval = setInterval(async () => {
            await this._storageService.setFlag("lastTargetImportTs", (new Date()).getTime());
        }, this._importTimeoutMs / 2);

        // Get some localization
        const l10n = await this._translateService.get([
            "target.manage.parsingTargets",
            "target.manage.erasingDb",
            "common.overwrite",
            "common.skip",
            "common.sameForAll"
        ]);
        
        if (!!options.updateCb) {
            await options.updateCb(l10n["target.manage.parsingTargets"], true);
        }

        let dbTargets = await new Promise<TargetDataInterface[]>(resolve => {
            const sub = this._storageService.getAllTargets().subscribe(async targets => {
                sub.unsubscribe();
                resolve(targets);
            });
        });

        // Delete the targets in the DB if requested
        if (!!options.eraseDb) {
            for (let i = 0; i < dbTargets.length; i++) {
                if (!!options.updateCb) {
                    await options.updateCb(l10n["target.manage.erasingDb"] + ": " + Helpers.percent(i + 1, targets.length) + "%", true);
                }
                await this._storageService.deleteTarget(dbTargets[i]);
            }
            dbTargets = [];
            this._targets = [];
        }

        // Add the targets in the DB
        let skipAll = false;
        let overWriteAll = false;
        const filteredTargets = targets.filter(t => !options.filter || !options.filter.length || options.filter.indexOf(t.uuid.toLowerCase()) > -1);
        for (let i = 0; i < filteredTargets.length; i++) {
            const target = filteredTargets[i];

            // Set the valiation parameters
            if (!!options.validation && !!options.validation.sensor && !!options.validation.gsd && options.validation.lensIndex >= 0) {
                target.setOutputType(options.validation.sensor, options.validation.lensIndex, options.validation.gsd);
                target.flowStatus = FlowStatus.SubmissionPending;
            }

            // Before the last, clear the import flag
            // This will other client recognize the bulk update
            if (i == filteredTargets.length - 1) {
                clearInterval(interval);
                await this._storageService.setFlag("lastTargetImportTs", (new Date()).getTime() - this._importTimeoutMs);
            }

            if (!!options.updateCb) {
                await options.updateCb(l10n["target.manage.parsingTargets"] + ": " + Helpers.percent(i + 1, filteredTargets.length) + "%", true);
            }
            // Check if the target is already there
            if (dbTargets.find(t => t.uuid == target.uuid)) {
                if (skipAll) {
                    continue;
                }

                if (overWriteAll) {
                    // Replace the target in the local array
                    await this.overwriteTarget(target);
                    await Helpers.sleep(50);
                    continue;
                }

                if (!!options.updateCb) {
                    await options.updateCb("", false);
                }
                const alertL10n = await this._translateService.get([
                    "target.manage.duplicatedWarning"
                ], { "name": target.uuid });
                let forAll = false;
                const alert = await this._alertController.create({
                    message: alertL10n["target.manage.duplicatedWarning"],
                    inputs: [
                        {
                            type: "checkbox",
                            label: l10n["common.sameForAll"],
                            handler: (val) => {
                                forAll = val.checked;
                            }
                        }
                    ],
                    buttons: [
                        {
                            "text": l10n["common.overwrite"],
                            "handler": async () => {
                                await this.overwriteTarget(target);
                                if (forAll) {
                                    overWriteAll = true;
                                }
                            }
                        },
                        {
                            "text": l10n["common.skip"],
                            "role": "cancel",
                            handler: () => {
                                if (forAll) {
                                    skipAll = true;
                                }
                            }
                        }
                    ]
                });
                await alert.present();
                await alert.onDidDismiss();
            }
            else {
                // Add the target to the local private array
                this._targets.push(target);
                // Add the target to the storage
                await this._storageService.saveTarget(target.toDataInterface());
                await Helpers.sleep(50);
            }
        }

        // Update the target subscribers
        this._targetsSubject.next(this._targets);

        clearInterval(interval);
        await this._storageService.setFlag("lastTargetImportTs", (new Date()).getTime() - 30000);
    }

    public async exportToGranularZip() : Promise<Blob>
    {
        const shpFile = new Shapefile(this._targets);
        return shpFile.toGranularZip();
    }

    public async exportToMonolithicZip() : Promise<Blob>
    {
        const shpFile = new Shapefile(this._targets);
        return shpFile.toMonolithicZip();
    }

    /**
     * Overwrite a target with the same uuid as the one provided as parameter.
     * 
     * @param target The new target
     */
    private async overwriteTarget(target : Target)
    {
        const index = this._targets.findIndex(t => t.uuid == target.uuid);
        const int = target.toDataInterface();
        if (index > -1) {
            const t = this._targets.splice(index, 1);
            // Maintain the current status!
            int.flowStatus = t[0].toDataInterface().flowStatus;
            // Keep the notes
            int.notes = t[0].toDataInterface().notes;
            this._targets.push(Target.fromDataInterface(int));
        }
        // Replace the target in the remote storage
        await this._storageService.saveTarget(int);
    }

    /**
     * Clean the target id string to remove the coordinates part (not used anymore).
     * 
     * @summary before storing the targets in the DB, they were provided using a single SHP file
     * in which the targets were duplicated. That is, two different targets had the same name.
     * To provide an unique id, the target center's coordinates were added to the id string.
     * Since the target are now checked before import, this extra part is not needed anymore.
     * 
     * @param targetId The target id
     */
    public static cleanTargetId(targetId : string) : string
    {
        const match = targetId.match("(-[0-9\.]+-[0-9\.]+)$");
        if (!!match && match.length) {
            return targetId.replace(match[0], "");
        }
        return targetId;
    }
}
