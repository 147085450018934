import { AsyncTranslateInService } from "src/app/services/async-translate-in.service";
import { AsyncTranslateService } from "src/app/services/async-translate.service";

export class TranslatedGenericError extends Error {

    /**
     * 
     * @param _l10nKey The L10N key that describes this error
     * @param _params The L10N parameters
     */
    constructor(private _l10nKey : string, private _params : any = null)
    {
        super();
    }

    /**
     * Convert this error to a localized string
     * 
     * @param translateService The async translate or async translate "in" service
     * @param langIf The language ID to use (only if a AsyncTranslateInService is used)
     */
    public async toString(translateService : AsyncTranslateService | AsyncTranslateInService, langId : string = "en") : Promise<string>
    {
        if (translateService == null) {
            return this._l10nKey;
        }
        
        if (translateService instanceof AsyncTranslateService) {
            const l10n = await translateService.get([this._l10nKey], this._params);
            return l10n[this._l10nKey];
        }

        const l10n = await translateService.get([this._l10nKey], this._params, langId);
        return l10n[this._l10nKey];
    }

}
