import { MassAndBalanceStationDataInterface } from "src/app/interfaces/data/mass-and-balance-station-data-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { SmartbayConfiguration } from "../smartbay-configuration/smartbay-configuration";
import { MassAndBalanceStation } from "./mass-and-balance-station";
import { MassAndBalanceStationType } from "./mass-and-balance-station-type";

export class SmartbayMassAndBalanceStation extends MassAndBalanceStation {

    /**
     * This station type
     */
    public get type() : MassAndBalanceStationType
    {
        return "smartbay";
    }
    
    public get mass(): number
    {
        return this.smartbay.mass + this.pylonMass;
    }

    public get arm() : number
    {
        let mass = this.pylonMass;
        let moment = this.pylonMass * this.pylonArm;

        mass += this.smartbay.frontTrolley.mass;
        moment += this.frontTrolleyArm * this.smartbay.frontTrolley.mass;

        mass += this.smartbay.centerTrolley.mass;
        moment += this.centerTrolleyArm * this.smartbay.centerTrolley.mass;

        mass += this.smartbay.rearTrolley.mass;
        moment += this.rearTrolleyArm * this.smartbay.rearTrolley.mass;

        mass += this.smartbay.BIUMass;
        moment += this.biuArm * this.smartbay.BIUMass;

        return moment / mass;
    }

    /**
     * Allow to load the smartbay configuration into the mass and balance
     * 
     * @param name The station name
     * @param pylonMass The extra empty pylon mass in kg (if not already included in the plane M&B)
     * @param pylonArm The empty pylon arm in m
     * @param frontTrolleyArm The front trolley arm in m
     * @param centerTrolleyArm The center trolley arm in m
     * @param rearTrolleyArm The rear trolly arm in m
     * @param biuArm the BIU arm in m
     * @param smartbay The smartbay configuration
     */
    constructor(
        name : string,
        public readonly pylonMass : number,
        public readonly pylonArm : number,
        public readonly frontTrolleyArm : number,
        public readonly centerTrolleyArm : number,
        public readonly rearTrolleyArm : number,
        public readonly biuArm : number,
        public readonly smartbay : SmartbayConfiguration)
    {
        super(name);
    }

    public clone() : MassAndBalanceStation
    {
        return new SmartbayMassAndBalanceStation(this.name, this.pylonMass, this.pylonArm, this.frontTrolleyArm, this.centerTrolleyArm, this.rearTrolleyArm, this.biuArm, this.smartbay);
    }

    public toDataInterface() : MassAndBalanceStationDataInterface
    {
        return {
            arm: this.arm,
            name: this.name,
            type: "smartbay",
            payloads: [this.pylonMass, this.frontTrolleyArm, this.centerTrolleyArm, this.rearTrolleyArm, this.biuArm],
            defaults: []
        };
    }

    public acceptMassAndBalanceVisitor(visitor: MassAndBalanceVisitorInterface)
    {
        visitor.visitSmartbayStation(this);
    }

    public static fromDataInterface(int : MassAndBalanceStationDataInterface, smartbay : SmartbayConfiguration) : SmartbayMassAndBalanceStation
    {
        return new SmartbayMassAndBalanceStation(int.name, int.payloads[0], int.arm, int.payloads[1], int.payloads[2], int.payloads[3], int.payloads[4], smartbay);
    }
}
