import { PointInterface } from "src/app/interfaces/point-interface";
import { XYFunctionInterface } from "src/app/interfaces/xyfunction-interface";

export class LinearInterpolation implements XYFunctionInterface {

    private _points : PointInterface[];

    constructor(points : PointInterface[])
    {
        this._points = [];

        // Order the points 
        for (let point of points) {
            // Tail
            if (this._points.length == 0 || point.x > this._points[this._points.length - 1].x) {
                this._points.push(point);
            }
            // Head
            else if (point.x < this._points[0].x) {
                this._points.splice(0, 0, point);
            }
            // Middle
            else {
                for (let i = 0; i < this._points.length - 1; i++) {
                    if (point.x >= this._points[i].x && point.x <= this._points[i + 1].x) {
                        this._points.splice(i, 0, point);
                        break;
                    }
                }
            }
        }
    }

    /**
     * Calculate the value of the function in a specific point of the X axis.
     * 
     * @param x The X axis value
     * @returns The corresponding Y value of this function
     */
    public getYValue(x: number): number
    {
        if (this._points.length == 0) {
            return 0;
        }

        if (this._points[0].x >= x) {
            return this._points[0].y;
        }

        if (this._points[this._points.length - 1].x <= x) {
            return this._points[this._points.length - 1].y;
        }

        for (let i = 0; i < this._points.length - 1; i++) {
            if (this._points[i].x <= x && this._points[i + 1].x >= x) {
                const ratio = (x - this._points[i].x) / (this._points[i + 1].x - this._points[i].x);
                return this._points[i].y + ratio * (this._points[i + 1].y - this._points[i].y);
            }
        }

        return 0;
    }

    /**
     * Invert the current function.
     */
    public invert() : LinearInterpolation
    {
        const points : PointInterface[] = [];

        for (let myp of this._points) {
            points.push({
                x: myp.y,
                y: myp.x
            });
        }

        return new LinearInterpolation(points);
    }
}
