import { WaypointDataInterface } from "src/app/interfaces/data/waypoint-data-interface";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";
import { PositionInterface } from "src/app/interfaces/position-interface";
import { WaypointAcceptorInterface } from "src/app/interfaces/waypoint-acceptor-interface";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { Position } from "../position";
import { WaypointType } from "./waypoint-type";

export abstract class Waypoint implements WaypointAcceptorInterface {

    public abstract get name() : string;
    public abstract get position() : PositionInterface;
    public abstract get type() : WaypointType;
    /**
     * The delay on this waypoint in milliseconds.
     * Usually it's zero for a turnpoint and the airwork time 
     * for a Target
     */
    public abstract get delayMs() : number;
    public abstract get altitudeM() : number;
    public abstract get boundarybox() : PolygonInterface;
    /**
     * True if this waypoint is fixed in space and cannot be moved
     */
    public abstract get fixed() : boolean;

    constructor()
    {
    }

    public abstract acceptWaypointVisitor(visitor: WaypointVisitorInterface): void;

    public abstract toDataInterface() : WaypointDataInterface;
    
    public equals(other : Waypoint) : boolean
    {
        if (other.type != this.type) {
            return false;
        }
        if (other.name != this.name) {
            return false;
        }
        if (Position.fromInterface(this.position).distanceTo(other.position) > 5) {
            return false;
        }
        return true;
    }

    protected toGenericDataInterface(payload : any) : WaypointDataInterface
    {
        return {
            name: this.name,
            position: this.position,
            type: this.type,
            payload: payload
        };
    }
}
