import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Aircraft } from 'src/app/classes/aircraft';
import { Route } from 'src/app/classes/route/route';
import { TargetWaypoint } from 'src/app/classes/route/target-waypoint';
import { Sensor } from 'src/app/classes/smartbay-configuration/sensor';
import { SmartbayConfiguration } from 'src/app/classes/smartbay-configuration/smartbay-configuration';
import { Target } from 'src/app/classes/target';
import { TargetOptions } from 'src/app/classes/target-options';
import { AircraftDataInterface } from 'src/app/interfaces/data/aircraft-data-interface';
import { AirportDataInterface } from 'src/app/interfaces/data/airport-data-interface';
import { FlagDataInterface } from 'src/app/interfaces/data/flag-data-interface';
import { MissionDataInterface } from 'src/app/interfaces/data/mission-data-interface';
import { PilotDataInterface } from 'src/app/interfaces/data/pilot-data-interface';
import { TargetDataInterface } from 'src/app/interfaces/data/target-data-interface';
import { StorageServiceInterface } from 'src/app/interfaces/storage-service-interface';

/**
 * A storage service that saves stuff in RAM.
 * It's useful in development when running a docker + appwrite instance is bloating
 */
@Injectable({
  providedIn: 'root'
})
export class VolatileStorageService implements StorageServiceInterface {

    public get isOnline() : Observable<boolean>
    {
        return new Observable<boolean>(subscriber => {
            subscriber.next(true);
        });
    }

    private _missions : MissionDataInterface[];
    private _missionSubject : Subject<void>;

    private _flags : { (key : string) : FlagDataInterface } | {};
    private _flagsSubject : Subject<void>;

    private _targets : TargetDataInterface[];
    private _targetsSubject : Subject<void>;

    private _airports : AirportDataInterface[];
    private _airportsSubject : Subject<void>;

    private _pilots : PilotDataInterface[];
    private _pilotssSubject : Subject<void>;

    private _aircrafts : AircraftDataInterface[];
    private _aircraftssSubject : Subject<void>;

    constructor()
    {
        this._missions = [];
        this._missionSubject = new Subject();

        this._flags = {};
        this._flagsSubject = new Subject();
        
        this._targets = [];
        this._targetsSubject = new Subject();

        this._airports = [];
        this._airportsSubject = new Subject();

        this._pilots = [];
        this._pilotssSubject = new Subject();

        this._aircrafts = [];
        this._aircraftssSubject = new Subject();
    }

    public getAllMissions(): Observable<MissionDataInterface[]>
    {
        return new Observable<MissionDataInterface[]>(subscriber => {
            const sub = this._missionSubject.subscribe(() => {
                subscriber.next(this._missions);
            });
            subscriber.next(this._missions);
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    public async saveMission(mission: MissionDataInterface): Promise<string>
    {
        if (!!mission.id) {
            this.silentDeleteMission(mission.id);
        }
        if (!mission.id) {
            mission.id = (+new Date).toString(36);
        }
        this._missions.push(mission);
        // A timeout prevents events overlapping
        setTimeout(() => {
            this._missionSubject.next();
        }, 1000);
        return mission.id;
    }

    public async getMission(id: string): Promise<MissionDataInterface>
    {
        return this._missions.find(m => m.id == id);
    }

    public async deleteMission(id: string): Promise<void>
    {
        this.silentDeleteMission(id);
        this._missionSubject.next();
    }

    private silentDeleteMission(id : string)
    {
        const currentIndex = this._missions.findIndex(m => m.id == id);
        if (currentIndex > -1) {
            this._missions.splice(currentIndex, 1);
        }
    }

    public getFlag(key: 'lastTargetImportTs'): Observable<FlagDataInterface>
    {
        return new Observable<FlagDataInterface>(subscriber => {
            subscriber.next(!!this._flags[key] ? this._flags[key] : null);
            const sub = this._flagsSubject.subscribe(() => {
                subscriber.next(!!this._flags[key] ? this._flags[key] : null);
            });
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    public async setFlag(key: 'lastTargetImportTs', value: string | number | boolean): Promise<void>
    {
        const flag : FlagDataInterface = {
            "key": key,
            "value": value + ""
        }
        this._flags[key] = flag;
        this._flagsSubject.next();
    }

    public getAllTargets(): Observable<TargetDataInterface[]>
    {
        return new Observable<TargetDataInterface[]>(subscriber => {
            const sub = this._targetsSubject.subscribe(() => {
                subscriber.next(this._targets);
            });
            setTimeout(() => {
                subscriber.next(this._targets);
            }, 100);
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    public async getTarget(id: string): Promise<TargetDataInterface>
    {
        return this._targets.find(t => t.uuid == id) ?? null;
    }

    public async saveTarget(target: TargetDataInterface): Promise<void>
    {
        this.silentDeleteTarget(target.uuid);
        this._targets.push(target);
        this._targetsSubject.next();
    }

    public async deleteTarget(target: TargetDataInterface): Promise<void>
    {
        this.silentDeleteTarget(target.uuid);
        this._targetsSubject.next();
    }

    public silentDeleteTarget(uuid : string)
    {
        const currentIndex = this._targets.findIndex(t => t.uuid = uuid);
        if (currentIndex > -1) {
            this._targets.splice(currentIndex, 1);
        }
    }

    public getAllAirports(): Observable<AirportDataInterface[]>
    {
        return new Observable<AirportDataInterface[]>(subscriber => {
            subscriber.next(this._airports);
            const sub = this._airportsSubject.subscribe(() => {
                subscriber.next(this._airports);
            });
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    public async getAirport(icao: string): Promise<AirportDataInterface>
    {
        return this._airports.find(a => a.icao == icao) ?? null;
    }

    public async saveAirport(airport: AirportDataInterface): Promise<void>
    {
        this.silentDeleteAirport(airport.icao);
        this._airports.push(airport);
        this._airportsSubject.next();
    }

    public async deleteAirport(airport: AirportDataInterface): Promise<void>
    {
        this.silentDeleteAirport(airport.icao);
        this._airportsSubject.next();
    }

    public silentDeleteAirport(icao : string)
    {
        const currentIndex = this._airports.findIndex(a => a.icao == icao);
        if (currentIndex > -1) {
            this._airports.splice(currentIndex, 1);
        }
    }

    public getAllPilots(): Observable<PilotDataInterface[]> {
        return new Observable<PilotDataInterface[]>(subscriber => {
            subscriber.next(this._pilots);
            const sub = this._pilotssSubject.subscribe(() => {
                subscriber.next(this._pilots);
            });
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    public async getPilot(email: string): Promise<PilotDataInterface>
    {
        return this._pilots.find(p => p.email == email) ?? null;
    }

    public async savePilot(pilot: PilotDataInterface): Promise<void>
    {
        this.silentDeletePilot(pilot.email);
        this._pilots.push(pilot);
        this._pilotssSubject.next();
    }

    public async deletePilot(pilot: PilotDataInterface): Promise<void>
    {
        this.silentDeletePilot(pilot.email);
        this._pilotssSubject.next();
    }

    public silentDeletePilot(email : string)
    {
        const currentIndex = this._pilots.findIndex(p => p.email == email);
        if (currentIndex > -1) {
            this._pilots.splice(currentIndex, 1);
        }
    }

    public saveAircraft(aircraft : AircraftDataInterface)
    {
        if (!this._aircrafts.find(a => a.registration == aircraft.registration)) {
            this._aircrafts.push(aircraft);
        }
    }

    public getAllAircrafts(): Observable<AircraftDataInterface[]>
    {
        return new Observable<AircraftDataInterface[]>(subscriber => {
            subscriber.next(this._aircrafts);
            const sub = this._aircraftssSubject.subscribe(() => {
                subscriber.next(this._aircrafts);
            });
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    /**
     * Populate this storage with some usable fake data
     */
    public populateWithFakeData()
    {
        this._aircrafts.push(Aircraft.GenericInterface);
        this._flags['lastTargetImportTs'] = 0;
        const target = new Target("RCG01", [
            {
                latitude: 44.787,
                longitude: 7.666
            },
            {
                latitude: 44.786,
                longitude: 7.680
            },
            {
                latitude: 44.770,
                longitude: 7.679
            },
            {
                latitude: 44.7702,
                longitude: 7.671
            }
        ]);
        target.landscapeType = "forest";
        this._targets.push(target.toDataInterface());

        const target2 = new Target("RCG012", [
            {
                latitude: 45.787,
                longitude: 7.666
            },
            {
                latitude: 45.786,
                longitude: 7.680
            },
            {
                latitude: 45.770,
                longitude: 7.679
            },
            {
                latitude: 45.7702,
                longitude: 7.671
            }
        ]);
        target2.landscapeType = "forest";
        this._targets.push(target2.toDataInterface());

        const balaclava02 = new Target("BALACLAVA02", [
            {"latitude":45.285118740563426,"longitude":7.485680051340751},
            {"latitude":45.285118740563426,"longitude":7.485680051340751},
            {"latitude":45.307958901211876,"longitude":7.515723527951185},
            {"latitude":45.30021527988062,"longitude":7.540821960274807},
            {"latitude":45.27323559493079,"longitude":7.523747562076952},
            {"latitude":45.285118740563426,"longitude":7.485680051340751},
            {"latitude":45.285118740563426,"longitude":7.485680051340751}
        ]);
        this._targets.push(balaclava02.toDataInterface());


        const lima : AirportDataInterface = {
            icao: "LIMA",
            elevationM: 0,
            name: "Torino Aeritalia",
            position: {
                latitude: 45.08,
                longitude: 7.60
            },
            type: 'airfield'
        };
        this._airports.push(lima);

        this._pilots.push({
            email: "nicolotti@digisky.it",
            langId: "it",
            name: "Claudio",
            surname: "Nicolotti"
        });

        const opt1 = new TargetOptions("BALACLAVA02");
        opt1.resolution = 5;
        opt1.sidelap = 0.7;
        const route1 = new Route(lima, lima, null, [
            new TargetWaypoint(balaclava02)
        ]);
        const smartbay1 = SmartbayConfiguration.defaultConfiguration;
        smartbay1.frontTrolley.setSensor(Sensor.CHCAA15, 0);
        smartbay1.frontTrolley.setSensor(Sensor.SonyILCE7RM4L, 1);
        smartbay1.primarySensorIndex = 0;
        this._missions.push({
            aircraftRegistration: Aircraft.Generic.registration,
            authorityRefCode: "",
            built: false,
            customerRefCode: "",
            defaultFlowStatus: "planned",
            ete: 4000,
            executionTimeIso: (new Date()).toISOString(),
            groundSupport: "",
            id: "2034u2n348su34",
            finResMins: 30,
            internalRefCode: "",
            latDeviation: 30,
            massAndBalanceValues: [],
            minimumFuelKg: 0,
            name: "LiDAR Mission",
            needsRebuild: true,
            notes: "",
            photogrammetricData: {},
            pic: "nicolotti@digisky.it",
            dispatcher: "sussio@digisky.it",
            ppr: "",
            riskEvaluationLevel: 'no-action',
            riskEvaluationNotes: "",
            route: route1.toDataInterface(),
            smartbayConfig: smartbay1.toDataInterface(),
            takeoffTimeIso: (new Date()).toISOString(),
            targetOptions: [opt1.toDataInterface()],
            timestampIso: "",
            uploadTimeIso: ""
        });

        const opt2 = new TargetOptions("RCG01");
        const route2 = new Route(lima, lima, null, [
            new TargetWaypoint(target)
        ]);
        this._missions.push({
            aircraftRegistration: Aircraft.Generic.registration,
            authorityRefCode: "",
            built: false,
            customerRefCode: "",
            defaultFlowStatus: "planned",
            ete: 4000,
            executionTimeIso: (new Date()).toISOString(),
            groundSupport: "",
            id: "2034u3n348su74",
            finResMins: 30,
            internalRefCode: "",
            latDeviation: 30,
            massAndBalanceValues: [],
            minimumFuelKg: 0,
            name: "Optical Mission",
            needsRebuild: true,
            notes: "",
            photogrammetricData: {},
            pic: "nicolotti@digisky.it",
            dispatcher: "sussio@digisky.it",
            ppr: "",
            riskEvaluationLevel: 'no-action',
            riskEvaluationNotes: "",
            route: route2.toDataInterface(),
            smartbayConfig: SmartbayConfiguration.defaultConfiguration.toDataInterface(),
            takeoffTimeIso: (new Date()).toISOString(),
            targetOptions: [opt2.toDataInterface()],
            timestampIso: "",
            uploadTimeIso: ""
        });
    }
}
