import { TargetInterface } from "src/app/interfaces/target-interface";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { TargetVisitorInterface } from "../../interfaces/target-visitor-interface";
import { AirportWaypoint } from "../route/airport-waypoint";
import { TargetWaypoint } from "../route/target-waypoint";
import { TurnWaypoint } from "../route/turn-waypoint";
import { Target } from "../target";
import { TargetsGroup } from "../targets-group";

/**
 * Sweep the different type of targets contained in a list of waypoints and
 * returns true if the target passed as parameter in the constructor is found.
 */
export class ContainsTargetWaypointVisitor implements TargetVisitorInterface, WaypointVisitorInterface {

    /**
     * True if the visited collection contains the target passed in the constructor
     */
    public get result() : boolean
    {
        return this._result;
    }

    private _result : boolean;
    
    /**
     * Search for the target during the visit.
     * The result is returned as the @property result after the visit.
     * 
     * @param _target The target to look for
     */
    constructor(private _target : TargetInterface)
    {
        this._result = false;
    }

    /**
     * Reset the visitor so it's ready for a new search
     */
    public reset()
    {
        this._result = false;
    }

    visitTurnWaypoint(wp : TurnWaypoint) {}
    visitAirportWaypoint(wp : AirportWaypoint) : void {}

    visitTargetWaypoint(wp : TargetWaypoint)
    {
        wp.target.acceptTargetVisitor(this);
    }

    visitTarget(target: Target): void
    {
        if (target.uuid == this._target.uuid) {
            this._result = true;
        }    
    }

    visitTargetGroup(target: TargetsGroup): void
    {
        if (target.uuid == this._target.uuid) {
            this._result = true;
        }
        if (!this._result) {
            for (let subt of target.targets) {
                subt.acceptTargetVisitor(this);
            }
        }
    }
}
