import { MassAndBalanceStationDataInterface } from "src/app/interfaces/data/mass-and-balance-station-data-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { MassAndBalanceStation } from "./mass-and-balance-station";
import { MassAndBalanceStationType } from "./mass-and-balance-station-type";

export class OccupantsMassAndBalanceStation extends MassAndBalanceStation {

    /**
     * This station type
     */
    public get type() : MassAndBalanceStationType
    {
        return "occupants";
    }


    public get mass(): number
    {
        return this._count * 85;
    }

    /**
     * Get the occupants counts
     */
    public get count() : number
    {
        return this._count;
    }

    /**
     * Set the occupants count
     */
    public set count(c : number)
    {
        this._count = Math.min(Math.max(c, this.minCount), this.maxCount);
        this.onChange.emit();
    }

    private _count : number;

    /**
     * A mass and balance station that provides the number of occupants.
     * The weight is based on the standard OM mass (85KG)
     * 
     * @param name The station name
     * @param arm The station arm in m
     * @param minCount The minimum occupants count
     * @param maxCount The maximum occupants count
     * @param standardMass The standard occupant mass in kg
     */
    constructor(
        name : string,
        public readonly arm : number,
        public readonly minCount : number = 0,
        public readonly maxCount : number = 1,
        public readonly standardMass : number = 85
    )
    {
        super(name);
        this._count = minCount;
    }

    public clone() : MassAndBalanceStation
    {
        const station = new OccupantsMassAndBalanceStation(this.name, this.arm, this.minCount, this.maxCount, this.standardMass);
        station.count = this.count;
        return station;
    }

    public toDataInterface() : MassAndBalanceStationDataInterface
    {
        return {
            arm: this.arm,
            name: this.name,
            type: "occupants",
            payloads: [this.minCount, this.maxCount, this.standardMass],
            defaults: []
        };
    }

    public acceptMassAndBalanceVisitor(visitor: MassAndBalanceVisitorInterface)
    {
        visitor.visitOccupantsStation(this);
    }

    public static fromDataInterface(int : MassAndBalanceStationDataInterface) : OccupantsMassAndBalanceStation
    {
        return new OccupantsMassAndBalanceStation(int.name, int.arm, int.payloads[0], int.payloads[1], int.payloads[2]);
    }
}
