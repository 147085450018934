import { PositionInterface } from "../interfaces/position-interface";
import { Position } from "./position";
import { smallestSurroundingRectangleByWidth } from "geojson-minimum-bounding-rectangle";
import { Segment } from "./segment";
import { BoundaryInterface } from "../interfaces/boundary-interface";
import { UserInputCoordinates } from "geolib/es/types";
import { Helpers } from "./helpers";
import { PolygonInterface } from "../interfaces/polygon-interface";

import * as geolib from 'geolib';

export class Polygon implements PolygonInterface {

    public get boundaries() : BoundaryInterface
    {
        // Calculate the simple box
        let minLat = 90, maxLat = -90, minLon = 180, maxLon = -180;
        for (let vertex of this.vertices) {
            minLat = Math.min(minLat, vertex.latitude);
            maxLat = Math.max(maxLat, vertex.latitude);
            minLon = Math.min(minLon, vertex.longitude);
            maxLon = Math.max(maxLon, vertex.longitude);
        }

        return {
            northWest: {
                latitude: maxLat,
                longitude: minLon
            },
            southEast: {
                latitude: minLat,
                longitude: maxLon
            }
        }
    }

    /** A simple box made using the lower and the higher coordinates values */
    public get simpleBox(): Polygon
    {
        if (!this._simpleBox) {
            // Calculate the simple box
            let minLat = 90, maxLat = -90, minLon = 180, maxLon = -180;
            for (let vertex of this.vertices) {
                minLat = Math.min(minLat, vertex.latitude);
                maxLat = Math.max(maxLat, vertex.latitude);
                minLon = Math.min(minLon, vertex.longitude);
                maxLon = Math.max(maxLon, vertex.longitude);
            }
            this._simpleBox = new Polygon([
                new Position(minLat, minLon),
                new Position(maxLat, minLon),
                new Position(maxLat, maxLon),
                new Position(minLat, maxLon)
            ]);
        }
        return this._simpleBox;
    }

    /** The geographic center of this target */
    public get center(): PositionInterface
    {
        if (!this._center) {
            let lat = 0, lon = 0;
            for (let vertex of this.vertices) {
                lat += vertex.latitude;
                lon += vertex.longitude;
            }
            this._center = new Position(lat / this.vertices.length, lon / this.vertices.length);
        }
        return this._center;
    }

    /** The path that resembles this target */
    public get path() : number[][]
    {
        if (!this._path) {
            // Fill the path array
            let mVertexes = [];
            for (let vertex of this.vertices) {
                mVertexes.push([vertex.latitude, vertex.longitude]);
            }
            mVertexes.push([this.vertices[0].latitude, this.vertices[0].longitude]);
            this._path = mVertexes;
        }
        return this._path;
    }

    /** The path that resembles this target */
    public get geoJsonPath() : UserInputCoordinates[]
    {
        const path : UserInputCoordinates[] = [];
        for (let vertex of this.vertices) {
            path.push({ lat: vertex.latitude, lon: vertex.longitude});
        }
        path.push({ lat: this.vertices[0].latitude, lon: this.vertices[0].longitude});
        return path;
    }

    public get longestSide() : Segment
    {
        if (!this._longestSide) {
            let d = 0;
            for (let i = 0; i < this.vertices.length; i++) {
                let p = Position.fromInterface(this.vertices[i]);
                let ii = (i + 1) % this.vertices.length;
                let dist = p.distanceTo(this.vertices[ii]);
                if (dist > d) {
                    d = dist;
                    if (p.rilpoTo(this.center, p.headingTo(this.vertices[ii])) > 0) {
                        this._longestSide = new Segment(this.vertices[i], this.vertices[ii]);
                    }
                    else {
                        this._longestSide = new Segment(this.vertices[ii], this.vertices[i]);
                    }
                }
            }
        }
        return this._longestSide;
    }

    public get shortestSide() : Segment
    {
        if (!this._shortestSide) {
            // Multipath
            let d = Number.MAX_SAFE_INTEGER;
            for (let i = 0; i < this.vertices.length; i++) {
                let p = Position.fromInterface(this.vertices[i]);
                let ii = (i + 1) % this.vertices.length;
                let dist = p.distanceTo(this.vertices[ii]);
                if (dist < d) {
                    d = dist;
                    if (p.rilpoTo(this.center, p.headingTo(this.vertices[ii])) > 0) {
                        this._shortestSide = new Segment(this.vertices[i], this.vertices[ii]);
                    }
                    else {
                        this._shortestSide = new Segment(this.vertices[ii], this.vertices[i]);
                    }
                }
            }
        }
        return this._shortestSide;
    }

    /**
     * Get the main side of this polygon.
     * It is the side the direction of which is used to calculate the legs.
     */
    public get mainSide() : Segment
    {
        return this._mainSide ?? this.longestSide;
    }

    /**
     * Get the secondary side of this polygon.
     * It is the side perpendicular to the main one.
     */
    public get secondarySide() : Segment
    {
        return this._secondarySide ?? this.shortestSide;
    }

    /** Get the area of this polygon in squared meters */
    public get area() : number
    {
        if (!this._area) {
            this._area = geolib.getAreaOfPolygon(this.geoJsonPath);
        }

        return this._area;
    }

    private _simpleBox : Polygon;
    private _center : PositionInterface;
    private _path : number[][];
    private _area : number;
    private _longestSide : Segment;
    private _shortestSide : Segment;
    private _mainSide : Segment;
    private _secondarySide : Segment;

    constructor(public vertices: PositionInterface[])
    {
        this._simpleBox = null;
        this._center = null;
        this._path = null;
        this._area = 0;
        this._longestSide = this._shortestSide = null;
        this._mainSide = this._secondarySide = null;
    }

    /**
     * Provide a minimum bounding box for this polygon.
     * 
     * @param angle The rotation from the optimal minimum bounding box direction. Default zero.
     * @returns The minimum bounding box polygon
     */
    public getMinimumBoundingBox(angle : number = 0): Polygon
    {
        let smallest = smallestSurroundingRectangleByWidth(this.toGeoJSON());
        const bendedBox = new Polygon([
            new Position(smallest.geometry.coordinates[0][0][0], smallest.geometry.coordinates[0][0][1]),
            new Position(smallest.geometry.coordinates[0][1][0], smallest.geometry.coordinates[0][1][1]),
            new Position(smallest.geometry.coordinates[0][2][0], smallest.geometry.coordinates[0][2][1]),
            new Position(smallest.geometry.coordinates[0][3][0], smallest.geometry.coordinates[0][3][1])
        ]);
        const targetHeading = bendedBox.longestSide.heading + angle;

        // Fix: use a simple box in case of north-south orientation
        if (Math.round(targetHeading) == 0 || Math.round(targetHeading) == 180) {
            return this.simpleBox;
        }
        
        let box : Polygon = null;
        if (targetHeading <= 180) {
            box = this.rotate(-targetHeading, this.vertices[0]).simpleBox.rotate(targetHeading, this.vertices[0]);
        }
        else {
            box = this.rotate(360 - targetHeading, this.vertices[0]).simpleBox.rotate(-(360 - targetHeading), this.vertices[0]);
        }

        // Identify the main side of the box
        for (let i = 0; i < 4; i++) {
            const v1 = i;
            const v2 = (i + 1) % 4;
            const hdg = Position.fromInterface(box.vertices[v1]).headingTo(box.vertices[v2]);
            if (Helpers.isCloseTo(hdg, targetHeading, 1) || Helpers.isCloseTo(hdg, (targetHeading + 180) % 360, 1)) {
                box._mainSide = new Segment(box.vertices[v1], box.vertices[v2]);
            }
            else {
                box._secondarySide = new Segment(box.vertices[v1], box.vertices[v2]);
            }
        }

        return box;
    }

    /** Get the GeoJSON of this polygon */
    public toGeoJSON() : Object
    {
        return {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [this.path]
                    },
                    "properties": {
                        "name": ""
                    }
                }
            ]
        };
    }

    /**
     * Get the distance between the center of the two polygons in meters
     * 
     * @param other The other polygon
     * @returns The distance between the center of the two polygons in meters
     */
    public distanceTo(other : Polygon) : number
    {
        let min = 100000000;
        for (let myVertex of this.getMinimumBoundingBox().vertices) {
            for (let otherVertex of other.getMinimumBoundingBox().vertices) {
                min = Math.min(min, Position.fromInterface(myVertex).distanceTo(otherVertex));
            }
            min = Math.min(min, Position.fromInterface(myVertex).distanceTo(other.center));
        }
        min = Math.min(min, Position.fromInterface(this.center).distanceTo(other.center));
        return min;
    }

    /**
     * Rotate this polygon into a new one
     * @param angle The rotation angles
     * @param center The rotation center
     * @returns The rotated polygon
     */
    public rotate(angle : number, center : PositionInterface = null) : Polygon
    { 
        // Shortcut no-rotation
        if (angle === 0) {
            return Polygon.fromPath(this.path);
        };
        
        // Use centroid of GeoJSON if pivot is not provided
        if (!center) {
            center = this.center;
        }
        
        // Rotate each coordinate
        const newVertices : PositionInterface[] = [];
        for (const vertex of this.vertices) {
            const pivot = Position.fromInterface(center);
            const initialAngle = pivot.headingTo(vertex);
            const finalAngle = initialAngle + angle;
            const distance = pivot.distanceTo(vertex);
            const newCoords = pivot.offset(finalAngle, distance);
            newVertices.push(newCoords);
        }
        
        return new Polygon(newVertices);
    }

    /**
     * Expand this polygon on the longest side direction for
     * the given amount of meters.
     * Only rectangles are supported.
     *
     * @param meters The meters to add on each side
     */
    public expandOnMainSideDirection(meters : number)
    {
        if (this.vertices.length != 4) {
            return Polygon.fromPath(this.path);
        }

        const newVertices : PositionInterface[] = [];
        const refHeading = this.mainSide.heading;
        const inverseRefHeading = this.mainSide.reverseHeading;

        for (let i = 0; i < this.vertices.length; i++) {
            const prev = Position.fromInterface(this.vertices[(i + this.vertices.length - 1) % this.vertices.length]);
            const next = Position.fromInterface(this.vertices[(i + 1) % this.vertices.length]);
            const cur = Position.fromInterface(this.vertices[i]);
            if (Helpers.isCloseTo(prev.headingTo(cur), refHeading, 0.5) || Helpers.isCloseTo(prev.headingTo(cur), inverseRefHeading, 0.5)) {
                newVertices.push(cur.offset(prev.headingTo(cur), meters));
            }
            else {
                newVertices.push(cur.offset((cur.headingTo(next) + 180) % 360, meters));
            }
        }

        if (newVertices.length != 4) {
            throw "Cannot expand polygon";
        }

        const box = new Polygon(newVertices);

        // Identify the main side of the box
        for (let i = 0; i < 4; i++) {
            const v1 = i;
            const v2 = (i + 1) % 4;
            const hdg = Position.fromInterface(box.vertices[v1]).headingTo(box.vertices[v2]);
            if (Helpers.isCloseTo(hdg, refHeading, 1) || Helpers.isCloseTo(hdg, inverseRefHeading, 1)) {
                box._mainSide = new Segment(box.vertices[v1], box.vertices[v2]);
            }
            else {
                box._secondarySide = new Segment(box.vertices[v1], box.vertices[v2]);
            }
        }

        return box;
    }

    /**
     * Expand this polygon on the shortes side direction for
     * the given amount of meters.
     * Only rectangles are supported.
     *
     * @param meters The meters to add on each side
     */
     public expandOnSecondarySideDirection(meters : number)
     {
        if (this.vertices.length != 4) {
            return Polygon.fromPath(this.path);
        }

        const newVertices : PositionInterface[] = [];
        const refHeading = this.secondarySide.heading;
        const inverseRefHeading = this.secondarySide.reverseHeading;

        for (let i = 0; i < this.vertices.length; i++) {
            const prev = Position.fromInterface(this.vertices[(i + this.vertices.length - 1) % this.vertices.length]);
            const next = Position.fromInterface(this.vertices[(i + 1) % this.vertices.length]);
            const cur = Position.fromInterface(this.vertices[i]);
            if (Helpers.isCloseTo(prev.headingTo(cur), refHeading, 0.5) || Helpers.isCloseTo(prev.headingTo(cur), inverseRefHeading, 0.5)) {
                newVertices.push(cur.offset(prev.headingTo(cur), meters));
            }
            else {
                newVertices.push(cur.offset((cur.headingTo(next) + 180) % 360, meters));
            }
        }

        if (newVertices.length != 4) {
            throw "Cannot expand polygon";
        }

        const box = new Polygon(newVertices);

        // Identify the main side of the box
        for (let i = 0; i < 4; i++) {
            const v1 = i;
            const v2 = (i + 1) % 4;
            const hdg = Position.fromInterface(box.vertices[v1]).headingTo(box.vertices[v2]);
            if (Helpers.isCloseTo(hdg, refHeading, 1) || Helpers.isCloseTo(hdg, inverseRefHeading, 1)) {
                box._secondarySide = new Segment(box.vertices[v1], box.vertices[v2]);
            }
            else {
                box._mainSide = new Segment(box.vertices[v1], box.vertices[v2]);
            }
        }
        
        return box;
    }

    /**
     * Build a new polygon starting from its path.
     * 
     * @param path The path of the polygon
     */
    public static fromPath(path : number[][]) : Polygon
    {
        const vertexes : PositionInterface[] = [];

        for (let i = 0; i < path.length - 1; i++) {
            vertexes.push({
                latitude: path[i][0],
                longitude: path[i][1]
            });
        }

        return new Polygon(vertexes);
    }
}
