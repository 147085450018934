import { Aircraft } from "./aircraft";
import { Position } from "./position";
import { AirportWaypoint } from "./route/airport-waypoint";
import { Route } from "./route/route";
import { Waypoint } from "./route/waypoint";

export interface FuelTable {
    taxiKg : number;
    tripKg : number;
    contingencyKg: number;
    alternateKg: number;
    minimumReserveKg: number;
    minimumTakeoffKg: number;
    totalKg : number;
};

export class FlightPlanningHelper {

    /**
     * Create a new helper
     * 
     * @param aircraft The aircraft
     * @param _finalReserveMin The final reserve minutes to use
     */
    constructor(public aircraft: Aircraft, private readonly _finalReserveMin = 30)
    { 
    }

    /**
     * Calculate the minimum required fuel in Kg for the given route.
     * 
     * The fuel will contain:
     * - Taxi fuel
     * - Trip fuel (climb, cruise, airwork, descent, and landing to the destination)
     * - Alternate fuel
     * - Contingency
     * - Final reserve
     * - Extra
     * 
     * @param route The route
     */
    public getMinimumRampFuel(route : Route, extraFuelKg : number = 0) : number
    {
        if (!route.departure || !route.destination) {
            throw "Invalid route departure or destination";
        }

        const table = this.getFuelTable(route);

        // Taxi fuel
        let fuel = table.taxiKg;

        // Trip fuel
        fuel += table.tripKg;

        // Contingency
        fuel += table.contingencyKg;

        // Alternate fuel
        fuel += table.alternateKg;

        // Final Reserve (30 minutes)
        fuel += table.minimumReserveKg;

        fuel += extraFuelKg;

        return fuel;
    }

    /**
     * Get the fuel table for minimum quantities of fuel required to fly the specific route
     * 
     * @param route The route to be flow
     * @returns A fuel table
     */
    public getFuelTable(route: Route) : FuelTable
    {
        if (!route.departure || !route.destination) {
            throw "Invalid route departure or destination";
        }

        // Taxi fuel
        const taxi = this.aircraft.performance.getTaxiFuel();

        // Trip fuel
        const tripFuel = this.getRouteFuel(route.toWaypointsArray(false));

        // Contingency
        const cruiseFF = this.aircraft.performance.getCruiseFuelFlow(this.aircraft.preferredCruiseLevel);
        const contingency = Math.max(tripFuel * 0.05, cruiseFF / 60 * 5);

        // Alternate fuel
        const alternate = route.alternate ? this.getFuelBetweenWaypoints(new AirportWaypoint(route.destination), new AirportWaypoint(route.alternate), this.aircraft.preferredCruiseLevel) * 1.2 : 0;

        // Final Reserve
        const finalReserve = cruiseFF / 60 * this._finalReserveMin;

        return {
            taxiKg: taxi,
            tripKg: tripFuel,
            contingencyKg: contingency,
            alternateKg: alternate,
            minimumReserveKg: finalReserve,
            minimumTakeoffKg: tripFuel + contingency + alternate + finalReserve,
            totalKg: tripFuel + contingency + alternate + finalReserve + taxi
        }
    }

    /**
     * Get the estimated time enroute in milliseconds at the given waypoint.
     * If no waypoint is specified, the ETE to the latest one is used.
     * 
     * @param waypoints The array of waypoints
     * @param index The index waypoint.
     */
    public getRouteETE(waypoints : Waypoint[], index : number = -1) : number
    {
        if (index < 0) {
            index = waypoints.length - 1;
        }

        if (index >= waypoints.length) {
            throw "Invalid index";
        }

        if (index == 0) {
            return 0;
        }

        let time = this.getRouteETE(waypoints, index - 1);
        
        if (index > 0) {
            time += waypoints[index - 1].delayMs;
        }
        time += this.getTimeBetweenWaypoints(waypoints[index - 1], waypoints[index], this.aircraft.preferredCruiseLevel);

        return time;
    }
 
    /**
     * Get the fuel burn in Kg at a given waypoint.
     * If no waypoint is specified, the fuel to the latest one is used.
     * 
     * @param waypoints The waypoints array
     * @param index The waypoint to calculate the fuel at
     * @param includeWaypointDelay Include the delay over the index waypoint in the fuel calculation
     */
    public getRouteFuel(waypoints : Waypoint[], index : number = -1, includeWaypointDelay : boolean = true) : number
    {
        if (index < 0) {
            index = waypoints.length - 1;
        }

        if (index >= waypoints.length) {
            throw "Invalid index";
        }

        let fuel = 0;
        
        if (includeWaypointDelay && waypoints[index].delayMs > 0) {
            let ff : number
            if (waypoints[index].type == "target") {
                ff = this.aircraft.performance.getAirworkFuelFlow(waypoints[index].altitudeM);
            }
            else {
                ff = this.aircraft.performance.getHoldingFuelFlow(waypoints[index].altitudeM);
            }
            fuel += ff / 3600000 * waypoints[index].delayMs;
        }

        if (index == 0) {
            return fuel;
        }

        fuel += this.getFuelBetweenWaypoints(waypoints[index - 1], waypoints[index], this.aircraft.preferredCruiseLevel);

        fuel += this.getRouteFuel(waypoints, index - 1);

        return fuel;
    }

    /**
     * Get the minimum fuel required onboard at a given waypoint of the route
     * 
     * @param route The waypoints array
     * @param index The waypoint to calculate the minimum fuel onboard at
     */
    public getMinimumFuelOnBoard(route : Route, index : number) : number
    {
        if (!route.departure || !route.destination) {
            throw "Invalid route departure or destination";
        }
        
        const table = this.getFuelTable(route);
        return table.minimumTakeoffKg - table.contingencyKg - this.getRouteFuel(route.toWaypointsArray(false), index, false);
    }

    /**
     * The fuel needed to fly from a to b
     */
    public getFuelBetweenWaypoints(a : Waypoint, b : Waypoint, cruiseAltitude : number) : number
    {
        let fuel = 0;
        let distance = Position.fromInterface(a.position).distanceTo(b.position);
        
        // Reach cruise altitude from a
        if (a.altitudeM < cruiseAltitude) {
            const climbDistance = this.aircraft.performance.getClimbDistance(a.altitudeM, cruiseAltitude);
            if (climbDistance < distance) {
                distance -= climbDistance;
                fuel += this.aircraft.performance.getClimbFuel(a.altitudeM, cruiseAltitude);
            }
        }
        else if (a.altitudeM > cruiseAltitude) {
            const descentDistance = this.aircraft.performance.getDescentDistance(a.altitudeM, cruiseAltitude);
            if (descentDistance < distance) {
                distance -= descentDistance;
                fuel += this.aircraft.performance.getDescentFuel(a.altitudeM, cruiseAltitude);
            }
        }

        // Reach b altitude from cruise
        if (b.altitudeM < cruiseAltitude) {
            const descentDistance = this.aircraft.performance.getDescentDistance(cruiseAltitude, b.altitudeM);
            if (descentDistance < distance) {
                distance -= descentDistance;
                fuel += this.aircraft.performance.getDescentFuel(cruiseAltitude, b.altitudeM);
            }
        }
        else if (b.altitudeM > cruiseAltitude) {
            const climbDistance = this.aircraft.performance.getClimbDistance(cruiseAltitude, b.altitudeM);
            if (climbDistance < distance) {
                distance -= climbDistance;
                fuel += this.aircraft.performance.getClimbFuel(cruiseAltitude, b.altitudeM);
            }
        }

        // Remaining cruise fuel
        fuel += this.aircraft.performance.getCruiseFuel(distance, cruiseAltitude);

        return fuel;
    }

    /**
     * Get the cruise time in milliseconds between two waypoints
     * 
     * @param a The stating waypoint
     * @param b The final waypoint
     * @param cruiseAltitude The cruise altitude in meters
     * @returns The cruise time in seconds
     */
    public getTimeBetweenWaypoints(a : Waypoint, b : Waypoint, cruiseAltitude : number) : number
    {
        let time = 0;
        let distance = Position.fromInterface(a.position).distanceTo(b.position);
        
        // Reach cruise altitude from a
        if (a.altitudeM < cruiseAltitude) {
            const climbDistance = this.aircraft.performance.getClimbDistance(a.altitudeM, cruiseAltitude);
            if (climbDistance < distance) {
                distance -= climbDistance;
                time += this.aircraft.performance.getClimbTime(a.altitudeM, cruiseAltitude);
            }
        }
        else if (a.altitudeM > cruiseAltitude) {
            const descentDistance = this.aircraft.performance.getDescentDistance(a.altitudeM, cruiseAltitude);
            if (descentDistance < distance) {
                distance -= descentDistance;
                time += this.aircraft.performance.getDescentTime(a.altitudeM, cruiseAltitude);
            }
        }

        // Reach b altitude from cruise
        if (b.altitudeM < cruiseAltitude) {
            const descentDistance = this.aircraft.performance.getDescentDistance(cruiseAltitude, b.altitudeM);
            if (descentDistance < distance) {
                distance -= descentDistance;
                time += this.aircraft.performance.getDescentTime(cruiseAltitude, b.altitudeM);
            }
        }
        else if (b.altitudeM > cruiseAltitude) {
            const climbDistance = this.aircraft.performance.getClimbDistance(cruiseAltitude, b.altitudeM);
            if (climbDistance < distance) {
                distance -= climbDistance;
                time += this.aircraft.performance.getClimbTime(cruiseAltitude, b.altitudeM);
            }
        }

        // Remaining cruise time
        time += this.aircraft.performance.getCruiseTime(distance, cruiseAltitude);

        return time;
    }

}
