import { AreaStrategyResultsInterface } from "src/app/interfaces/area-strategy-results-interface";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";
import { PositionInterface } from "src/app/interfaces/position-interface";
import { SensorStrategyResultsInterface } from "src/app/interfaces/sensor-strategy-results-interface";
import { TargetInterface } from "src/app/interfaces/target-interface";
import { Aircraft } from "../aircraft";
import { Helpers } from "../helpers";
import { Leg } from "../leg";
import { Polygon } from "../polygon";
import { Position } from "../position";
import { SmartbayConfiguration } from "../smartbay-configuration/smartbay-configuration";
import { TargetOptions } from "../target-options";
import { PhotogrammetryStrategyInterface } from "src/app/interfaces/photogrammetry-strategy-interface";
import { TranslatedMessageInterface } from "src/app/interfaces/translated-message-interface";
import { TranslatedGenericError } from "../errors/translated-generic-error";
import { GroundElevationResultInterface, GroundElevationServiceInterface } from "src/app/interfaces/ground-elevation-service-interface";

export class ParallelLegsStrategy implements PhotogrammetryStrategyInterface {

    public get isValid() : boolean
    {
        return true;
    }
    
    public get maneuveringArea() : PolygonInterface
    {
        // Extend the buffered box for 1km on every long side and 500m on every short side
        if (!this._maneuveringArea) {
            const longSideBuffer = 1500;
            const shortSideBuffer = 300;
            let vertexes = [];
            let mainSide = this.bufferedBox.mainSide;
            let secondarySide = this.bufferedBox.secondarySide;
            let point = Position.fromInterface(mainSide.start);
            point = Position.fromInterface(point.offset(mainSide.reverseHeading, longSideBuffer));
            point = Position.fromInterface(point.offset(secondarySide.reverseHeading, shortSideBuffer));
            vertexes.push(point);
            point = Position.fromInterface(point.offset(mainSide.heading, 2 * longSideBuffer + mainSide.length));
            vertexes.push(point);
            point = Position.fromInterface(point.offset(secondarySide.heading, 2 * shortSideBuffer + secondarySide.length));
            vertexes.push(point);
            point = Position.fromInterface(point.offset(mainSide.reverseHeading, 2* longSideBuffer + mainSide.length));
            vertexes.push(point);
            this._maneuveringArea = new Polygon(vertexes);
        }
        return this._maneuveringArea;
    }

    public get legsBox() : PolygonInterface
    {
        if (!this._legsBox) {
            console.warn("Empty legs data");
            return this._bufferedBox;
        }

        return this._legsBox;
    }

    public get bufferedBox() : PolygonInterface
    {
        if (!this._bufferedBox) {
            // Extend the minimum bounding box 150 meters on the longest sides
            const extendM = 150;
            this._bufferedBox = this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).expandOnMainSideDirection(extendM);
        }
        return this._bufferedBox;
    }

    private _bufferedBox : PolygonInterface;
    private _maneuveringArea : PolygonInterface;
    private _legsData : AreaStrategyResultsInterface;
    private _legsBox : PolygonInterface;

    constructor(
        private _target : TargetInterface, 
        private _config : SmartbayConfiguration,
        public readonly lateralDeviation : number,
        public readonly targetOptions : TargetOptions,
        private _aircraft : Aircraft,
        private _elevationService : GroundElevationServiceInterface = null
    )
    {
        this._maneuveringArea = null;
        this._bufferedBox = null;
        this._legsBox = null;
        this._legsData = null;
    }

    /**
     * Get a list of dynamically spaced legs based on the target ground elevation.
     * 
     * @param progressCallback A progress callback used to provide updates on the building process.
     */
    public async generate(progressCallback : (progress : number) => Promise<void> = null) : Promise<AreaStrategyResultsInterface>
    {
        let legs : Leg[] = [];
        let data : SensorStrategyResultsInterface[];
        let targetMinMax : false | GroundElevationResultInterface = false;
        if (progressCallback) {
            await progressCallback(0);
        }
        if (this._elevationService) {

            // Manual target elevations set by the user
            if (this.targetOptions.useManualElevations) {
                targetMinMax = {
                    count: 2,
                    max: this.targetOptions.maxManualElevation,
                    min: this.targetOptions.minManualElevation,
                    maxPosition: null,
                    minPosition: null
                };
            }
            // Automatic target elevations using HGT
            else {
                targetMinMax = await this._elevationService.getMinMaxElevations(this._target, 30, async (progress : number) => {
                    if (progressCallback) {
                        await progressCallback(progress / 3);
                    }
                });
            }
            // The maneuvering area is always automatic calculated
            const manMinMax = await this._elevationService.getMinMaxElevations(this.maneuveringArea, 200, async (progress : number) => {
                if (progressCallback) {
                    await progressCallback((1 / 3) + (progress / 3));
                }
            });
            if (targetMinMax !== false && manMinMax !== false) {
                data = await this.getPhotogrammetryData(targetMinMax, manMinMax.max, async (progress : number) => {
                    if (progressCallback) {
                        progressCallback((2 / 3) + (progress / 3));
                    }
                });
            }
        }
        else {
            data = await this.getPhotogrammetryData({
                min: 0,
                minPosition: undefined,
                max: 0,
                maxPosition: undefined,
                count: 0
            }, 0);
            if (progressCallback) {
                await progressCallback(0.667);
            }
        }

        if (!data || !data.length) {
            console.warn("Empty photogrammetry data for " + this._target.uuid);
            return {
                legs: [],
                sensorsData: [],
                maneuveringArea: this.maneuveringArea,
                legsBox: this._legsBox
            };
        }

        const referenceSide = this.bufferedBox.mainSide;

        // Get the orthogonal heading
        const legCenter = referenceSide.center;
        const offsetHeading = Position.fromInterface(legCenter).headingTo(this.bufferedBox.center);
        const offset = data[0].initialOffsetX;

        // Center the legs by using an offset start
        let start = Position.fromInterface(referenceSide.start).offset(offsetHeading + 180 % 360, offset);
        let end = Position.fromInterface(referenceSide.end).offset(offsetHeading + 180 % 360, offset);

        for (let i = 0; i < data[0].totalLegsCount; i++) {
            legs.push(new Leg(start, end, data[0].zStep[i]));
            start = Position.fromInterface(start).offset(offsetHeading, data[0].xStep[i]);
            end = Position.fromInterface(end).offset(offsetHeading, data[0].xStep[i]);
        }

        if (progressCallback) {
            await progressCallback(1);
        }

        let gliderange : PolygonInterface = null;

        // Get the glide range
        if (this._elevationService && legs.length > 0 && !!this._aircraft.glideRatio) {
            const altitude = legs[Math.floor(data[0].totalLegsCount / 2)].altitude;
            gliderange = await this._elevationService.getGlideRange(this._target.center, altitude, this._aircraft.glideRatio);

            if (!gliderange) {
                console.error("Cannot get the glide range for " + this._target.uuid);
            }
        }

        let sensorsData : SensorStrategyResultsInterface[] = [];
        for (let sensorData of data) {
            sensorData.glideRangeVertices = gliderange?.vertices ?? null;
            sensorsData.push(sensorData);
        }

        // Build the legs box
        if (!legs.length) {
            return {
                legs: [],
                sensorsData: [],
                maneuveringArea: this.maneuveringArea,
                legsBox: this._legsBox
            };
        }
        
        const l1 = legs[0];
        const l2 = legs[legs.length - 1];

        let v1 = Position.fromInterface(l1.start).offset((Position.fromInterface(l1.start).headingTo(l2.start) + 180) % 360, this.lateralDeviation * 1.5);
        v1 = Position.fromInterface(v1).offset(l1.reverseHeading, this.lateralDeviation * 1.5);

        let v2 = Position.fromInterface(l1.end).offset((Position.fromInterface(l1.end).headingTo(l2.end) + 180) % 360, this.lateralDeviation * 1.5);
        v2 = Position.fromInterface(v2).offset(l1.heading, this.lateralDeviation * 1.5);

        let v3 = Position.fromInterface(l2.end).offset((Position.fromInterface(l2.end).headingTo(l1.end) + 180) % 360, this.lateralDeviation * 1.5);
        v3 = Position.fromInterface(v3).offset(l2.heading, this.lateralDeviation * 1.5);

        let v4 = Position.fromInterface(l2.start).offset((Position.fromInterface(l2.start).headingTo(l1.start) + 180) % 360, this.lateralDeviation * 1.5);
        v4 = Position.fromInterface(v4).offset(l2.reverseHeading, this.lateralDeviation * 1.5);

        this._legsBox = new Polygon([v1, v2, v3, v4]);

        this._legsData = {
            legs: legs,
            sensorsData: sensorsData,
            maneuveringArea: this.maneuveringArea,
            legsBox: this._legsBox
        };
        return this._legsData;
    }

    /** ------------------ Helpers ------------------------------ */

    /**
     * Get the photogrammetric data calculated using this target, a specific sensor and targetOptions
     * 
     * @param targetMinMax.min The minimum ground elevation in the target area
     * @param targetMinMax.max The maximum ground elevation in the target area
     * @param maxAreaElevation The maximum ground elevation in the maneuvering area
     * @param progressCallback A progress callback used to provide updates on the building process.
     */
    public async getPhotogrammetryData(
        targetMinMax: GroundElevationResultInterface,
        maxAreaElevation: number,
        progressCallback : (progress : number) => Promise<void> = null
    ) : Promise<SensorStrategyResultsInterface[]>
    {
        let results : SensorStrategyResultsInterface[] = [];
        let heightM : number;
        let xStep : number;
        let unlimitedXStep : number;
        const compxSteps : number[] = [];
        let zStep : number[] = [];

        // Ensure that the primary sensor is the first in line
        const activeConfig = this._config.getActiveSensorsConfiguration(this.targetOptions);
        const trolleySensors = activeConfig.activeSensors;

        let airworkSpeedMs = 0;
        let legsCount = 0;
        const errLegsCount = 30;
        let targetTerrainSeparation;
        let altitudeM;
        let compxStep = 0;
        let minCompXStep = Number.MAX_SAFE_INTEGER;
        // Distance between legs
        let initialOffsetX = 0;

        for (let sensorIndex = 0; sensorIndex < trolleySensors.length; sensorIndex++) {

            const pair = trolleySensors[sensorIndex];
            if (!pair.sensor.isValid) {
                continue;
            }
            const warnings : TranslatedMessageInterface[] = [];
            const errors : TranslatedMessageInterface[] = [];
            // Ground height over the target calculated based on the primary sensor
            if (sensorIndex == 0) {
                heightM = pair.sensor.getOptimalAcquisitionHeight(this.targetOptions.resolution, this._target.landscapeType, this.targetOptions.lidarFrequencyHz);
                airworkSpeedMs = this._aircraft.performance.getAirworkSpeed(heightM + targetMinMax.min);
                altitudeM = heightM + targetMinMax.min;
                targetTerrainSeparation = altitudeM - targetMinMax.max;
            }
            // Altitude over the target
            const areaTerrainSeparation = altitudeM - maxAreaElevation;
            const reducedTargetTerrainSep = targetTerrainSeparation < Helpers.feetToMeters(500);
            const reducedManAreaTerrainSep = areaTerrainSeparation < Helpers.feetToMeters(500);
            const warnTargetTerrainSep = targetTerrainSeparation < Helpers.feetToMeters(1000);
            const warnManAreaTerrainSep = areaTerrainSeparation < Helpers.feetToMeters(1000);

            if (reducedTargetTerrainSep) {
                warnings.push({
                    l10nKey: "leg.warning.obsSepTarget", 
                    params: {
                        meters: Math.round(targetTerrainSeparation),
                        feet: Math.round(Helpers.metersToFeet(targetTerrainSeparation))
                    }
                });
            }

            if (reducedManAreaTerrainSep) {
                warnings.push({
                    l10nKey: "leg.warning.obsSepManArea",
                    params: {
                        meters: Math.round(areaTerrainSeparation),
                        feet: Math.round(Helpers.metersToFeet(areaTerrainSeparation))
                    }
                });
            }

            // Footprint width
            const SLb = pair.sensor.getFootprintHorizontalSpan(heightM);
            const SLa = pair.sensor.getFootprintHorizontalSpan(targetTerrainSeparation);
            const mainSide = this.bufferedBox.mainSide;
            const secondarySide = this.bufferedBox.secondarySide;

            if (sensorIndex == 0) {
                // Equidistant legs
                // Data needed to compute the dynamic legs as well
                const sidelap = this.targetOptions.sidelap;
                const resolution = this.targetOptions.resolution;
                const lidarFrequencyHz = this.targetOptions.lidarFrequencyHz;
                const secondary = trolleySensors.length > 1 ? trolleySensors[1].sensor : null;
                xStep = pair.sensor.getOptimalDistanceBetweenLegs(altitudeM, targetMinMax, sidelap, resolution, lidarFrequencyHz, secondary);
                unlimitedXStep = pair.sensor.getOptimalDistanceBetweenLegs(altitudeM, targetMinMax, sidelap, resolution, lidarFrequencyHz);
                compxStep = xStep - this.lateralDeviation;
                minCompXStep = compxStep;
                legsCount = Math.ceil(secondarySide.length / compxStep) + 1;
                let totalWidth = 0;
                for (let x = 0; x < legsCount - 1; x++) {
                    compxSteps.push(compxStep);
                    zStep.push(altitudeM);
                    totalWidth += compxStep;
                }
                zStep.push(altitudeM); // One more leg for which the altitude is needed
                initialOffsetX = (totalWidth - this.bufferedBox.secondarySide.length) / 2;
            
                if (!!progressCallback) {
                    await progressCallback(0);
                }

                if (this._elevationService) {
                    // Legs with dynamic horizontal spacing
                    if (this.targetOptions.legsSpacingType == "dynamic-hor") {
                        const secondarySideLength = this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).secondarySide.length;
                        const bbmainSide = this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).mainSide;
                        const legCenter = Position.fromInterface(bbmainSide.start).offset(bbmainSide.heading, bbmainSide.length / 2);
                        const offsetHeading = Position.fromInterface(legCenter).headingTo(this._target.getMinimumBoundingBox().center);
                        let xLength = 0;
                        let start = Position.fromInterface(this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).mainSide.start).offset((offsetHeading + 180) % 360, initialOffsetX);
                        let end = Position.fromInterface(this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).mainSide.end).offset((offsetHeading + 180) % 360, initialOffsetX);

                        // Reset some data
                        compxSteps.splice(0, compxSteps.length);
                        zStep.splice(0, zStep.length);

                        zStep.push(altitudeM);
                        legsCount = 1;

                        while (xLength < secondarySideLength + initialOffsetX && legsCount < errLegsCount && minCompXStep >= this.lateralDeviation) {
                            const sampleStep = 30;

                            // The algorithm wants to measure a segment from -0.2 and +0.8 xStep.
                            // Let's create a polygon to measure the elevations.

                            const vertices : PositionInterface[] = [];
                            const headingBack = (offsetHeading + 180) % 360;
                            vertices.push(Position.fromInterface(start).offset(headingBack, compxStep * 0.2));
                            vertices.push(Position.fromInterface(end).offset(headingBack, compxStep * 0.2));
                            const headingForward = offsetHeading;
                            vertices.push(Position.fromInterface(end).offset(headingForward, compxStep * 0.8));
                            vertices.push(Position.fromInterface(start).offset(headingForward, compxStep * 0.8));

                            // Then get the min and max elevation in the matrix of points
                            const minMax = await this._elevationService.getMinMaxElevations(new Polygon(vertices), sampleStep);
                            if (minMax === false) {
                                console.error("Cannot get min and max elevation");
                                return;
                            }

                            if (minMax.min < targetMinMax.min - 3) {
                                console.warn("Local minimum (" + minMax.min + ") lower than general minimum (" + targetMinMax.min + ")");
                            }
                            if (minMax.max > targetMinMax.max + 3) {
                                console.warn("Local minimum (" + minMax.max + ") higher than general maximum (" + targetMinMax.max + ")");
                            }
                                
                            // Find the actual spacing between this and the next leg
                            let localxStep = pair.sensor.getOptimalDistanceBetweenLegs(altitudeM, minMax, this.targetOptions.sidelap, this.targetOptions.resolution);
                            let localCompxStep= localxStep - this.lateralDeviation;
                            minCompXStep = Math.min(localCompxStep);

                            // Store the step for this leg
                            compxSteps.push(localCompxStep);
                            zStep.push(altitudeM);

                            // Move the current position to the next leg
                            start = Position.fromInterface(start).offset(offsetHeading, localCompxStep);
                            end = Position.fromInterface(end).offset(offsetHeading, localCompxStep);

                            legsCount++;
                            xLength += localCompxStep;

                            if (!!progressCallback) {
                                await progressCallback(xLength / (secondarySideLength + initialOffsetX));
                            }
                        }
                    }
                    // Legs at variable altitude
                    else if (this.targetOptions.legsSpacingType == "dynamic-ver") {
                        const secondarySideLength = this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).secondarySide.length;
                        const bbmainSide = this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).mainSide;
                        const legCenter = Position.fromInterface(bbmainSide.start).offset(bbmainSide.heading, bbmainSide.length / 2);
                        const offsetHeading = Position.fromInterface(legCenter).headingTo(this._target.getMinimumBoundingBox().center);
                        let xLength = 0;
                        let start = Position.fromInterface(this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).mainSide.start).offset((offsetHeading + 180) % 360, initialOffsetX);
                        let end = Position.fromInterface(this._target.getMinimumBoundingBox(this.targetOptions.headingOffset).mainSide.end).offset((offsetHeading + 180) % 360, initialOffsetX);

                        // Reset some data
                        compxSteps.splice(0, compxSteps.length);
                        zStep.splice(0, zStep.length);
                        legsCount = 1;
                        targetTerrainSeparation = Number.MAX_SAFE_INTEGER;

                        while (true) {
                            const sampleStep = 30;

                            // The algorithm wants to measure a segment from -0.2 and +0.8 xStep.
                            // Let's create a polygon to measure the elevations.

                            const vertices : PositionInterface[] = [];
                            const headingBack = (offsetHeading + 180) % 360;
                            vertices.push(Position.fromInterface(start).offset(headingBack, compxStep * 0.2));
                            vertices.push(Position.fromInterface(end).offset(headingBack, compxStep * 0.2));
                            const headingForward = offsetHeading;
                            vertices.push(Position.fromInterface(end).offset(headingForward, compxStep * 0.8));
                            vertices.push(Position.fromInterface(start).offset(headingForward, compxStep * 0.8));

                            // Then get the min and max elevation in the matrix of points
                            const minMax = await this._elevationService.getMinMaxElevations(new Polygon(vertices), sampleStep);
                            if (minMax === false) {
                                console.error("Cannot get min and max elevation");
                                return;
                            }

                            if (minMax.min < targetMinMax.min - 3) {
                                console.warn("Local minimum (" + minMax.min + ") lower than general minimum (" + targetMinMax.min + ")");
                            }
                            if (minMax.max > targetMinMax.max + 3) {
                                console.warn("Local minimum (" + minMax.max + ") higher than general maximum (" + targetMinMax.max + ")");
                            }

                            // Calculate the step to move forward to the other leg
                            const localAltitudeM = minMax.min + heightM;
                            const localTargetMinTerrainSeparation = localAltitudeM - minMax.max;
                            // Get the minimum terrain separation on target.
                            //  It's used to calculate the sensors shot time.
                            targetTerrainSeparation = Math.min(localTargetMinTerrainSeparation, targetTerrainSeparation);
                            const localSLa = pair.sensor.getFootprintHorizontalSpan(localTargetMinTerrainSeparation);
                                
                            // Find the actual spacing between this and the next leg
                            let localxStep = localSLa * (1 - this.targetOptions.sidelap);
                            let localCompxStep= localxStep - this.lateralDeviation;
                            minCompXStep = Math.min(localCompxStep);

                            // Store the step for this leg
                            compxSteps.push(localCompxStep);
                            zStep.push(localAltitudeM);
                            // Stop the legs computation if the area is fully covered
                            if (xLength >= secondarySideLength + initialOffsetX || legsCount >= errLegsCount || minCompXStep < this.lateralDeviation) {
                                break;
                            }

                            // Move the current position to the next leg
                            start = Position.fromInterface(start).offset(offsetHeading, localCompxStep);
                            end = Position.fromInterface(end).offset(offsetHeading, localCompxStep);

                            legsCount++;
                            xLength += localCompxStep;

                            if (!!progressCallback) {
                                await progressCallback(xLength / (secondarySideLength + initialOffsetX));
                            }
                        }
                    }

                    if (!!progressCallback) {
                        await progressCallback(1);
                    }
                }
            }

            if (minCompXStep < this.lateralDeviation) {
                errors.push({ l10nKey: "leg.error.thinnerThanTunnel"});
            }

            if (legsCount >= errLegsCount) {
                errors.push({ l10nKey: "leg.error.maxLegsCountReached", params: { maxCount: errLegsCount } });
            }

            const activeLegsCount = this.targetOptions.activeLegs.length == 0 ? legsCount : legsCount - (legsCount - this.targetOptions.activeLegs.length);
            const highLegsCount = activeLegsCount > 15;

            if (highLegsCount) {
                warnings.push({ l10nKey: "leg.warning.tooManyLegs" });
            }

            if (legsCount == 1) {
                warnings.push({ l10nKey: "leg.warning.oneLeg"});
            }

            // console.log(this._target.name, compxSteps);

            // Distance between shots
            const ha = Helpers.squareMetersToHectares(this._target.area);
            const totalDistance = mainSide.length * activeLegsCount;
            const overflyTime = (totalDistance / airworkSpeedMs + this._aircraft.minimumInversionTime * activeLegsCount) * 1000;
            const productivity = (ha / overflyTime) * 3600000;
            const sidelapHighestPoint = (SLa - compxStep) / SLa;
            const sidelapLowestPoint = (SLb - compxStep) / SLb;
            
            let data : SensorStrategyResultsInterface = {
                lidarPrf: 0,
                sensorIndex: pair.sensorIndex,
                trolleyIndex: pair.trolleyIndex,
                warnings: warnings,
                errors: errors,
                uncompensatedXStep: xStep,
                xStep: compxSteps,
                yStep: 0,
                zStep: zStep,
                initialOffsetX: initialOffsetX,
                totalLegsCount: legsCount,
                effectiveLegsCount: activeLegsCount,
                altitude: altitudeM,
                height: heightM,
                maneuveringObsSeparation: areaTerrainSeparation,
                targetObsSeparation: targetTerrainSeparation,
                reducedManAreaObsSep: reducedManAreaTerrainSep,
                reducedTargetAreaObsSep: reducedTargetTerrainSep,
                warnManAreaObsSep: warnManAreaTerrainSep,
                warnTargetAreaObsSep: warnTargetTerrainSep,
                highLegsCount: highLegsCount,
                shotTime: 0,
                shotsPerLeg: 0,
                totalDistance: totalDistance,
                overflyTime: overflyTime,
                totalShots: 0,
                productivity: productivity,
                totalShotsSize: 0,
                sidelapHighestPoint: sidelapHighestPoint,
                sidelapLowestPoint: sidelapLowestPoint,
                guaranteedSidelap: 0,
                guaranteedOverlap: 0,
                resolutionHighestPoint: 0,
                resolutionLowestPoint: 0,
                resolutionUnit: "",
                footPrintWidthHighestPoint: SLa,
                footPrintWidthLowestPoint: SLb,
                glideRangeVertices: null,
                minElevation: targetMinMax.min,
                maxElevation: targetMinMax.max,
                maxElevationPosition: targetMinMax.maxPosition,
                minElevationPosition: targetMinMax.minPosition
            };

            data = pair.sensor.populatePhotogrammetrySensorData(
                data,
                this._aircraft,
                targetMinMax,
                xStep,
                altitudeM,
                this.targetOptions.sidelap,
                this.targetOptions.overlap,
                activeLegsCount,
                this._bufferedBox,
                this.targetOptions.lidarFrequencyHz
            );

            // Warn the user if the sidelap is limited by a secondary sensor
            if (sensorIndex == 0 && unlimitedXStep > xStep) {
                data.warnings.push({ l10nKey: "leg.warning.legsDistLimitBySecondary", params: { perc: Math.round(data.guaranteedSidelap * 100), sensor: pair.sensor.name } });
            }

            results.push(data);
        }

        return results;
    }
}
