import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Mission } from '../classes/mission';
import { SmartbayConfiguration } from '../classes/smartbay-configuration/smartbay-configuration';
import { ContainsTargetWaypointVisitor } from '../classes/visitors/contains-target-waypoint-visitor';
import { MissionDataInterface } from '../interfaces/data/mission-data-interface';
import { TargetInterface } from '../interfaces/target-interface';
import { AircraftService } from './aircraft.service';
import { AirportService } from './airport.service';
import { PilotService } from './pilot.service';
import { StorageService } from './storage/storage.service';
import { TargetService } from './target.service';
import { AuthService } from './auth/auth.service';
import { Trolley } from '../classes/smartbay-configuration/trolley';
import { Sensor } from '../classes/smartbay-configuration/sensor';

@Injectable({
  providedIn: 'root'
})
export class MissionService {

    private _missions : Mission[];
    private _missionInterfaces : MissionDataInterface[];
    private _missionsObservable : Subject<Mission[]>;
    private _missionsPromise : Promise<void>;

    constructor(
        public storage : StorageService,
        private _targetService : TargetService,
        private _airportService : AirportService,
        private _aircraftService : AircraftService,
        private _pilotService : PilotService,
        private _authService : AuthService
    )
    {
        this._missions = [];
        this._missionInterfaces = [];
        this._missionsObservable = new Subject<Mission[]>();

        // Keep all the interfaces updated
        this._missionsPromise = new Promise(resolve => {
            this.storage.getAllMissions().subscribe(async interfaces => {
                this._missionInterfaces = interfaces;
                await this.updateMissionArray();
                resolve();
            });
        });
    }

    /** Update the missions array with the data already owned by the service */
    private async updateMissionArray()
    {
        console.log("Found " + this._missionInterfaces.length + " missions...");
        this._missions = [];
        for (let m of this._missionInterfaces) {
            const mission = await Mission.fromDataInterface(m, this._airportService, this._targetService, this._pilotService, this._aircraftService);
            this._missions.push(mission);
        }
        this._missionsObservable.next(this._missions);
    }

    public async get(id : string) : Promise<Mission>
    {
        // Targets
        await this._missionsPromise;
        
        const mission = this._missions.find(m => m.id == id);
        if (mission) {
            return mission;
        }

        const int = this._missionInterfaces.find(m => m.id == id);
        if (int) {
            const mission = await Mission.fromDataInterface(int, this._airportService, this._targetService, this._pilotService, this._aircraftService);
            this._missions.push(mission);
            return mission;
        }

        return null;
    }

    public getAll() : Observable<Mission[]>
    {
        return new Observable<Mission[]>(subscriber => {
            this._missionsPromise.then(() => {
                subscriber.next(this._missions);
            });
            
            let sub = this._missionsObservable.subscribe(next => {
                subscriber.next(next);
            });

            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
        
    }

    public async save(mission : Mission) : Promise<string>
    {
        const id = await this.storage.saveMission(mission.toDataInterface());
        mission.onAfterSave();
        return id;
    }

    public async create(name : string) : Promise<Mission>
    {
        const aircrafts = await this._aircraftService.aircrafts;
        const mission = new Mission("", Date.now(), aircrafts[0], SmartbayConfiguration.defaultConfiguration);
        mission.name = name;
        mission.dispatcher = await this._pilotService.get(this._authService.currentUserEmail);

        // ---------------------------
        // WARNING! CODE SMELLS AHEAD!
        // ---------------------------
        //
        // Since the current DB does not account for per-user default target options
        // and there's much work going on on a new platform, I'll put this smells 
        // right here. Moshe wants different GSD, overlap, and sidelap default values...
        // This code should be refactored ASAP.
        const teams = this._authService.getUserTeams();
        if (!!teams.find(t => t.id == "av_aerialworks")) {
            mission.defaultGsd = 5;
            mission.defaultOverlap = 0.7;
            mission.defaultSidelap = 0.3;
            mission.smartBayConfig.frontTrolley = Trolley.Multicamera;
            mission.smartBayConfig.frontTrolley.setSensor(Sensor.PhaseOneiXMRS150F, 0);
            mission.smartBayConfig.frontTrolley.setSensor(Sensor.NullSensor, 1);
            mission.smartBayConfig.frontTrolley.sensors[0].lensIndex = 1;
            mission.smartBayConfig.frontTrolley.sensors[0].commandGroupIndex = 1;
            mission.smartBayConfig.primarySensorIndex = 0;
        }
        // ----- END OF WARNING -------

        const id = await this.save(mission);
        if (!id) {
            return null;
        }
        this._missions.push(mission);
        mission.id = id;
        return mission;
    }

    public async delete(mission : Mission) : Promise<void>
    {
        await this.storage.deleteMission(mission.id);
    }

    /**
     * Get all the missions that contain a specific target.
     * 
     * @param target The target
     * @returns An observable of all the missions that contain the target
     */
    public getMissionsOf(target : TargetInterface) : Observable<Mission[]>
    {
        return new Observable<Mission[]>(subscriber => {
            let sub : Subscription = null;
            this._missionsPromise.then(() => {
                let getTargets = () => {
                    const list : Mission[] = [];
                    const visitor = new ContainsTargetWaypointVisitor(target);
                    for (let mission of this._missions) {
                        mission.route.acceptWaypointVisitor(visitor);
                        if (visitor.result && !list.find(m => m.id == mission.id)) {
                            list.push(mission);
                        }
                        visitor.reset();
                    }
                    return list;
                };

                subscriber.next(getTargets());
                sub = this._missionsObservable.subscribe(() => {
                    subscriber.next(getTargets());
                });
            });
            return {
                unsubscribe: () => {
                    if (sub) {
                        sub.unsubscribe();
                    }
                }
            }
        });
    }

    /**
     * Export the given missions as JSON object.
     * 
     * @param ids The missions ids
     */
    public exportToJson(missions : Mission[]) : MissionDataInterface[]
    {
        const interfaces : MissionDataInterface[] = [];
        for (let mission of missions) {
            interfaces.push(mission.toDataInterface());
        }
        return interfaces;
    }

    /**
     * Import the missions from a JSON data exported
     * using the exportToJson method.
     * If a mission is both in the JSON file and in the database,
     * it will be duplicated.
     * 
     * @param missions The json exported by exportToJson
     */
    public async importFromJson(missions : any)
    {
        for (let mission of missions as MissionDataInterface[]) {
            mission.id = "";
            const newMission = await this.create(mission.name);
            mission.id = newMission.id;
            await this.storage.saveMission(mission);
        }
    }
}
