import { SmartbayDataInterface } from "src/app/interfaces/data/smartbay-data-interface";
import { TrolleyDataInterface } from "src/app/interfaces/data/trolley-data-interface";
import { TrolleySensorPairInterface } from "src/app/interfaces/trolley-sensor-pair-interface";
import { TargetOptions } from "../target-options";
import { Sensor } from "./sensor";
import { Trolley } from "./trolley";
import { EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";

export class SmartbayConfiguration {

    /**
     * The onboard trolleys (three).
     * Trolleys[0] => Front trolley.
     * Trolleys[1] => Center trolley.
     * Trolleys[2] => Rear trolley.
     * 
     * Use the frontTrolley, centerTrolley, and rearTrolley getters for fast access.
     */
    public readonly trolleys : Trolley[];

    public get primarySensorIndex() : number
    {
        return this._primarySensorIndex;
    }

    public set primarySensorIndex(index : number)
    {
        this._primarySensorIndex = index;
        this.onChange.emit();
    }

    /**
     * If true, the BIU is not activated during flight
     * and the trolley configuration is disregarded.
     */
    public get biuOff(): boolean
    {
        return this._biuOff;
    }

    public set biuOff(value : boolean)
    {
        this._biuOff = value;
        this.onChange.emit();
    }

    /** A list of pairs sensor+trolley of all the sensor objects in the configuration */
    public get sensorsTrolleyPairs() : TrolleySensorPairInterface[]
    {
        let sensors : TrolleySensorPairInterface[] = [];
        for (let tIndex = 0; tIndex < this.trolleys.length; tIndex++) {
            for (let sIndex = 0; sIndex < this.trolleys[tIndex].sensors.length; sIndex++) {
                sensors.push({
                    trolley: this.trolleys[tIndex],
                    sensor: this.trolleys[tIndex].sensors[sIndex],
                    trolleyIndex: tIndex,
                    sensorIndex: sIndex
                });
            }
        }
        return sensors;
    }

    public get frontTrolley() : Trolley
    {
        return this.trolleys[0];
    }

    public set frontTrolley(t : Trolley)
    {
        if (!!this._frontTrolleySubscription) {
            this._frontTrolleySubscription.unsubscribe();
        }
        this.trolleys[0] = t;
        this._frontTrolleySubscription = t.onChange.subscribe(() => {
            this.onChange.emit();
        });
        this.onChange.emit();
    }

    public get centerTrolley() : Trolley
    {
        return this.trolleys[1];
    }

    public set centerTrolley(t : Trolley)
    {
        if (!!this._centerTrolleySubscription) {
            this._centerTrolleySubscription.unsubscribe();
        }
        this.trolleys[1] = t;
        this._centerTrolleySubscription = t.onChange.subscribe(() => {
            this.onChange.emit();
        });
        this.onChange.emit();
    }

    public get rearTrolley() : Trolley
    {
        return this.trolleys[2];
    }

    public set rearTrolley(t : Trolley)
    {
        if (!!this._rearTrolleySubscription) {
            this._rearTrolleySubscription.unsubscribe();
        }
        this.trolleys[2] = t;
        this._rearTrolleySubscription = t.onChange.subscribe(() => {
            this.onChange.emit();
        });
        this.onChange.emit();
    }

    /** The BIU mass */
    public get BIUMass() : number
    {
        return 0.5;
    }

    /** The smartbay mass in kg */
    public get mass() : number
    {
        let weight = this.BIUMass;
        for (let trolley of this.trolleys) {
            weight += trolley.mass;
        }
        return weight;
    }

    private _primarySensorIndex : number;
    private _biuOff : boolean;
    private _frontTrolleySubscription : Subscription;
    private _centerTrolleySubscription : Subscription;
    private _rearTrolleySubscription : Subscription;

    /**
     * Fired every time this configuration changes
     */
    public readonly onChange : EventEmitter<void>;

    constructor(trolleys : Trolley[] = [])
    {
        this.trolleys = trolleys.splice(0, 3); // Limit the trolleys count to 3.
        this._primarySensorIndex = 0;
        this._biuOff = false;
        this._frontTrolleySubscription = null;
        this._centerTrolleySubscription = null;
        this._rearTrolleySubscription = null;
        this.onChange = new EventEmitter<void>();
    }

    /**
     * Get the active sensors configuration based on the provided target options.
     * The primary sensor is at index 0 of the array.
     * 
     * @param targetOptions The target options
     * 
     * @returns The configuration or null on error
     */
    public getActiveSensorsConfiguration(targetOptions : TargetOptions) : { activeSensors : TrolleySensorPairInterface[], primarySensorIndex : number }
    {
        const trolleySensors : TrolleySensorPairInterface[] = [];
        const primarySensorIndex = targetOptions.primarySensorIndex > -1 ? targetOptions.primarySensorIndex : this.primarySensorIndex;
        for (let i = 0; i < this.sensorsTrolleyPairs.length; i++) {
            // Only use the sensors that are in the target options
            if (targetOptions.activeSensorsIndexes.length > 0 && targetOptions.activeSensorsIndexes.indexOf(i) == -1) {
                continue;
            }
            // The primary sensor is on top
            if (i == primarySensorIndex) {
                trolleySensors.splice(0, 0, this.sensorsTrolleyPairs[i]);
                continue;
            }
            // The others follow
            trolleySensors.push(this.sensorsTrolleyPairs[i]);
        }

        return {
            activeSensors: trolleySensors,
            primarySensorIndex: primarySensorIndex
        };
    }

    public clone() : SmartbayConfiguration
    {
        const config = new SmartbayConfiguration();
        config.primarySensorIndex = this.primarySensorIndex;
        for (let trolley of this.trolleys) {
            config.trolleys.push(trolley.clone());
        }
        config.biuOff = this.biuOff;
        return config;
    }

    public equals(other : SmartbayConfiguration) : boolean
    {
        if (other.primarySensorIndex != this.primarySensorIndex) {
            return false;
        }
        if (other.trolleys.length != this.trolleys.length) {
            return false;
        }
        if (other.biuOff != this.biuOff) {
            return false;
        }
        for (let i = 0; i < other.trolleys.length; i++) {
            if (!other.trolleys[i].equals(this.trolleys[i])) {
                return false;
            }
        }
        return true;
    }

    public toDataInterface() : SmartbayDataInterface
    {
        let trolleys : TrolleyDataInterface[] = [];
        for (let trolley of this.trolleys) {
            trolleys.push(trolley.toDataInterface());
        }
        return {
            trolleys: trolleys,
            primarySensorIndex: this.primarySensorIndex,
            biuOff: this.biuOff
        };
    }

    public static fromDataInterface(int : SmartbayDataInterface) : SmartbayConfiguration
    {
        let config = new SmartbayConfiguration();
        config.primarySensorIndex = int.primarySensorIndex ?? 0;
        if (!!int.trolleys) {
            for (let i = 0; i < 3 && i < int.trolleys.length; i++) {
                config.trolleys.push(Trolley.fromDataInterface(int.trolleys[i]));
            }
        }
        config.biuOff = !!int.biuOff;
        return config;
    }

    public static get defaultConfiguration() : SmartbayConfiguration
    {
        let config = new SmartbayConfiguration([
            Trolley.Multicamera2Prototype,
            Trolley.Naked,
            Trolley.PowerSupply
        ]);
        config.trolleys[0].sensors[0] = Sensor.SonyILCE7RM4L;
        config.trolleys[0].sensors[1] = Sensor.FLIRA8581;
        config.primarySensorIndex = 1;
        return config;
    }
}
