export class Color {

    public static get Blue() : string
    {
        return "#0384fc";
    }

    public static get Green() : string
    {
        return "#50db14";
    }

    public static get Magenta() : string
    {
        return "#ad00a5";
    }

    public static get LightBlue1() : string
    {
        return "#f5fdff";
    }

    public static get LightBlue2() : string
    {
        return "#def1fc";
    }

    public static get DigiskyBlue() : string
    {
        return "#0f418a";
    }

    public static get DigiskyLightBlue() : string
    {
        return "#9fc6eb";
    }    

    public static get Brown() : string
    {
        return "#6b5d49";
    }

    public static get LightBrown() : string
    {
        return "#ab9574";
    }

    public static get LightYellow() : string
    {
        return "#f5db69";
    }

    public static get Yellow() : string
    {
        return "#ffe600";
    }

    public static get Red() : string
    {
        return "#ff0000";
    }

    public static get DarkGray() : string
    {
        return "#3d3d3d";
    }

    public static get Gray() : string
    {
        return "#757575";
    }

    public static get Orange() : string
    {
        return "#ff9100";
    }
}
