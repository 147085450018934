import { PositionInterface } from "../interfaces/position-interface";
import { TargetDataInterface } from "../interfaces/data/target-data-interface";
import { TargetVisitorInterface } from "../interfaces/target-visitor-interface";
import { Polygon } from "./polygon";
import { Position } from "./position";
import { TargetAcceptorInterface } from "../interfaces/target-acceptor-interface";
import { EventEmitter } from "@angular/core";
import { FlowStatus } from "./flow-status";
import { NoteDataInterface } from "../interfaces/data/note-data-interface";
import { TargetInterface, TargetLandscapeType, TargetObservableProperties, TargetOverflySlot, TargetOverflyWeather } from "../interfaces/target-interface";
import { Sensor } from "./smartbay-configuration/sensor";

export class Target extends Polygon implements TargetInterface, TargetAcceptorInterface {

    /** The UUID of this target */
    public get uuid(): string { return this._uuid; }

    /** If true, it means that it can be joined with other targets to form a bigger target */
    public get joinable() : boolean
    {
        return this._joinable;
    }

    public set joinable(j : boolean)
    {
        const changed = j != this._joinable;
        this._joinable = j;
        if (changed) {
            this.onChange.emit("joinable");
        }
    }

    /**
     * The API request type from the customer (FDP or OC)
     */
    public get requestType() : string
    {
        return this._requestType;
    }

    /**
     * The customer organization tag
     */
    public get organizationTag() : string
    {
        return this._organizationTag;
    }

    /**
     * The partner organization tag
     */
    public get partnerTag() : string
    {
        return this._partnerTag;
    }

    /**
     * Custom pass-through parameters
     * specified by the customers
     * and provided via API
     */
    public get passThroughParams() : any[]
    {
        return this._passThroughParams;
    }

    /**
     * The deadline specified by the customer
     * via API.
     */
    public get deadline() : Date
    {
        return this._deadline;
    }

    /**
     * The output type required by the customer
     */
    public get outputType() : string[]
    {
        return this._outputType;
    }

    /**
     * The landscape type. Usually used for LiDAR missions.
     */
    public get landscapeType() : TargetLandscapeType
    {
        return this._landscapeType;
    }

    /**
     * The landscape type. Usually used for LiDAR missions.
     */
    public set landscapeType(l : TargetLandscapeType)
    {
        const changed = l != this._landscapeType;
        this._landscapeType = l;
        if (changed) {
            this.onChange.emit("landscapeType");
        }
    }

    /**
     * The output type in a human readable format
     */
    public get outputTypeHumanReadable() : string
    {
        let text : string[] = [];
        for (let str of this._outputType) {
            if (str == "TIR" || str == "RGB") {
                text.push(str);
                continue;
            }
            const comma = str.indexOf(":");
            if (comma <= 0 || comma >= str.length - 1) {
                continue;
            }
            const sensor = Sensor.fromUniqueName(str.substring(0, comma));
            const index = parseInt(str.substring(comma + 1));
            if (isNaN(index) || index < 0 || index > sensor.lensFocals.length - 1) {
                continue;
            } 
            text.push(sensor.uniqueName + " + " + sensor.lensFocals[index] + "mm");
        }
        return text.join(", ");
    }

    /**
     * The resolution required by the customer
     */
    public get resolution() : number
    {
        return this._gsd;
    }

    /**
     * The resolution required by the customer
    */
    public set resolution(gsd : number)
    {
        const changed = gsd != this._gsd;
        this._gsd = gsd;
        if (changed) {
            this.onChange.emit("gsd");
        }
    }

    /**
     * The overfly time slot as specified by the customer via API.
     */
    public get overflySlot() : TargetOverflySlot
    {
        return this._overflySlot;
    }

    /**
     * The overfly time slot as specified by the customer via API.
     */
    public set overflySlot(s : TargetOverflySlot)
    {
        const changed = this._overflySlot != s;
        this._overflySlot = s;
        if (changed) {
            this.onChange.emit("overflySlot");
        }
    }

    /**
     * The overfly weather as specified by the customer via API.
     */
    public get overflyWeather() : TargetOverflyWeather
    {
        return this._overflyWeather;
    }

    /**
     * The overfly weather slot as specified by the customer via API.
     */
    public set overflyWeather(s : TargetOverflyWeather)
    {
        const changed = this._overflyWeather != s;
        this._overflyWeather = s;
        if (changed) {
            this.onChange.emit("overflyWeather");
        }
    }

    /**
     * A custom callback URL specified by the customer via API
     * and called by the system when the area has been processed
     * by the automatic validation process.
     */
    public get callbackUrl() : string
    {
        return this._callbackUrl;
    }

    /**
     * The API validation notes.
     * If a target is rejected by the API, here
     * it is specified why.
     */
    public get validationNotes() : string[]
    {
        return this._validationNotes;
    }

    /**
     * The creation time of this target.
     * ISO UTC.
     */
    public get creationTime() : string
    {
        return this._creationTime;
    }

    /**
     * The update time of this target.
     * ISO UTC.
     */
    public get updateTime() : string
    {
        return this._updateTime;
    }

    /**
     * An array of notes left by users
     */
    public get notes() : NoteDataInterface[]
    {
        return this._notes;
    }

    /**
     * An array of notes left by users
     */
    public set notes(n : NoteDataInterface[])
    {
        const changed = JSON.stringify(n) !== JSON.stringify(this._notes);
        this._notes = n;
        if (changed) {
            this.onChange.emit("notes");
        }
    }

    /**
     * The two letters code that identifies the country
     */
    public get countryCode() : string
    {
        return this._countryCode;
    }

    /**
     * The target status in the mission flow.
     */
    public get flowStatus() : FlowStatus
    {
        return this._flowStatus;
    }

    public set flowStatus(status : FlowStatus)
    {
        const changed = status != this._flowStatus;
        this._flowStatus = status;
        this._updateTime = (new Date()).toISOString();
        this.onChange.emit("updateTime");
        if (changed) {
            this.onChange.emit("flowStatus");
        }
    }

    /**
     * The commercial area of this target
     * in squared meters.
     */
    public get commercialArea() : number
    {
        return this._commercialArea;
    }

    /**
     * Triggered when a property changes.
     * The propery name is passed as parameter.
     */
    public readonly onChange : EventEmitter<TargetObservableProperties>;

    private _uuid : string;
    private _flowStatus : FlowStatus;
    private _commercialArea : number;
    private _countryCode : string;
    private _joinable : boolean;
    private _requestType : string;
    private _organizationTag : string;
    private _partnerTag : string;
    private _passThroughParams : any[];
    private _deadline : Date;
    private _outputType : string[];
    private _gsd : number;
    private _overflySlot : TargetOverflySlot;
    private _overflyWeather : TargetOverflyWeather;
    private _validationNotes : string[];
    private _updateTime : string;
    private _creationTime : string;
    private _callbackUrl : string;
    private _notes : NoteDataInterface[];
    private _landscapeType : TargetLandscapeType;

    /**
     * 
     * @param name The UUID of the target
     * @param _vertexes The target vertexes array
     */
    constructor(name : string, _vertexes : PositionInterface[])
    {
        super(_vertexes);
        this._uuid = name;
        this._notes = [];
        this.onChange = new EventEmitter<TargetObservableProperties>();
        this._flowStatus = FlowStatus.Todo;
        this._joinable = true;
        this._requestType = "";
        this._organizationTag = "";
        this._partnerTag = "";
        this._countryCode = "";
        this._passThroughParams = [];
        this._deadline = null;
        this._outputType = [];
        this._gsd = 0;
        this._commercialArea = 0;
        this._overflySlot = "any";
        this._overflyWeather = "any";
        this._callbackUrl = "";
        this._validationNotes = [];
        this._creationTime = "";
        this._updateTime = "";
        this._landscapeType = "worst-case";
    }

    /**
     * Set the output type required by the customer of this target
     * 
     * @param sensor The sensor to use
     * @param lensIndex The sensor lens index
     * @param gsd The GSD in cm/px
     */
    public setOutputType(sensor : Sensor, lensIndex : number, gsd : number)
    {
        if (lensIndex < 0 || lensIndex > sensor.lensFocals.length - 1) {
            throw Error("Wrong lens index");
        }
        this._outputType = [sensor.uniqueName + ":" + lensIndex];
        this._gsd = gsd;
    }

    /**
     * Convert this target o a data interface that can be saved to the DB.
     * 
     * @returns The TargetDataInterface of this target
     */
    public toDataInterface() : TargetDataInterface
    {
        let path : PositionInterface[] = [];
        for (let pos of this.path) {
            path.push({
                latitude: pos[0],
                longitude: pos[1]
            })
        }
        return {
            uuid: this.uuid,
            commercialArea: this._commercialArea,
            joinable: this.joinable,
            flowStatus: this.flowStatus.toString(),
            organizationTag : this.organizationTag,
            partnerTag: this.partnerTag,
            deadline : this.deadline instanceof Date ? this.deadline.toISOString() : "",
            outputType : this.outputType,
            gsd : this.resolution,
            landscapeType: this.landscapeType,
            countryCode: this.countryCode,
            overflySlot : this.overflySlot,
            overflyWeather : this.overflyWeather,
            validationNotes : this.validationNotes,
            creationTime : this.creationTime,
            updateTime : this.updateTime,
            notes: this.notes,
            path: path
        }
    }

    public acceptTargetVisitor(visitor: TargetVisitorInterface): void {
        visitor.visitTarget(this);
    }

    /**
     * Check if this target equals the other passed as parameter.
     * 
     * @param other The other target
     * @returns True if the other target equals this
     */
    public equals(other : TargetInterface) : boolean
    {
        if (other.uuid != this.uuid) {
            return false;
        }

        if (Position.fromInterface(other.center).distanceTo(this.center) > 100) {
            return false;
        }

        if (!other.flowStatus.equals(this.flowStatus)) {
            return false;
        }

        return true;
    }

    /**
     * Update the properties of this target using its data interface
     * 
     * @param int The target data interface
     */
    public updateFromInterface(int : TargetDataInterface) : void
    {
        if (int.uuid != this.uuid) {
            return;
        }

        if (this._joinable != int.joinable) {
            // The setter will emit the event
            this.joinable = int.joinable;
        }
        if (this.deadline instanceof Date && int.deadline.length > 0 && this.deadline.getTime() != new Date(int.deadline).getTime()) {
            this._deadline = new Date(int.deadline);
            this.onChange.emit("deadline");
        }
        if (this.outputType != int.outputType) {
            this._outputType = int.outputType;
            this.onChange.emit("outputType");
        }
        if (this.resolution != int.gsd) {
            this._gsd = int.gsd;
            this.onChange.emit("gsd");
        }
        if (this.overflySlot != int.overflySlot) {
            this._overflySlot = int.overflySlot;
            this.onChange.emit("overflySlot");
        }
        if (this.overflyWeather != int.overflyWeather) {
            this._overflyWeather = int.overflyWeather;
            this.onChange.emit("overflyWeather");
        }
        if (JSON.stringify(this.validationNotes) != JSON.stringify(int.validationNotes)) {
            this._validationNotes = int.validationNotes;
            this.onChange.emit("validationNotes");
        }
        if (this.updateTime != int.updateTime) {
            this._updateTime = int.updateTime;
            this.onChange.emit("updateTime");
        }
        if (JSON.stringify(this.notes) != JSON.stringify(int.notes)) {
            this._notes = int.notes;
            this.onChange.emit("notes");
        }
        if (this.flowStatus.toString() != int.flowStatus) {
            this._flowStatus = FlowStatus.fromString(int.flowStatus);
            this.onChange.emit("flowStatus");
        }
        if (this._landscapeType != int.landscapeType) {
            this._landscapeType = int.landscapeType;
            this.onChange.emit("landscapeType");
        }
    }

    /**
     * @brief A static method useful if used for array sorting.
     * @details Compares two targets and orders them by ascending UUID
     * 
     * @param a The first target
     * @param b The second target
     * @returns 0 (equal), 1 (a > b), -1 (a < b)
     */
    public static orderByUUID(a : Target, b : Target) : number
    {
        if (a.uuid == b.uuid) {
            return 0;
        }
        return a.uuid > b.uuid ? 1 : -1;
    }

    /**
     * @brief A static method useful if used for array sorting.
     * @details Compares two targets and orders them by ascending flow status
     * 
     * @param a The first target
     * @param b The second target
     * @returns 0 (equal), 1 (a > b), -1 (a < b)
     */
    public static orderByFlowStatus(a : Target, b : Target) : number
    {
        return a.flowStatus.compareTo(b.flowStatus);
    }

    /**
     * Create a new target starting from its data interface.
     * 
     * @param int The data interface
     */
    public static fromDataInterface(int : TargetDataInterface) : Target
    {
        const target = new Target(int.uuid, int.path);
        target.joinable = int.joinable;
        if (!!int.flowStatus) {
            target.flowStatus = FlowStatus.fromString(int.flowStatus);
        }
        if (!!int.landscapeType) {
            target._landscapeType = int.landscapeType;
        }
        if (!!int.partnerTag) {
            target._partnerTag = int.partnerTag;
        }
        if (!!int.organizationTag) {
            target._organizationTag = int.organizationTag;
        }
        if (!!int.deadline) {
            target._deadline = new Date(int.deadline);
        }
        if (!!int.outputType) {
            target._outputType = int.outputType;
        }
        if (!!int.gsd) {
            target._gsd = int.gsd;
        }
        if (!!int.overflySlot) {
            target._overflySlot = int.overflySlot;
        }
        if (!!int.overflyWeather) {
            target._overflyWeather = int.overflyWeather;
        }
        if (!!int.validationNotes) {
            target._validationNotes = int.validationNotes;
        }
        if (!!int.creationTime) {
            target._creationTime = int.creationTime;
        }
        if (!!int.commercialArea) {
            target._commercialArea = int.commercialArea;
        }
        if (!!int.countryCode) {
            target._countryCode = int.countryCode;
        }
        if (!!int.notes) {
            target._notes = int.notes;
        }
        if (!!int.landscapeType) {
            target._landscapeType = int.landscapeType;
        }
        if (!!int.updateTime) {
            target._updateTime = int.updateTime;
        }
        else {
            // Ensure the time is the right one
            // as it's updated when the flowStatus
            // param is written
            target._updateTime = "";
        }
        return target;
    }
}
