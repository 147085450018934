import { WaypointDataInterface } from "src/app/interfaces/data/waypoint-data-interface";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";
import { PositionInterface } from "src/app/interfaces/position-interface";
import { TargetInterface } from "src/app/interfaces/target-interface";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { TargetServiceInterface } from "src/app/services/target.service";
import { Waypoint } from "./waypoint";
import { WaypointType } from "./waypoint-type";

export class TargetWaypoint extends Waypoint {

    public get name(): string {
        return this.target.uuid;
    }

    public get position(): PositionInterface {
        return this.target.center;
    }

    public get type(): WaypointType {
        return "target";
    }

    public get boundarybox() : PolygonInterface
    {
        return this.target.getMinimumBoundingBox();
    }

    public get fixed() : boolean
    {
        return true;
    }

    public altitudeM : number;

    public delayMs: number;

    constructor(public target : TargetInterface)
    {
        super();
        this.altitudeM = 0;
        this.delayMs = 0;
    }

    public acceptWaypointVisitor(visitor: WaypointVisitorInterface): void {
        visitor.visitTargetWaypoint(this);
    }

    public static async fromTargetDataInterface(int : WaypointDataInterface, targetService : TargetServiceInterface) : Promise<Waypoint>
    {
        if (int.type != "target") {
            throw "Invalid interface";
        }

        const tgt = await targetService.get(int.payload);
        if (!tgt) {
            return null;
        }
        const wpt = new TargetWaypoint(tgt);
        wpt.delayMs = int.delayMs;
        return wpt;
    }

    public toDataInterface() : WaypointDataInterface
    {
        const int = super.toGenericDataInterface(this.target.uuid);
        int.delayMs = this.delayMs;
        return int;
    }
}
