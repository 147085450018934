import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private _translateService : TranslateService,
        private _toastController : ToastController,
        private _swUpdate : SwUpdate,
        private _storageService : StorageService,
        private _router : Router
    ) {
        // Set the translate language
        this._translateService.setDefaultLang('en');
        if (this._translateService.getBrowserCultureLang().substr(0, 2).toLowerCase() == "it") {
            this._translateService.use("it");
        }
    }

    async ngOnInit()
    {
        this._translateService.get([
            "update.message",
            "update.reload"
        ]).subscribe(l10n => {
            this._swUpdate.versionUpdates.subscribe(async (e) => {
                if (e.type != "VERSION_READY") {
                    return;
                }
                const toast = await this._toastController.create({
                    message: l10n["update.message"],
                    position: 'bottom',
                    buttons: [{ role: 'cancel', text: l10n["update.reload"] }]
                });
                await toast.present();
                toast
                    .onDidDismiss()
                    .then(() => this._swUpdate.activateUpdate())
                    .then(() => window.location.reload());
            });
            this._swUpdate.checkForUpdate();
            // Check for updates every 15 minutes
            setInterval(() => {
                this._swUpdate.checkForUpdate();
            }, 15 * 60 * 1000);
        });
        
        // If the storage goes offline, enter maintenance mode
        this._storageService.isOnline.subscribe(online => {
            if (!online) {
                this._router.navigate(["maintenance"]);
            }
        });
    }
}
