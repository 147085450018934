import { EventEmitter } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BoundaryInterface } from "src/app/interfaces/boundary-interface";
import { AirportDataInterface } from "src/app/interfaces/data/airport-data-interface";
import { RouteDataInterface } from "src/app/interfaces/data/route-data-interface";
import { WaypointDataInterface } from "src/app/interfaces/data/waypoint-data-interface";
import { WaypointAcceptorInterface } from "src/app/interfaces/waypoint-acceptor-interface";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { AirportService } from "src/app/services/airport.service";
import { TargetService } from "src/app/services/target.service";
import { Position } from "../position";
import { Segment } from "../segment";
import { Target } from "../target";
import { TargetsGroup } from "../targets-group";
import { AirportWaypoint } from "./airport-waypoint";
import { TargetWaypoint } from "./target-waypoint";
import { TurnWaypoint } from "./turn-waypoint";
import { Waypoint } from "./waypoint";
import { WaypointType } from "./waypoint-type";

/**
 * Monitor a set of waypoints and see if they change position
 */
class WaypointsPositionMonitor implements WaypointVisitorInterface
{
    public onWaypointPositionChange : EventEmitter<void>;

    constructor()
    {
        this.onWaypointPositionChange = new EventEmitter<void>();
    }

    public monitorWaypoints(wps : Waypoint[])
    {
        for (let wp of wps) {
            wp.acceptWaypointVisitor(this);
        }
    }

    public visitTargetWaypoint(wp: TargetWaypoint): void {}

    public visitTurnWaypoint(wp: TurnWaypoint): void {
        wp.onPositionChanged.subscribe(() => {
            this.onWaypointPositionChange.emit();
        });
    }

    public visitAirportWaypoint(wp: AirportWaypoint): void {}
}

export class Route implements WaypointAcceptorInterface {

    /**
     * Generic route changed event that is fired whenever 
     * something changes about targets or airports
     */
    public get onRouteChanged() : EventEmitter<void>
    {
        return this._onRouteChanged;
    }

    /**
     * Generic waypoint changed event that is fired whenever 
     * something changes about waypoints
     */
    public get onWaypointChanged() : EventEmitter<void>
    {
        return this._onWaypointChanged;
    }

    public get onWaypointAdded() : EventEmitter<Waypoint>
    {
        return this._onWaypointAdded;
    }

    public get onWaypointRemoved() : EventEmitter<Waypoint>
    {
        return this._onWaypointRemoved;
    }

    /**
     * Event fired when a waypoint is added or removed
     */
    public get onWaypointCountChanged() : EventEmitter<void>
    {
        return this._onWaypointCountChanged;
    }

    /**
     * Event fired when a waypoint is reordered
     */
    public get onWaypointOrderChanged() : EventEmitter<void>
    {
        return this._onWaypointOrderChanged;
    }

    public get boundaries() : BoundaryInterface
    {
        let targets : Target[] = [];
        this.waypoints.forEach(t => targets.push(new Target(t.name, [t.position])));
        if (this.departure) {
            targets.push(new Target("dep", [this.departure.position]));
        }
        if (this.destination) {
            targets.push(new Target("dest", [this.destination.position]));
        }
        if (this.alternate) {
            targets.push(new Target("alt", [this.alternate.position]));
        }
        let g = new TargetsGroup(targets);
        return g.boundaries;
    }

    public get departure() : AirportDataInterface
    {
        return this._departure;
    }

    public get destination() : AirportDataInterface
    {
        return this._destination;
    }

    public get alternate() : AirportDataInterface
    {
        return this._alternate;
    }

    private _onWaypointCountChanged : EventEmitter<void>;
    private _onWaypointOrderChanged : EventEmitter<void>;
    private _onRouteChanged : EventEmitter<void>;
    private _onWaypointChanged : EventEmitter<void>;
    private _onWaypointAdded : EventEmitter<Waypoint>;
    private _onWaypointRemoved : EventEmitter<Waypoint>;
    private _waypointPositionMonitor : WaypointsPositionMonitor;

    constructor(
        private _departure : AirportDataInterface = null,
        private _destination : AirportDataInterface = null,
        private _alternate : AirportDataInterface = null,
        public readonly waypoints : Waypoint[] = []
    )
    {
        this._onWaypointCountChanged = new EventEmitter<void>();
        this._onWaypointOrderChanged = new EventEmitter<void>();
        this._onWaypointChanged = new EventEmitter<void>();
        this._onRouteChanged = new EventEmitter<void>();
        this._onWaypointAdded = new EventEmitter<Waypoint>();
        this._onWaypointRemoved = new EventEmitter<Waypoint>();
        this._waypointPositionMonitor = new WaypointsPositionMonitor();
        this._waypointPositionMonitor.monitorWaypoints(waypoints);

        this._onWaypointCountChanged.subscribe(() => { this._onWaypointChanged.emit(); });
        this._onWaypointOrderChanged.subscribe(() => { this._onWaypointChanged.emit(); });
        this._onWaypointChanged.subscribe(() => { this._onRouteChanged.emit(); });
        this._waypointPositionMonitor.onWaypointPositionChange.subscribe(() => {
            this._onWaypointChanged.emit();
        });
    }

    public setDeparture(airport : AirportDataInterface)
    {
        if (!!airport && this._departure?.icao != airport.icao) {
            this._departure = airport;
            this._onRouteChanged.emit();
        }
    }

    public setDestination(airport : AirportDataInterface)
    {
        if (!!airport && this._destination?.icao != airport.icao) {
            this._destination = airport;
            this._onRouteChanged.emit();
        }
    }

    public setAlternate(airport : AirportDataInterface)
    {
        if (!!airport && this._alternate?.icao != airport.icao) {
            this._alternate = airport;
            this._onRouteChanged.emit();
        }
    }

    /**
     * Insert a waypoint in the closest leg of the route.
     * 
     * @param waypoint The waypoint
     */
    public insertWaypoint(waypoint : Waypoint) : void
    {
        if (!!this.waypoints.find(wp => wp.equals(waypoint))) {
            return;
        }

        if (this.waypoints.length == 0) {
            this.appendWaypoint(waypoint);
            return;
        }

        let index = -1;
        let dist = Number.MAX_SAFE_INTEGER;
        const waypoints = this.waypoints;

        // Beginning of the route
        if (waypoints.length > 0) {
            dist = Position.fromInterface(waypoints[0].position).distanceTo(waypoint.position);
            index = 0;
        }

        // In the middle of the route
        for (let i = 0; i < waypoints.length - 1; i++) {
            const s = new Segment(waypoints[i].position, waypoints[i + 1].position);
            const d = s.distanceTo(waypoint.position);
            if (d < dist) {
                dist = d;
                index = i + 1;
            }
        }

        // End of the route
        if (waypoints.length > 0) {
            const d = Position.fromInterface(waypoints[waypoints.length - 1].position).distanceTo(waypoint.position);
            if (d <= dist) {
                index = waypoints.length;
            }
        }

        if (index > -1) {
            this.waypoints.splice(index, 0, waypoint);
            this._onWaypointCountChanged.emit();
            this._onWaypointAdded.emit(waypoint);
            this._waypointPositionMonitor.monitorWaypoints([waypoint]);
            return;
        }

        this.appendWaypoint(waypoint);
    }

    /**
     * Append a waypoint to the end of the route
     * 
     * @param waypoint The waypoint
     */
    public appendWaypoint(waypoint : Waypoint) : void
    {
        // Do not append the same waypoint twice
        if (this.waypoints.length > 0 && this.waypoints[this.waypoints.length - 1].equals(waypoint)) {
            return;
        }
        
        this.waypoints.splice(this.waypoints.length, 0, waypoint);
        this._onWaypointCountChanged.emit();
        this._onWaypointAdded.emit(waypoint);
        this._waypointPositionMonitor.monitorWaypoints([waypoint]);
    }

    public removeWaypoint(waypoint : Waypoint) : void
    {
        let index = this.waypoints.findIndex(t => t.equals(waypoint));
        if (index > -1) {
            this.waypoints.splice(index, 1);
            this._onWaypointCountChanged.emit();
            this._onWaypointRemoved.emit(waypoint);
        }
    }

    public reorderWaypoint(from : number, to : number)
    {
        let t = this.waypoints.splice(from, 1);
        this.waypoints.splice(to, 0, t[0]);
        this._onWaypointOrderChanged.emit();
    }

    public acceptWaypointVisitor(visitor : WaypointVisitorInterface)
    {
        if (this.departure) {
            visitor.visitAirportWaypoint(new AirportWaypoint(this.departure));
        }
        for (let wp of this.waypoints) {
            wp.acceptWaypointVisitor(visitor);
        }
        if (this.destination) {
            visitor.visitAirportWaypoint(new AirportWaypoint(this.destination));
        }
        if (this.alternate) {
            visitor.visitAirportWaypoint(new AirportWaypoint(this.alternate));
        }
    }

    public toDataInterface() : RouteDataInterface
    {
        const waypoints : WaypointDataInterface[] = [];

        for (let waypoint of this.waypoints) {
            waypoints.push(waypoint.toDataInterface());
        }

        return {
            alternate: this.alternate?.icao ?? "",
            departure: this.departure?.icao ?? "",
            destination: this.destination?.icao ?? "",
            waypoints: waypoints
        };
    }

    /**
     * Return a waypoint array that represent the full route including
     * the departure and destination airports
     * 
     * @param includeAlternate True to include the alternate airport as last waypoint
     */
    public toWaypointsArray(includeAlternate : boolean) : Waypoint[]
    {
        let waypoints : Waypoint[] = [];
        if (this.departure) {
            waypoints.push(new AirportWaypoint(this.departure));
        }

        waypoints = waypoints.concat(this.waypoints);

        if (this.destination) {
            waypoints.push(new AirportWaypoint(this.destination));
        }

        if (includeAlternate && this.alternate) {
            waypoints.push(new AirportWaypoint(this.alternate));
        }

        return waypoints;
    }

    public static async fromDataInterface(int : RouteDataInterface, airportService : AirportService, targetService : TargetService) : Promise<Route>
    {
        const dep = await airportService.get(int.departure);
        const dest = await airportService.get(int.destination);
        const alt = await airportService.get(int.alternate);
        const waypoints : Waypoint[] = [];
        
        for (let wpInt of int.waypoints ?? []) {
            let wp : Waypoint;
            switch (wpInt.type) {
                case "target":
                    wp = await TargetWaypoint.fromTargetDataInterface(wpInt, targetService);
                    break;
                case "turnpoint":
                    wp = TurnWaypoint.fromTurnpointDataInterface(wpInt);
                    break;
                case "airport":
                    wp = await AirportWaypoint.fromAirportDataInterface(wpInt, airportService);
                    break;
                default:
                    throw "Unknown waypoint type " + wpInt.type;
            }
            if (!wp) {
                continue;
            }
            waypoints.push(wp);
        }

        return new Route(dep, dest, alt, waypoints);
    }
}
