import { AircraftDataInterface } from "../interfaces/data/aircraft-data-interface";
import { MassAndBalanceDataInterface } from "../interfaces/data/mass-and-balance-data-interface";
import { AircraftPerformance } from "./aircraft-performance";
import { Helpers } from "./helpers";
import { FuelType } from "./mass-and-balance/fuel-type";

export class Aircraft {

    public readonly performance : AircraftPerformance;

    /**
     * The aircraft registration markings
     */
    public get registration() : string
    {
        return this._data.registration;
    }

    public get name() : string
    {
        return this._data.name;
    }

    public get fuelType() : FuelType
    {
        return this._data.fuelType;
    }

    /** The minimum inversion time in seconds between legs */
    public get minimumInversionTime() : number
    {
        return this._data.minimumInversionTime;
    }

    /**
     * The preferred cruise level in meters
     */
    public get preferredCruiseLevel() : number
    {
        return this._data.preferredCruiseLevel;
    }

    public get massAndBalanceData() : MassAndBalanceDataInterface
    {
        return this._data.massAndBalance;
    }

    public get glideRatio() : number
    {
        return this._data.glideRatio;
    }

    constructor(private _data : AircraftDataInterface) {
        this.performance = new AircraftPerformance(this._data);
    }

    public equals(other : Aircraft) : boolean
    {
        return this.registration == other.registration;
    }

    public static get Generic() : Aircraft
    {
        return new Aircraft(this.GenericInterface);
    }

    public static get GenericInterface() : AircraftDataInterface
    {
        return {
            registration: "I-DSKY",
            name: "Piper PA-32 Cherokee Lance",
            maxFuelKg: 356 * 0.72,
            taxiFuelKg: 10 * 0.72,
            fuelType: "avgas",
            glideRatio: 9,
            minimumInversionTime: 90,

            // -----------------
            // Mass and balance
            // -----------------

            massAndBalance: {
                armUnit: "in.",
                basicEmptyMass: 960.7,
                basicEmptyArm: 80.8,
                envelope: [
                    { x: 76, y: 1088.6 },
                    { x: 80, y: 1315.4 },
                    { x: 91, y: 1632.9 },
                    { x: 95, y: 1632.9 }
                ],
                stations: [
                    {
                        name: "massAndBalance.stations.baggageFront",
                        type: "generic",
                        arm: 42,
                        // [max baggage]
                        payloads: [0],
                        // [default baggage]
                        defaults: [0]
                    },
                    {
                        name: "massAndBalance.stations.pilots",
                        type: "occupants",
                        arm: 85.5,
                        // [min occupants, max occupants]
                        payloads: [1, 2],
                        // [default occupants]
                        defaults: [1]
                    },
                    {
                        name: "massAndBalance.stations.seatsCenter",
                        type: "occupants",
                        arm: 118.1,
                        // [min occupants, max occupants]
                        payloads: [0, 2],
                        // [default occupants]
                        defaults: [0]
                    },
                    {
                        name: "massAndBalance.stations.seatsRear",
                        type: "occupants",
                        arm: 155.7,
                        // [min occupants, max occupants]
                        payloads: [0, 2],
                        // [default occupants]
                        defaults: [0]
                    },
                    {
                        name: "massAndBalance.stations.baggageRear",
                        type: "generic",
                        arm: 178.7,
                        // [max load]
                        payloads: [0],
                        // [default load]
                        defaults: [0]
                    },

                    {
                        name: "massAndBalance.stations.taskSpec",
                        type: "generic",
                        arm: 118.1,
                        // [max load]
                        payloads: [0],
                        // [default load]
                        defaults: [10]
                    },

                    {
                        name: "massAndBalance.stations.smartbay",
                        type: "smartbay",
                        arm: 143.7, // empty pylon arm
                        // [extra empty pylon mass, front trolley arm, center trolley arm, rear trolley arm, BIU arm]
                        payloads: [0, 144.93, 145.13, 145.33, 145.43],
                        defaults: []
                    },
                    
                    {
                        name: "massAndBalance.stations.fuel",
                        type: "fuel",
                        arm: 93.6,
                        // [max fuel kg]
                        payloads: [355.7 * 0.72],
                        // [default fuel kg]
                        defaults: [355.7 * 0.72]
                    }
                ]
            },

            // -----------------
            // Climb performance
            // -----------------
            climbPerformance: [{
                level: 0,
                fuelFlow: 65 * 0.72,
                speed: Helpers.ktsToMs(105),
                verticalSpeed: Helpers.fpmToMs(1000)
            }, {
                level: Helpers.feetToMeters(10000),
                fuelFlow: 55 * 0.72,
                speed: Helpers.ktsToMs(105),
                verticalSpeed: Helpers.fpmToMs(400)
            }],

            // ------------------
            // Cruise performance
            // ------------------
            cruisePerformance: [{
                level: 0,
                fuelFlow: 58 * 0.72,
                speed: Helpers.ktsToMs(140),
                verticalSpeed: 0,
            },{
                level: Helpers.feetToMeters(10000),
                fuelFlow: 58 * 0.72,
                speed: Helpers.ktsToMs(160),
                verticalSpeed: 0,
            }],

            // -------------------
            // Descent performance
            // -------------------
            descentPerformance: [{
                level: 0,
                fuelFlow: 20 * 0.72,
                speed: Helpers.ktsToMs(140),
                verticalSpeed: -800,
            }],

            // --------------------
            // Airwork performance
            // --------------------
            airworkPerformance: [{
                level: 0,
                fuelFlow: 45 * 0.72,
                speed: Helpers.ktsToMs(82),
                verticalSpeed: 0,
            }],
            preferredCruiseLevel: Helpers.feetToMeters(10000)
        };
    }
}