import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlagDataInterface, FlagId } from 'src/app/interfaces/data/flag-data-interface';
import { MissionDataInterface } from 'src/app/interfaces/data/mission-data-interface';
import { PilotDataInterface } from 'src/app/interfaces/data/pilot-data-interface';
import { StorageServiceInterface } from 'src/app/interfaces/storage-service-interface';
import { TargetDataInterface } from 'src/app/interfaces/data/target-data-interface';
import { environment, BackendType } from 'src/environments/environment';
import { AppwriteStorageService } from './appwrite-storage.service';
import { LocalStorageService } from './local-storage.service';
import { AirportDataInterface } from 'src/app/interfaces/data/airport-data-interface';
import { AircraftDataInterface } from 'src/app/interfaces/data/aircraft-data-interface';
import { VolatileStorageService } from './volatile-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements StorageServiceInterface {

    private _storage : StorageServiceInterface;

    public get isOnline() : Observable<boolean>
    {
        return this._storage.isOnline;
    }

    constructor(
        localStorageService : LocalStorageService,
        volatileStorageService : VolatileStorageService,
        appwriteStorgeService : AppwriteStorageService
    ) {
        switch (environment.backendType) {
            case BackendType.localstorage: this._storage = localStorageService; break;
            case BackendType.volatile:
                this._storage = volatileStorageService;
                volatileStorageService.populateWithFakeData();
                break;
            default:
                this._storage = appwriteStorgeService;
                appwriteStorgeService.connect();
                break;
        }
    }

    public getAllAircrafts(): Observable<AircraftDataInterface[]>
    {
        return this._storage.getAllAircrafts();
    }

    public getFlag(key : FlagId) : Observable<FlagDataInterface>
    {
        return this._storage.getFlag(key);
    }

    public setFlag(key : FlagId, value : boolean | number | string) : Promise<void>
    {
        return this._storage.setFlag(key, value);
    }

    public getAllTargets(): Observable<TargetDataInterface[]> {
        return this._storage.getAllTargets();
    }

    public getTarget(id: string): Promise<TargetDataInterface> {
        return this._storage.getTarget(id);
    }

    public saveTarget(target: TargetDataInterface): Promise<void> {
        return this._storage.saveTarget(target);
    }

    public deleteTarget(target: TargetDataInterface): Promise<void> {
        return this._storage.deleteTarget(target);
    }
    
    public getAllMissions(): Observable<MissionDataInterface[]> {
        return this._storage.getAllMissions();
    }
    
    public async saveMission(mission: MissionDataInterface): Promise<string> {
        return this._storage.saveMission(mission);
    }
    
    public async getMission(id: string): Promise<MissionDataInterface> {
        return this._storage.getMission(id);
    }
    
    public async deleteMission(id: string): Promise<void> {
        return this._storage.deleteMission(id);
    }
    
    public getAllAirports(): Observable<AirportDataInterface[]> {
        return this._storage.getAllAirports();
    }
    
    public async getAirport(icao: string): Promise<AirportDataInterface> {
        return this._storage.getAirport(icao);
    }
    
    public async saveAirport(airport: AirportDataInterface): Promise<void> {
        return this._storage.saveAirport(airport);
    }
    
    public async deleteAirport(airport: AirportDataInterface): Promise<void> {
        return this._storage.deleteAirport(airport);
    }
    
    public getAllPilots(): Observable<PilotDataInterface[]> {
        return this._storage.getAllPilots();
    }
    
    public async getPilot(email: string): Promise<PilotDataInterface> {
        return this._storage.getPilot(email);
    }
    
    public async savePilot(pilot: PilotDataInterface): Promise<void> {
        return this._storage.savePilot(pilot);
    }
    
    public async deletePilot(pilot: PilotDataInterface): Promise<void> {
        return this._storage.deletePilot(pilot);
    }
}
