import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { take } from "rxjs";
import { AuthService } from "./auth.service";
 
 @Injectable()
 export class AuthGuard implements CanActivate {
 
    constructor(private _authService: AuthService, private _router: Router) {}
 
    public canActivate(): Promise<boolean>
    {
        return new Promise<boolean>(resolve => {
            this._authService.signedIn.pipe(take(1)).subscribe(signed => {
                resolve(signed);
                if (!signed) {
                    this._router.navigate(["auth", "login"]);
                }
            });
        });
    }
 }