import { SensorDataInterface } from "src/app/interfaces/data/sensor-data-interface";
import { SensorInterface, SensorType } from "../../interfaces/sensor-interface";
import { SignalEdgeMode } from "../signal-edge-mode";
import { Helpers } from "../helpers";
import { TargetLandscapeType } from "src/app/interfaces/target-interface";
import { GroundElevationResultInterface } from "src/app/interfaces/ground-elevation-service-interface";
import { SensorStrategyResultsInterface } from "src/app/interfaces/sensor-strategy-results-interface";
import { Aircraft } from "../aircraft";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";

export class Sensor implements SensorInterface {

    public readonly manufacturerSensorId: string;
    public readonly name : string;
    public readonly manufacturer : string;
    public readonly type : SensorType;
    public readonly ccdWidthCm: number;
    public readonly ccdHeightCm: number;
    public readonly ccdResolution: number;
    public readonly ccdWidthPx: number;
    public readonly ccdHeightPx: number;
    public readonly pixelSize: number;
    public readonly frameSizeMegaByte: number;
    public readonly maxBufferMegaByte: number;
    public readonly minShutterTimeMs: number;
    public readonly lensFocals: number[];
    public readonly weightKg: number;
    public readonly TTCMode: SignalEdgeMode;
    public readonly strobeMode: SignalEdgeMode;
    public readonly offsetTimeMs: number;
    public readonly commandGroups: number[];
    public roll: number;
    public readonly pitchDeg: number;
    public readonly isValid: boolean;

    public get uniqueName() : string
    {
        if (!!this.manufacturer) {
            return this.manufacturer + " " + this.name;
        }
        return this.name;
    }

    public get commandGroupIndex() : number
    {
        return this._commandGroupIndex;
    }

    public set commandGroupIndex(index : number)
    {
        if (index >= 0 && index < this.commandGroups.length) {
            this._commandGroupIndex = index;
        }
        else if (this.commandGroups.length) {
            console.warn("Wrong command group index " + index + " for sensor " + this.uniqueName);
        }
    }

    public get lensIndex() : number
    {
        return this._lensIndex;
    }

    /**
     * Get an ordered array of available focals and index
     */
    public get orderedLensFocals() : { "focal": number, "index": number}[]
    {
        // Sort the focals in ascending order
        // and add their array index
        const focals = [];
        for (let i = 0; i < this.lensFocals?.length; i++) {
            focals.push({ "focal": this.lensFocals[i], "index": i });
        }
        focals.sort((a, b) => a.focal - b.focal);
        return focals;
    }

    public set lensIndex(index : number)
    {
        if (index >= 0 && index < this.lensFocals?.length) {
            this._lensIndex = index;
        }
        else if (this.lensFocals?.length) {
            console.warn("Wrong lens focal index " + index + " for sensor " + this.uniqueName);
        }
    }

    private _commandGroupIndex : number;
    private _lensIndex : number;
    private _fovOverride : number;
    private _lidarSpeed : number;

    constructor(private _config : SensorInterface, lensIndex : number, commandGroupIndex : number)
    {
        this.manufacturerSensorId = _config.manufacturerSensorId;
        this.name = _config.name;
        this.manufacturer = _config.manufacturer;
        this.type = _config.type;
        this.ccdWidthCm = _config.ccdWidthCm;
        this.ccdHeightCm = _config.ccdHeightCm;
        this.ccdResolution = _config.ccdResolution;
        this.ccdWidthPx = _config.ccdWidthPx;
        this.ccdHeightPx = _config.ccdHeightPx;
        this.pixelSize = _config.pixelSize;
        this.frameSizeMegaByte = _config.frameSizeMegaByte;
        this.maxBufferMegaByte = _config.maxBufferMegaByte;
        this.minShutterTimeMs = _config.minShutterTimeMs;
        this.lensFocals = _config.lensFocals;
        this.weightKg = _config.weightKg;
        this.TTCMode = _config.TTCMode;
        this.strobeMode = _config.strobeMode;
        this.offsetTimeMs = _config.offsetTimeMs;
        this.commandGroups = _config.commandGroups;
        this.roll = _config.roll;
        this.pitchDeg = _config.pitchDeg;
        this.isValid = _config.isValid;

        this._lensIndex = lensIndex;
        this._commandGroupIndex = commandGroupIndex;
        this._lidarSpeed = 42; // ms;
        this._fovOverride = _config.fovOverride ?? -1;
    }

    public clone() : Sensor
    {
        return new Sensor(this._config, this._lensIndex, this._commandGroupIndex);
    }

    /**
     * Get the optimal acquisition height of this sensor for the given resolution, sidelap, target min/max and landscape type
     * 
     * @param resolution 
     * @param sidelap 
     * @param targetMinMax 
     * @param landscapeType 
     * @param lidarFreqHz
     * @returns 
     */
    public getOptimalAcquisitionHeight(resolution : number, landscapeType : TargetLandscapeType, lidarFreqHz : number = 100000) : number
    {
        if (this.type == "lidar") {
            return this.getLidarHeight(landscapeType, lidarFreqHz);
        }

        // Optical sensor
        return this.lensFocals[this._lensIndex] / (this.ccdWidthCm / this.ccdWidthPx) * resolution / 100;
    }

    /**
     * Get the optimal distance between legs in meters for this sensor
     * 
     * @param altitudeM The altitude of the flight in meters
     * @param terrainMinMax The ground elevation data
     * @param sidelap The sidelap percentage
     * @param resolution The resolution (GSD or PD)
     * @param lidarFreqHz The lidar frequency if the sensor is a lidar
     * @param secondarySensor The secondary sensor if any. A secondary sensor could limit the distance between legs to ensure coverage
     */
    public getOptimalDistanceBetweenLegs(altitudeM : number, terrainMinMax : GroundElevationResultInterface, sidelap : number, resolution : number, lidarFreqHz : number = 100000, secondarySensor : Sensor = null) : number
    {
        const swathHighestPoint = this.getFootprintHorizontalSpan(altitudeM - terrainMinMax.max);
        const swathLowestPoint = this.getFootprintHorizontalSpan(altitudeM - terrainMinMax.min);

        // LiDAR
        if (this.type == "lidar") {
            const pdavg = (lidarFreqHz * 0.833) / (this._lidarSpeed * swathLowestPoint);
            //const sidelap = Math.max((resolution / pdavg - 1) / 2, 0.3);
            const sidelap = (resolution / pdavg - 1) / 2;
            // Dist required to guarantee the PD at the lowest point
            const distA = swathLowestPoint * (1 - sidelap);
            // Dist required to guarantee the 0.3 sidelap at the highest point
            const distB = swathHighestPoint * 0.7;
            if (!!secondarySensor && secondarySensor.type == "rgbcam") {
                const distC = secondarySensor.getOptimalDistanceBetweenLegs(altitudeM, terrainMinMax, 0.25, 0);
                return Math.min(distA, distB, distC);
            }
            return Math.min(distA, distB);
        }

        // Optical
        return swathHighestPoint * (1 - sidelap);
    }

    /**
     * Get the footprint horizontal size in meters of the sensor at a given height
     * 
     * @param heightM The height of the flight in meters
     * @returns The width of the sensor footprint in meters
     */
    public getFootprintHorizontalSpan(heightM : number) : number
    {
        // Manual FOV override
        if (this.type == "lidar") {
            return 2 * Math.tan(this._fovOverride * Math.PI / 360) * heightM;
        }
        if (!this.ccdHeightCm || !this.ccdWidthCm) {
            return 0;
        }
        // Default calculation using the CCD size and the lens focal
        return this.ccdWidthCm / this.lensFocals[this._lensIndex] * heightM;
    }

    /**
     * Get the footprint vertical size in meters of the sensor at a given height
     * 
     * @param heightM The height of the flight in meters 
     * @returns The height of the sensor footprint in meters
     */
    public getFootprintVerticalSpan(heightM : number) : number
    {
        if (!this.ccdHeightCm || !this.ccdWidthCm) {
            return 0;
        }
        return this.ccdHeightCm / this.lensFocals[this._lensIndex] * heightM;
    }

    /**
     * Populate the given data with this sensor-related data
     * 
     * @param data
     * @returns 
     */
    public populatePhotogrammetrySensorData(
        data : SensorStrategyResultsInterface,
        aircraft : Aircraft,
        terrainElevation : GroundElevationResultInterface,
        xStep : number,
        altitudeM : number,
        sidelap : number,
        overlap : number,
        activeLegsCount: number,
        bufferedBox : PolygonInterface,
        lidarFreqHz : number
    ) : SensorStrategyResultsInterface
    {
        const minSeparation = altitudeM - terrainElevation.max;
        const maxSeparation = altitudeM - terrainElevation.min;

        // Lidar
        if (this.type == "lidar") {
            const swathHighestPoint = this.getFootprintHorizontalSpan(minSeparation);
            const swathLowestPoint = this.getFootprintHorizontalSpan(maxSeparation);
            let legsDist = 0;
            // Use the largest distance available
            data.xStep.forEach(d => legsDist = Math.max(legsDist, d));
            const sidelapHighestPoint = 1 - legsDist / swathHighestPoint;
            const sidelapLowestPoint = 1 - legsDist / swathLowestPoint;

            data.resolutionUnit = "pt/m²";
            data.lidarPrf = lidarFreqHz;
            data.resolutionHighestPoint = this.getLidarEffResolution(lidarFreqHz, minSeparation, sidelapHighestPoint);
            data.resolutionLowestPoint = this.getLidarEffResolution(lidarFreqHz, maxSeparation, sidelapLowestPoint);
            data.guaranteedSidelap = sidelapHighestPoint;

            return data;
        }
        
        // Optical
        const longFOV = 2 * Helpers.radsToDegs(Math.atan(this.ccdHeightCm/ (2 * this.lensFocals[this._lensIndex])));
        const latFOV = 2 * Helpers.radsToDegs(Math.atan(this.ccdWidthCm / (2 * this.lensFocals[this._lensIndex])));
        const latBaseAngle = Helpers.degsToRads(180 - latFOV) / 2;
        const lonBaseAngle = Helpers.degsToRads(180 - longFOV) / 2;
        const yStep = (2 * (1 - overlap) * minSeparation) / Math.tan(lonBaseAngle);
        const shotsPerLeg = Math.ceil(bufferedBox.mainSide.length / yStep) + 1;
        const shotTime = yStep / aircraft.performance.getAirworkSpeed(altitudeM);
        const totalShots = activeLegsCount * shotsPerLeg;
        const totalShotsSize = totalShots * this.frameSizeMegaByte;
        const gsdHighestPoint = this.getFootprintHorizontalSpan(minSeparation) * 100 / this.ccdWidthPx;
        const gsdLowestPoint = this.getFootprintHorizontalSpan(maxSeparation) * 100 / this.ccdWidthPx;
        const guaranteedSidelap = -(xStep * Math.tan(latBaseAngle)) / (2 * minSeparation) + 1;
        const guaranteedOverlap = -(yStep * Math.tan(lonBaseAngle)) / (2 * minSeparation) + 1;

        data.resolutionUnit = "cm/px";
        data.guaranteedSidelap = guaranteedSidelap;
        data.guaranteedOverlap = guaranteedOverlap;
        data.resolutionHighestPoint = gsdHighestPoint;
        data.resolutionLowestPoint = gsdLowestPoint;
        data.shotTime = shotTime;
        data.shotsPerLeg = shotsPerLeg;
        data.totalShots = totalShots;
        data.totalShotsSize = totalShotsSize;
        data.yStep = yStep;
        return data;
    }

    public equals(other : Sensor) : boolean
    {
        if (other.type != this.type) {
            return false;
        }
        if (other.manufacturerSensorId != this.manufacturerSensorId) {
            return false;
        }
        if (other.name != this.name) {
            return false;
        }
        if (other.manufacturer != this.manufacturer) {
            return false;
        }
        if (other.commandGroups.length != this.commandGroups.length) {
            return false;
        }
        if (other.roll != this.roll) {
            return false;
        }
        if (other.pitchDeg != this.pitchDeg) {
            return false;
        }
        if (other._lensIndex != this._lensIndex) {
            return false;
        }
        if (other._commandGroupIndex != this._commandGroupIndex) {
            return false;
        }
        if (other._fovOverride != this._fovOverride) {
            return false;
        }
        return true;
    }

    /** Get the data interface of this sensor. */
    public toDataInterface() : SensorDataInterface
    {
        return {
            id: this.uniqueName,
            commandGroupIndex: this._commandGroupIndex,
            lensIndex: this._lensIndex,
            roll: this.roll
        };
    }

    public static fromDataInterface(int : SensorDataInterface) : Sensor
    {
        let sensor = Sensor.fromUniqueName(int.id);
        // A null sensor does not have data to load
        if (!sensor.isValid) {
            return sensor;
        }
        if (int.hasOwnProperty("commandGroupIndex")) {
            sensor.commandGroupIndex = int.commandGroupIndex;
        }
        if (int.hasOwnProperty("roll")) {
            sensor.roll = int.roll;
        }
        if (int.hasOwnProperty("lensIndex")) {
            sensor.lensIndex = int.lensIndex;
        }
        return sensor;
    }

    /**
     * Get the lidar height and prf data for the given resolution, sidelap, target min/max and landscape type
     * 
     * @param resolution 
     * @param sidelap 
     * @param targetMinMax 
     * @param landscapeType 
     * @param freqHz
     * @returns 
     */
    private getLidarHeight(landscapeType : TargetLandscapeType, freqHz : number) : number
    {
        const rho0 = 0.8; // Reflectivity of the ground
        return this.getLidarOptimalAcquisitionHeight(freqHz) * Math.sqrt(this.getRho(landscapeType) / rho0);
    }

    /**
     * Get the LiDAR effective resolution given frequency, height, and sidelap
     * @param frequencyHz
     * @param heightM 
     * @param sidelap 
     * @returns 
     */
    private getLidarEffResolution(frequencyHz, heightM, sidelap) : number
    {
        const swath = this.getFootprintHorizontalSpan(heightM);
        return (frequencyHz * 0.833) * (1 + 2 * sidelap)  / (this._lidarSpeed * swath); 
    }

    /**
     * Get the Rho value for the given landscape type
     * @param landscape The landscape type
     * @returns 
     */
    private getRho(landscape : TargetLandscapeType) : number {
        switch (landscape) {
            case "forest": return 0.4;
            case "natural-soil": return 0.25;
            case "urban": return 0.25;
            case "rocky-mountain": return 0.35;
            case "snowy-mountain": return 0.8;
            default: return 0.1;
        }
    }

    /**
     * Get the optimal acquisition height for the given PRF
     * 
     * @param prf The PRF value in Hz
     */
    private getLidarOptimalAcquisitionHeight(prf : number) {
        if (prf <= 100000) {
            return 1308;
        }
        if (prf <= 200000) {
            return 1142;
        }
        if (prf <= 300000) {
            return 1110;
        }
        if (prf <= 400000) {
            return 967;
        }
        if (prf <= 500000) {
            return 864;
        }
        if (prf <= 800000) {
            return 682;
        }
        if (prf <= 1000000) {
            return 611;
        }
        if (prf <= 1500000) {
            return 500;
        }
        return 397;
    }

    public static sanitizeLidarFrequency(freq : number) : number
    {
        if (freq < 100000) {
            return 100000;
        }
        if (freq > 2000000) {
            return 2000000;
        }
        return freq;
    }

    private static _sensors : Sensor[] = null;
    public static get sensors() : Sensor[]
    {
        if (!Sensor._sensors) {
            Sensor._sensors = [
                this.FLIRA65,
                this.FLIRA8581,
                this.MAIAS2,
                this.AltumPT,
                this.APTThermal,
                this.PhaseOneiXMRS150F,
                this.SonyILXLR1,
                this.SonyILCE7RM2,
                this.SonyILCE7RM3L,
                this.SonyILCE7RM3M,
                this.SonyILCE7RM4L,
                this.SonyILCE7RM4M,
                this.CHCAA15
            ];
        }
        return Sensor._sensors;
    }

    public static get NullSensor() : Sensor
    {
        return new Sensor({
            manufacturer : "",
            type: "",
            name : "NullSensor",
            manufacturerSensorId: "0",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 42.4,
            ccdWidthPx: 7952,
            ccdHeightPx: 5304,
            pixelSize: 4.5,
            frameSizeMegaByte: 40,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1350,
            lensFocals: [],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0,
            isValid: false
        }, 0, 0);
    }

    public static get SonyILCE7RM2() : Sensor
    {
        return new Sensor({
            manufacturerSensorId: "2056",
            type: "rgbcam",
            name: "ILCE 7RM2",
            manufacturer: "Sony",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 42.4,
            ccdWidthPx: 7952,
            ccdHeightPx: 5304,
            pixelSize: 4.5,
            frameSizeMegaByte: 40,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1350,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM3L() : Sensor
    {
        return new Sensor({
            name: "ILCE 7RM3 (L)",
            type: "rgbcam",
            manufacturerSensorId: "2064",
            manufacturer: "Sony",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 42.4,
            ccdWidthPx: 7952,
            ccdHeightPx: 5304,
            pixelSize: 4.5,
            frameSizeMegaByte: 40,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM3M() : Sensor 
    {
        return new Sensor({
            name: "ILCE 7RM3 (M)",
            type: "rgbcam",
            manufacturerSensorId: "2064",
            manufacturer: "Sony",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 18,
            ccdWidthPx: 5168,
            ccdHeightPx: 3448,
            pixelSize: 6.9,
            frameSizeMegaByte: 20,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM4L() : Sensor 
    {
        return new Sensor({
            name: "ILCE 7RM4 (L)",
            type: "rgbcam",
            manufacturerSensorId: "2104",
            manufacturer: "Sony",
            ccdWidthCm: 35.6,
            ccdHeightCm:  23.8,
            ccdResolution: 61,
            ccdWidthPx: 9504,
            ccdHeightPx: 6336,
            pixelSize: 3.7,
            frameSizeMegaByte: 60,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM4M() : Sensor 
    {
        return new Sensor({
            name: "ILCE 7RM4 (M)",
            type: "rgbcam",
            manufacturerSensorId: "2104",
            manufacturer: "Sony",
            ccdWidthCm: 35.6,
            ccdHeightCm:  23.8,
            ccdResolution: 26,
            ccdWidthPx: 6240,
            ccdHeightPx: 4160,
            pixelSize: 5.7,
            frameSizeMegaByte: 30,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get PhaseOneiXMRS150F() : Sensor 
    {
        return new Sensor({
            name: "iXM-RS150F",
            type: "rgbcam",
            manufacturerSensorId: "2080",
            manufacturer: "PhaseOne",
            ccdWidthCm: 53.4,
            ccdHeightCm:  40,
            ccdResolution: 150,
            ccdWidthPx: 14204,
            ccdHeightPx: 10652,
            pixelSize: 3.8,
            frameSizeMegaByte: 150,
            maxBufferMegaByte: 240,
            minShutterTimeMs: 1000,
            lensFocals: [50, 90],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 3],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 2.2,
            isValid: true
        }, 0, 0);
    }

    public static get MAIAS2() : Sensor 
    {
        return new Sensor({
            name: "S2",
            type: "rgbcam",
            manufacturer: "MAIA",
            manufacturerSensorId: "18440",
            ccdWidthCm: 4.8,
            ccdHeightCm: 3.6,
            ccdResolution: 1.2,
            ccdWidthPx: 1280,
            ccdHeightPx: 960,
            pixelSize: 3.8,
            frameSizeMegaByte: 20,
            maxBufferMegaByte: 210,
            minShutterTimeMs: 1000,
            lensFocals: [7.5],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.5,
            isValid: true
        }, 0, 0);
    }

    public static get FLIRA65() : Sensor 
    {
        return new Sensor({
            name: "A65",
            type: "tircam",
            manufacturer: "FLIR",
            manufacturerSensorId: "4104",
            ccdWidthCm: 10.88,
            ccdHeightCm: 8.7,
            ccdResolution: 0.32,
            ccdWidthPx: 640,
            ccdHeightPx: 512,
            pixelSize: 17,
            frameSizeMegaByte: 0.7,
            maxBufferMegaByte: 64,
            minShutterTimeMs: 250,
            lensFocals: [25],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [2],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.3,
            isValid: true
        }, 0, 0);
    }

    public static get FLIRA8581() : Sensor 
    {
        return new Sensor({
            name: "A8581",
            type: "tircam",
            manufacturer: "FLIR",
            manufacturerSensorId: "4112",
            ccdWidthCm: 15.36,
            ccdHeightCm: 12.288,
            ccdResolution: 1.31,
            ccdWidthPx: 1280,
            ccdHeightPx: 1024,
            pixelSize: 12,
            frameSizeMegaByte: 1.5,
            maxBufferMegaByte: 100,
            minShutterTimeMs: 200,
            lensFocals: [50, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "FALLING_EDGE",
            commandGroups: [3],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 3,
            isValid: true
        }, 0, 0);
    }

    public static get AltumPT() : Sensor
    {
        return new Sensor({
            name: "Altum PT",
            type: "rgbcam",
            manufacturer: "Micasense",
            manufacturerSensorId: "18456",
            ccdWidthCm: 7.12,
            ccdHeightCm: 5.33,
            ccdResolution: 3.2,
            ccdWidthPx: 2064,
            ccdHeightPx: 1544,
            pixelSize: 3.45,
            frameSizeMegaByte: 20,
            maxBufferMegaByte: 2000,
            minShutterTimeMs: 1000,
            lensFocals: [8],
            TTCMode: "FALLING_EDGE",
            strobeMode: "FALLING_EDGE",
            commandGroups: [1],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.577,
            isValid: true
        }, 0, 0);
    }

    public static get APTThermal() : Sensor
    {
        return new Sensor({
            name: "APT Thermal",
            type: "tircam",
            manufacturer: "Micasense",
            manufacturerSensorId: "4120",
            ccdWidthCm: 3.84,
            ccdHeightCm: 3.07,
            ccdResolution: 0.08192,
            ccdWidthPx: 320,
            ccdHeightPx: 256,
            pixelSize: 12,
            frameSizeMegaByte: 1.5,
            maxBufferMegaByte: 2000,
            minShutterTimeMs: 500,
            lensFocals: [4.5],
            TTCMode: "FALLING_EDGE",
            strobeMode: "FALLING_EDGE",
            commandGroups: [1],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.577,
            isValid: true
        }, 0, 0);
    }

    public static get SonyILXLR1() : Sensor
    {
        return new Sensor({
            name: "ILX-LR1",
            type: "rgbcam",
            manufacturerSensorId: "2112",
            manufacturer: "Sony",
            ccdWidthCm: 35.7,
            ccdHeightCm:  23.8,
            ccdResolution: 61,
            ccdWidthPx: 9504,
            ccdHeightPx: 6336,
            pixelSize: 3.76,
            frameSizeMegaByte: 60,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [50, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 3],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 0, 0);
    }

    public static get CHCAA15() : Sensor
    {
        return new Sensor({
            name: "AA15",
            type: "lidar",
            manufacturer: "CHC",
            manufacturerSensorId: "8208",
            fovOverride: 75,
            frameSizeMegaByte: 0,
            maxBufferMegaByte: 256000,
            TTCMode: "LAN",
            strobeMode: "LAN",
            commandGroups: [],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 2.45,
            isValid: true
        }, 0, 0);
    }

    public static fromUniqueName(name : string) : Sensor
    {
        switch (name) {
            case "Sony ILCE 7RM2": return Sensor.SonyILCE7RM2;
            case "Sony ILCE 7RM3 (L)": return Sensor.SonyILCE7RM3L;
            case "Sony ILCE 7RM3 (M)": return Sensor.SonyILCE7RM3M;
            case "Sony ILCE 7RM4 (L)": return Sensor.SonyILCE7RM4L;
            case "Sony ILCE 7RM4 (M)": return Sensor.SonyILCE7RM4M;
            case "Sony ILX-LR1": return Sensor.SonyILXLR1;
            case "PhaseOne iXM-RS150F": return Sensor.PhaseOneiXMRS150F;
            case "MAIA S2": return Sensor.MAIAS2;
            case "FLIR A65": return Sensor.FLIRA65;
            case "FLIR A8581": return Sensor.FLIRA8581;
            case "Micasense Altum PT": return Sensor.AltumPT;
            case "NullSensor": return Sensor.NullSensor;
            case "Micasense APT Thermal": return Sensor.APTThermal;
            case "CHC AA15": return Sensor.CHCAA15;
            default: throw "Unknown Sensor name " + name;
        }
    }
}
