export enum BackendType {
    localstorage,
    volatile,
    appwrite
};
  
export const environment = {
    production: false,
    backendType: BackendType.appwrite,
    url: "https://dev.planner.skymetry.it/",
    phpEndpoint: "https://planner.skymetry.it/php/",
    groundElevationEndpoint: "https://elevations.skymetry.it/"
};

export const appwriteConfig = {
    url: "https://appwrite.skymetry.it/v1",
    project: "61c0543bc1000",
    database: "default",
    airportsCollectionId: "airports",
    flagsCollectionId: "flags",
    missionsCollectionId: "missions",
    pilotsCollectionId: "pilots",
    targetsCollectionId: "targets",
    aircraftsCollectionId: "aircrafts"
};
