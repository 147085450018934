import { SensorDataInterface } from "src/app/interfaces/data/sensor-data-interface";
import { TrolleyDataInterface } from "src/app/interfaces/data/trolley-data-interface";
import { Sensor } from "./sensor";
import { TrolleyCategory } from "./trolley-category";
import { EventEmitter } from "@angular/core";

export class Trolley {
    
    /**
     * The array of sensors in this trolley.
     * Set using @method setSensor
     */
    public readonly sensors : Sensor[];

    /**
     * The trolley hardware version
     */
    public get hardwareVersion() : number
    {
        if (this._hw_id <= 0) {
            return 1;
        }
        return 2;
    }

    public get mass() : number
    {
        let weight = this._weightKg;
        for (let sensor of this.sensors) {
            weight += sensor.weightKg;
        }
        return weight;
    }

    /**
     * This id may change when the sensors command group change
     */
    public get smartbayTrolleyId() : string
    {
        const groups = this.sensors.map(s => {
            if (s.isValid) {
                return s.commandGroups[s.commandGroupIndex];
            }
        }).sort((a : number, b : number) : number => {
            if (a < b) {
                return -1;
            }
            if (b < a) {
                return 1;
            }
            return 0;
        }).filter(elem => { return !!elem; }).join(",");

        switch (this.category) {
            case "multicamera":
                switch (groups) {
                    case "1,2": return "12556";
                    case "1,3": return "12554";
                    case "1,4": return "12553";
                    case "2,3": return "12550";
                    case "2,4": return "12549";
                    case "3,4": return "12547";
                    case "1,2,3": return "12558";
                    case "1,2,4": return "12557";
                    case "1,3,4": return "12555";
                    case "2,3,4": return "12551";
                    default: return "12554";
                }
            case "smartcamera":
                switch (groups) {
                    case "1": return "2312";
                    case "2": return "2305";
                    case "3": return "2306";
                    case "4": return "2305";
                    default: return "2305";
                }
            case "naked":
                return "0";
            case "smartgimball":
                throw new Error("SmartGimbal support not implemented");
        }
        console.log("Unknown trolley ID for category " + this.category +  " with groups " + groups);
        return "0";
    }

    public get hw_id() : number
    {
        return this._hw_id;
    }

    public get roll_servo_cmd_group() : number
    {
        return this._roll_servo_cmd_group;
    }

    public get pitch_servo_cmd_group() : number
    {
        return this._pitch_servo_cmd_group;
    }

    public get max_roll_deg() : number
    {
        return this._max_roll_deg;
    }

    public get min_roll_deg() : number
    {
        return this._min_roll_deg;
    }

    public get max_pitch_deg() : number
    {
        return this._max_pitch_deg;
    }

    public get min_pitch_deg() : number
    {
        return this._min_pitch_deg;
    }

    public get tr_roll_offset() : number
    {
        return this._tr_roll_offset;
    }

    public get tr_pitch_offset() : number
    {
        return this._tr_pitch_offset;
    }

    /**
     * Fired every time this trolley configuration changes
     */
    public readonly onChange : EventEmitter<void>;

    private _hw_id : number;
    private _roll_servo_cmd_group : number;
    private _pitch_servo_cmd_group : number;
    private _max_roll_deg : number;
    private _min_roll_deg : number;
    private _max_pitch_deg : number;
    private _min_pitch_deg : number;
    private _tr_roll_offset : number;
    private _tr_pitch_offset : number;

    // 1 1 pitch 0 roll +25,-25 Prototipo Multicamera

    constructor(
        public readonly name : string,
        public readonly category : TrolleyCategory,
        public readonly tClass : string,
        public readonly operativeMode : string,
        private _weightKg : number,
        private _maxSensorsCount : number
    )
    {
        this.sensors = [];
        for (let i = 0; i < this._maxSensorsCount; i++) {
            this.sensors.push(Sensor.NullSensor);
        }

        this.onChange = new EventEmitter<void>();

        this._hw_id = 0;
        this._roll_servo_cmd_group = 0;
        this._pitch_servo_cmd_group = 0;
        this._max_pitch_deg = 0;
        this._min_pitch_deg = 0;
        this._max_roll_deg = 0;
        this._min_roll_deg = 0;
        this._tr_pitch_offset = 0;
        this._tr_roll_offset = 0;
    }

    public clone() : Trolley
    {
        let trolley = Trolley.fromDataInterface(this.toDataInterface());
        for (let i = 0; i < this.sensors.length; i++) {
            trolley.sensors[i] = this.sensors[i].clone();
        }
        return trolley;
    }

    public setSensor(sensor : Sensor, index : number)
    {
        if (index >= 0 && index < this.sensors.length) {
            this.sensors[index] = sensor;
            this.sensors.sort((a : Sensor, b : Sensor) => {
                if (a.isValid && !b.isValid) {
                    return -1;
                }
                if (b.isValid && !a.isValid) {
                    return 1;
                }
                return 0;
            });
            this.onChange.emit();
        }
    }

    public equals(other : Trolley) : boolean
    {
        if (other.smartbayTrolleyId != this.smartbayTrolleyId) {
            return false;
        }
        if (other._hw_id != this._hw_id) {
            return false;
        }
        if (other._roll_servo_cmd_group != this._roll_servo_cmd_group) {
            return false;
        }
        if (other._pitch_servo_cmd_group != this._pitch_servo_cmd_group) {
            return false;
        }
        if (other._max_pitch_deg != this._max_pitch_deg) {
            return false;
        }
        if (other._min_pitch_deg != this._min_pitch_deg) {
            return false;
        }
        if (other._max_roll_deg != this._max_roll_deg) {
            return false;
        }
        if (other._min_roll_deg != this._min_roll_deg) {
            return false;
        }
        if (other._tr_pitch_offset != this._tr_pitch_offset) {
            return false;
        }
        if (other._tr_roll_offset != this._tr_roll_offset) {
            return false;
        }
        if (other.operativeMode != this.operativeMode) {
            return false;
        }
        if (other._weightKg != this._weightKg) {
            return false;
        }
        if (other._maxSensorsCount != this._maxSensorsCount) {
            return false;
        }
        for (let i = 0; i < this.sensors.length; i++) {
            if (!other.sensors[i].equals(this.sensors[i])) {
                return false;
            }
        }
        return true;
    }

    public static fromDataInterface(int : TrolleyDataInterface) : Trolley
    {
        let trolley = Trolley.fromName(int.name);
        if (!!int.sensors) {
            for (let i = 0; i < int.sensors.length && i < trolley._maxSensorsCount; i++) {
                trolley.sensors[i] = Sensor.fromDataInterface(int.sensors[i]);
            }
        }
        return trolley;
    }

    public toDataInterface() : TrolleyDataInterface
    {
        let sensors : SensorDataInterface[] = [];
        for (let sensor of this.sensors) {
            sensors.push(sensor.toDataInterface());
        }
        return {
            name: this.name,
            sensors: sensors
        };
    }

    private static _trolleys : Trolley[] = null;
    public static get trolleys() : Trolley[]
    {
        if (!Trolley._trolleys) {
            Trolley._trolleys = [
                Trolley.Multicamera,
                Trolley.Multicamera2Prototype,
                Trolley.SmartCamera,
                //Trolley.SmartGimbal,
                Trolley.PowerSupply,
                Trolley.Naked
            ];
        }
        return Trolley._trolleys;
    }

    public static get Multicamera() : Trolley
    {
        return new Trolley("Multicamera", "multicamera", "A", "0", 2.6, 3);
    }

    public static get Multicamera2Prototype() : Trolley
    {
        const trolley = new Trolley("Multicamera 2.0 Proto", "multicamera", "A", "0", 2.6, 3);
        trolley._hw_id = 1;
        trolley._roll_servo_cmd_group = 1;
        trolley._max_roll_deg = 25;
        trolley._min_roll_deg = -25;
        return trolley;
    }

    public static get SmartCamera() : Trolley
    {
        return new Trolley("SmartCamera", "smartcamera", "A", "operative mode", 1.3, 1);
    }

    // public static get SmartGimbal() : Trolley
    // {
    //     return new Trolley("SmartGimbal", TrolleyCategory.SmartGimbal, "A", "operative mode", 6, 1);
    // }

    public static get PowerSupply() : Trolley
    {
        return new Trolley("PowerSupply", "naked", "RESERVED", "operative mode", 0.4, 0);
    }

    public static get Naked() : Trolley
    {
        return new Trolley("Naked", "naked", "RESERVED", "operative mode", 0.4, 0);
    }

    public static fromName(name : string) : Trolley
    {
        switch (name.toLowerCase()) {
            case "multicamera": return Trolley.Multicamera;
            case "multicamera 2.0 proto": return Trolley.Multicamera2Prototype;
            case "smartcamera": return Trolley.SmartCamera;
            //case "SmartGimbal": return Trolley.SmartGimbal;
            case "powersupply": return Trolley.PowerSupply;
            default: return Trolley.Naked;
        }
    }
}
