import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AirportDataInterface } from '../interfaces/data/airport-data-interface';
import { StorageService } from './storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AirportService {

    private _airports : AirportDataInterface[];
    private _readyPromise : Promise<void>;

    constructor(private _storage : StorageService)
    {
        this._airports = [];

        if (!environment.production) {
            this.save({
                icao: "LIMA",
                name: "Torino Aeritalia",
                position: {
                    latitude: 45.0844,
                    longitude: 7.603
                },
                elevationM: 0,
                type: "airport"
            }).then(() => {
                this.save({
                    icao: "LIMF",
                    name: "Torino Caselle",
                    position: {
                        latitude: 45.2025,
                        longitude: 7.649
                    },
                    elevationM: 0,
                    type: "airport"
                });
            });
        }

        this._readyPromise = new Promise<void>(resolve => {
            this._storage.getAllAirports().subscribe(apts => {
                this._airports = apts;
                resolve();
            });
        });
    }

    public async getAll() : Promise<AirportDataInterface[]>
    {
        await this._readyPromise;
        return this._airports;
    }

    public async get(icao : string) : Promise<AirportDataInterface>
    {
        await this._readyPromise;
        return this._airports.find(apt => apt.icao == icao);
    }

    public async save(airport : AirportDataInterface) : Promise<void>
    {
        await this._storage.saveAirport(airport);
    }
}
