import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendType, environment } from 'src/environments/environment';
import { AuthServiceInterface, TeamInterface } from '../../interfaces/auth-service-interface';
import { AppwriteAuthService } from './appwrite-auth.service';
import { NoAuthService } from './no-auth.service';
import { TestAuthService } from './test-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements AuthServiceInterface {

    public get onSignin() : Observable<void>
    {
        return this._auth.onSignin;
    }

    public get onBeforeSignout() : Observable<void>
    {
        return this._auth.onBeforeSignout;
    }

    public get signedIn() : Observable<boolean>
    {
        return this._auth.signedIn;
    }

    public getUserTeams(): TeamInterface[]
    {
        return this._auth.getUserTeams();
    }

    public get currentUserEmail() : string
    {
        return this._auth.currentUserEmail;
    }

    private _auth : AuthServiceInterface;

    constructor(
        appWriteAuth: AppwriteAuthService,
        testAuth : TestAuthService,
        noAuth : NoAuthService) 
    {
        switch (environment.backendType) {
            case BackendType.appwrite: this._auth = appWriteAuth; break;
            case BackendType.volatile: this._auth = testAuth; break;
            default: this._auth = noAuth; break;
        }
    }

    public async signInWithCredential(email : string, password : string) : Promise<any>
    {
        return this._auth.signInWithCredential(email, password);
    }

    public async signOut() : Promise<any>
    {
        return this._auth.signOut();
    }

    public async sendPasswordRecoveryEmail(email : string) : Promise<void>
    {
        return this._auth.sendPasswordRecoveryEmail(email);
    }

    public async recoverPasswordWithSecret(password : string, userId : string, secret : string) : Promise<void>
    {
        return this._auth.recoverPasswordWithSecret(password, userId, secret);
    }

    /**
     * Update the password or throw an exception
     * 
     * @param oldPassword 
     * @param password 
     * @param control 
     * @returns 
     */
    public async updatePassword(oldPassword : string, password: string, control: string): Promise<void>
    {
        await this._auth.validateNewPassword(password, control);
        return this._auth.updatePassword(oldPassword, password, control);
    }

    /**
     * Validate the new password or throw an exception
     * 
     * @param password1 The new password
     * @param password2 Repeat the new password
     * @returns 
     */
    public async validatePassword(password1: string, password2: string): Promise<void>
    {
        // Unify here the password validation
        if (password1 !== password2) {
            throw new Error('Passwords do not match');
        }
    }

    /**
     * Validate the new password or throw an exception
     * 
     * @param password The new password
     * @param control The retyped password
     */
    public async validateNewPassword(password: string, control: string): Promise<void>
    {
        return this._auth.validateNewPassword(password, control);
    }

}
