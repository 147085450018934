import { SensorDataInterface } from "src/app/interfaces/data/sensor-data-interface";
import { SensorInterface } from "../../interfaces/sensor-interface";
import { SignalEdgeMode } from "../signal-edge-mode";

export class Sensor implements SensorInterface {

    public readonly manufacturerSensorId: string;
    public readonly name : string;
    public readonly manufacturer : string;
    public readonly type : string;
    public readonly ccdWidthCm: number;
    public readonly ccdHeightCm: number;
    public readonly ccdResolution: number;
    public readonly ccdWidthPx: number;
    public readonly ccdHeightPx: number;
    public readonly pixelSize: number;
    public readonly frameSizeMegaByte: number;
    public readonly maxBufferMegaByte: number;
    public readonly minShutterTimeMs: number;
    public readonly lensFocals: number[];
    public readonly weightKg: number;
    public readonly TTCMode: SignalEdgeMode;
    public readonly strobeMode: SignalEdgeMode;
    public readonly offsetTimeMs: number;
    public readonly commandGroups: number[];
    public roll: number;
    public readonly pitchDeg: number;
    public readonly isValid: boolean;

    public get uniqueName() : string
    {
        if (!!this.manufacturer) {
            return this.manufacturer + " " + this.name;
        }
        return this.name;
    }

    public get commandGroupIndex() : number
    {
        return this._commandGroupIndex;
    }

    public set commandGroupIndex(index : number)
    {
        if (index >= 0 && index < this.commandGroups.length) {
            this._commandGroupIndex = index;
        }
        else {
            console.warn("Wrong command group index " + index + " for sensor " + this.uniqueName);
        }
    }

    public get lensIndex() : number
    {
        return this._lensIndex;
    }

    /**
     * Get an ordered array of available focals and index
     */
    public get orderedLensFocals() : { "focal": number, "index": number}[]
    {
        // Sort the focals in ascending order
        // and add their array index
        const focals = [];
        for (let i = 0; i < this.lensFocals.length; i++) {
            focals.push({ "focal": this.lensFocals[i], "index": i });
        }
        focals.sort((a, b) => a.focal - b.focal);
        return focals;
    }

    public set lensIndex(index : number)
    {
        if (index >= 0 && index < this.lensFocals.length) {
            this._lensIndex = index;
        }
        else {
            console.warn("Wrong lens focal index " + index + " for sensor " + this.uniqueName);
        }
    }

    private _commandGroupIndex : number;
    private _lensIndex : number;

    constructor(config : SensorInterface, lensIndex : number, commandGroupIndex : number)
    {
        this.manufacturerSensorId = config.manufacturerSensorId;
        this.name = config.name;
        this.manufacturer = config.manufacturer;
        this.type = config.type;
        this.ccdWidthCm = config.ccdWidthCm;
        this.ccdHeightCm = config.ccdHeightCm;
        this.ccdResolution = config.ccdResolution;
        this.ccdWidthPx = config.ccdWidthPx;
        this.ccdHeightPx = config.ccdHeightPx;
        this.pixelSize = config.pixelSize;
        this.frameSizeMegaByte = config.frameSizeMegaByte;
        this.maxBufferMegaByte = config.maxBufferMegaByte;
        this.minShutterTimeMs = config.minShutterTimeMs;
        this.lensFocals = config.lensFocals;
        this.weightKg = config.weightKg;
        this.TTCMode = config.TTCMode;
        this.strobeMode = config.strobeMode;
        this.offsetTimeMs = config.offsetTimeMs;
        this.commandGroups = config.commandGroups;
        this.roll = config.roll;
        this.pitchDeg = config.pitchDeg;
        this.isValid = config.isValid;

        this._lensIndex = lensIndex;
        this._commandGroupIndex = commandGroupIndex;
    }

    public clone() : Sensor
    {
        return new Sensor(this, this._lensIndex, this._commandGroupIndex);
    }

    public equals(other : Sensor) : boolean
    {
        if (other.manufacturerSensorId != this.manufacturerSensorId) {
            return false;
        }
        if (other.name != this.name) {
            return false;
        }
        if (other.manufacturer != this.manufacturer) {
            return false;
        }
        if (other.commandGroups.length != this.commandGroups.length) {
            return false;
        }
        if (other.roll != this.roll) {
            return false;
        }
        if (other.pitchDeg != this.pitchDeg) {
            return false;
        }
        if (other._lensIndex != this._lensIndex) {
            return false;
        }
        if (other._commandGroupIndex != this._commandGroupIndex) {
            return false;
        }
        return true;
    }

    /** Get the data interface of this sensor. */
    public toDataInterface() : SensorDataInterface
    {
        return {
            id: this.uniqueName,
            commandGroupIndex: this._commandGroupIndex,
            lensIndex: this._lensIndex,
            roll: this.roll
        };
    }

    public static fromDataInterface(int : SensorDataInterface) : Sensor
    {
        let sensor = Sensor.fromUniqueName(int.id);
        // A null sensor does not have data to load
        if (!sensor.isValid) {
            return sensor;
        }
        sensor.commandGroupIndex = int.commandGroupIndex;
        sensor.roll = int.roll;
        sensor.lensIndex = int.lensIndex;
        return sensor;
    }

    private static _sensors : Sensor[] = null;
    public static get sensors() : Sensor[]
    {
        if (!Sensor._sensors) {
            Sensor._sensors = [
                this.FLIRA65,
                this.FLIRA8581,
                this.MAIAS2,
                this.AltumPT,
                this.APTThermal,
                this.PhaseOneiXMRS150F,
                this.SonyILXLR1,
                this.SonyILCE7RM2,
                this.SonyILCE7RM3L,
                this.SonyILCE7RM3M,
                this.SonyILCE7RM4L,
                this.SonyILCE7RM4M
            ];
        }
        return Sensor._sensors;
    }

    public static get NullSensor() : Sensor
    {
        return new Sensor({
            manufacturer : "",
            type: "",
            name : "NullSensor",
            manufacturerSensorId: "0",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 42.4,
            ccdWidthPx: 7952,
            ccdHeightPx: 5304,
            pixelSize: 4.5,
            frameSizeMegaByte: 40,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1350,
            lensFocals: [],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0,
            isValid: false
        }, 0, 0);
    }

    public static get SonyILCE7RM2() : Sensor
    {
        return new Sensor({
            manufacturerSensorId: "2056",
            type: "rgbcam",
            name: "ILCE 7RM2",
            manufacturer: "Sony",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 42.4,
            ccdWidthPx: 7952,
            ccdHeightPx: 5304,
            pixelSize: 4.5,
            frameSizeMegaByte: 40,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1350,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM3L() : Sensor
    {
        return new Sensor({
            name: "ILCE 7RM3 (L)",
            type: "rgbcam",
            manufacturerSensorId: "2064",
            manufacturer: "Sony",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 42.4,
            ccdWidthPx: 7952,
            ccdHeightPx: 5304,
            pixelSize: 4.5,
            frameSizeMegaByte: 40,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM3M() : Sensor 
    {
        return new Sensor({
            name: "ILCE 7RM3 (M)",
            type: "rgbcam",
            manufacturerSensorId: "2064",
            manufacturer: "Sony",
            ccdWidthCm: 35.9,
            ccdHeightCm:  24,
            ccdResolution: 18,
            ccdWidthPx: 5168,
            ccdHeightPx: 3448,
            pixelSize: 6.9,
            frameSizeMegaByte: 20,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM4L() : Sensor 
    {
        return new Sensor({
            name: "ILCE 7RM4 (L)",
            type: "rgbcam",
            manufacturerSensorId: "2104",
            manufacturer: "Sony",
            ccdWidthCm: 35.6,
            ccdHeightCm:  23.8,
            ccdResolution: 61,
            ccdWidthPx: 9504,
            ccdHeightPx: 6336,
            pixelSize: 3.7,
            frameSizeMegaByte: 60,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get SonyILCE7RM4M() : Sensor 
    {
        return new Sensor({
            name: "ILCE 7RM4 (M)",
            type: "rgbcam",
            manufacturerSensorId: "2104",
            manufacturer: "Sony",
            ccdWidthCm: 35.6,
            ccdHeightCm:  23.8,
            ccdResolution: 26,
            ccdWidthPx: 6240,
            ccdHeightPx: 4160,
            pixelSize: 5.7,
            frameSizeMegaByte: 30,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [28, 50, 55, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 2, 3, 4],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 1, 0);
    }

    public static get PhaseOneiXMRS150F() : Sensor 
    {
        return new Sensor({
            name: "iXM-RS150F",
            type: "rgbcam",
            manufacturerSensorId: "2080",
            manufacturer: "PhaseOne",
            ccdWidthCm: 53.4,
            ccdHeightCm:  40,
            ccdResolution: 150,
            ccdWidthPx: 14204,
            ccdHeightPx: 10652,
            pixelSize: 3.8,
            frameSizeMegaByte: 150,
            maxBufferMegaByte: 240,
            minShutterTimeMs: 1000,
            lensFocals: [50, 90],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 3],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 2.2,
            isValid: true
        }, 0, 0);
    }

    public static get MAIAS2() : Sensor 
    {
        return new Sensor({
            name: "S2",
            type: "rgbcam",
            manufacturer: "MAIA",
            manufacturerSensorId: "18440",
            ccdWidthCm: 4.8,
            ccdHeightCm: 3.6,
            ccdResolution: 1.2,
            ccdWidthPx: 1280,
            ccdHeightPx: 960,
            pixelSize: 3.8,
            frameSizeMegaByte: 20,
            maxBufferMegaByte: 210,
            minShutterTimeMs: 1000,
            lensFocals: [7.5],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.5,
            isValid: true
        }, 0, 0);
    }

    public static get FLIRA65() : Sensor 
    {
        return new Sensor({
            name: "A65",
            type: "tircam",
            manufacturer: "FLIR",
            manufacturerSensorId: "4104",
            ccdWidthCm: 10.88,
            ccdHeightCm: 8.7,
            ccdResolution: 0.32,
            ccdWidthPx: 640,
            ccdHeightPx: 512,
            pixelSize: 17,
            frameSizeMegaByte: 0.7,
            maxBufferMegaByte: 64,
            minShutterTimeMs: 250,
            lensFocals: [25],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [2],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.3,
            isValid: true
        }, 0, 0);
    }

    public static get FLIRA8581() : Sensor 
    {
        return new Sensor({
            name: "A8581",
            type: "tircam",
            manufacturer: "FLIR",
            manufacturerSensorId: "4112",
            ccdWidthCm: 15.36,
            ccdHeightCm: 12.288,
            ccdResolution: 1.31,
            ccdWidthPx: 1280,
            ccdHeightPx: 1024,
            pixelSize: 12,
            frameSizeMegaByte: 1.5,
            maxBufferMegaByte: 100,
            minShutterTimeMs: 200,
            lensFocals: [50, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "FALLING_EDGE",
            commandGroups: [3],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 3,
            isValid: true
        }, 0, 0);
    }

    public static get AltumPT() : Sensor
    {
        return new Sensor({
            name: "Altum PT",
            type: "rgbcam",
            manufacturer: "Micasense",
            manufacturerSensorId: "18456",
            ccdWidthCm: 7.12,
            ccdHeightCm: 5.33,
            ccdResolution: 3.2,
            ccdWidthPx: 2064,
            ccdHeightPx: 1544,
            pixelSize: 3.45,
            frameSizeMegaByte: 20,
            maxBufferMegaByte: 2000,
            minShutterTimeMs: 1000,
            lensFocals: [8],
            TTCMode: "FALLING_EDGE",
            strobeMode: "FALLING_EDGE",
            commandGroups: [1],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.577,
            isValid: true
        }, 0, 0);
    }

    public static get APTThermal() : Sensor
    {
        return new Sensor({
            name: "APT Thermal",
            type: "tircam",
            manufacturer: "Micasense",
            manufacturerSensorId: "4120",
            ccdWidthCm: 3.84,
            ccdHeightCm: 3.07,
            ccdResolution: 0.08192,
            ccdWidthPx: 320,
            ccdHeightPx: 256,
            pixelSize: 12,
            frameSizeMegaByte: 1.5,
            maxBufferMegaByte: 2000,
            minShutterTimeMs: 500,
            lensFocals: [4.5],
            TTCMode: "FALLING_EDGE",
            strobeMode: "FALLING_EDGE",
            commandGroups: [1],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 0.577,
            isValid: true
        }, 0, 0);
    }

    public static get SonyILXLR1() : Sensor
    {
        return new Sensor({
            name: "ILX-LR1",
            type: "rgbcam",
            manufacturerSensorId: "2112",
            manufacturer: "Sony",
            ccdWidthCm: 35.7,
            ccdHeightCm:  23.8,
            ccdResolution: 61,
            ccdWidthPx: 9504,
            ccdHeightPx: 6336,
            pixelSize: 3.76,
            frameSizeMegaByte: 60,
            maxBufferMegaByte: 256,
            minShutterTimeMs: 1000,
            lensFocals: [50, 35, 100],
            TTCMode: "FALLING_EDGE",
            strobeMode: "RISING_EDGE",
            commandGroups: [1, 3],
            offsetTimeMs: 0,
            pitchDeg: -90,
            roll: 0,
            weightKg: 1.1,
            isValid: true
        }, 0, 0);
    }

    public static fromUniqueName(name : string) : Sensor
    {
        switch (name) {
            case "Sony ILCE 7RM2": return Sensor.SonyILCE7RM2;
            case "Sony ILCE 7RM3 (L)": return Sensor.SonyILCE7RM3L;
            case "Sony ILCE 7RM3 (M)": return Sensor.SonyILCE7RM3M;
            case "Sony ILCE 7RM4 (L)": return Sensor.SonyILCE7RM4L;
            case "Sony ILCE 7RM4 (M)": return Sensor.SonyILCE7RM4M;
            case "Sony ILX-LR1": return Sensor.SonyILXLR1;
            case "PhaseOne iXM-RS150F": return Sensor.PhaseOneiXMRS150F;
            case "MAIA S2": return Sensor.MAIAS2;
            case "FLIR A65": return Sensor.FLIRA65;
            case "FLIR A8581": return Sensor.FLIRA8581;
            case "Micasense Altum PT": return Sensor.AltumPT;
            case "NullSensor": return Sensor.NullSensor;
            case "Micasense APT Thermal": return Sensor.APTThermal;
            default: throw "Unknown Sensor name " + name;
        }
    }
}
