import { PositionInterface } from "../interfaces/position-interface";

export class Position {
    public get latitude() : number
    {
        return this._latitude;
    }

    public get longitude() : number
    {
        return this._longitude;
    } 

    public get altitude() : number
    {
        return 0;
    }

    constructor(private _latitude : number, private _longitude : number) {}

    public equals(other : Position) : boolean
    {
        return this._latitude == other._latitude && this._longitude == other._longitude;
    }

    /** Distance of this position to the specified point in meters */
    public distanceTo(point : PositionInterface) : number
    {
        const R = 6371e3; // metres
        const phi1 = this.latitude * Math.PI / 180; // φ, λ in radians
        const phi2 = point.latitude * Math.PI / 180;
        const deltaPhi = (point.latitude - this.latitude) * Math.PI / 180;
        const deltaLambda = (point.longitude - this.longitude) * Math.PI / 180;

        const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
                Math.cos(phi1) * Math.cos(phi2) *
                Math.sin(deltaLambda / 2) * Math.sin(deltaLambda  /2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // in metres
    }

    public headingTo(point : PositionInterface) : number
    {
        let startLat = this.toRadians(this.latitude);
        let startLng = this.toRadians(this.longitude);
        let destLat = this.toRadians(point.latitude);
        let destLng = this.toRadians(point.longitude);
    
        let y = Math.sin(destLng - startLng) * Math.cos(destLat);
        let x = Math.cos(startLat) * Math.sin(destLat) -
            Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        let brng = Math.atan2(y, x);
        brng = this.toDegrees(brng);
        return (brng + 360) % 360
    }

    /**
     * 
     * @param target The target position
     * @param track The track from wich the rilpo must be calculated
     * @returns 
     */
    public rilpoTo(target : PositionInterface, track : number) : number
    {
        let rilpo = this.headingTo(target) - track;
        if (rilpo < -180) {
            rilpo += 360;
        }
        else if (rilpo > 180) {
            rilpo -= 360;
        }
        return rilpo;
    }

    private toRadians(degrees : number) : number
    {
        return degrees * Math.PI / 180;
    }
   
    private toDegrees(radians : number) : number
    {
        return radians * 180 / Math.PI;
    }

    /**
     * Return a new position that is this position offset a heading and distance
     * 
     * @param headingDeg The heading in degrees
     * @param distanceM The distance to offset in meters
     * @returns The new position
     */
    public offset(headingDeg : number, distanceM : number) : PositionInterface
    {
        const radiusEarthKilometres = 6371.01;
        var distRatio = (distanceM / 1000) / radiusEarthKilometres;
        var distRatioSine = Math.sin(distRatio);
        var distRatioCosine = Math.cos(distRatio);

        var startLatRad = this.toRadians(this.latitude);
        var startLonRad = this.toRadians(this.longitude);

        var startLatCos = Math.cos(startLatRad);
        var startLatSin = Math.sin(startLatRad);

        var endLatRads = Math.asin((startLatSin * distRatioCosine) + (startLatCos * distRatioSine * Math.cos(this.toRadians(headingDeg))));

        var endLonRads = startLonRad
            + Math.atan2(
                Math.sin(this.toRadians(headingDeg)) * distRatioSine * startLatCos,
                distRatioCosine - startLatSin * Math.sin(endLatRads));

        return new Position(this.toDegrees(endLatRads), this.toDegrees(endLonRads));
    }

    static fromInterface (position : PositionInterface) : Position
    {
        return new Position(position.latitude, position.longitude);
    }



    /**
     * Format a position to a latitude and longitude human readable string
     * @param position The position
     * @returns The lat/lon string in skydemon format
     */
    public static toString(position : PositionInterface) : string
    {
        let coords = "";

        let prefix = (val : number, precision : number = 0) => {
            if (precision) {
                return (val < 10 ? ("0" + val.toPrecision(precision)) : val.toPrecision(precision));
            }
            return (val < 10 ? ("0" + val) : val);
        }
        let recurse = (val : number, index : number = 0) => {
            if (index >= 2) {
                return ("" + prefix(Math.round(val * 100) / 100, 2)).substring(0, 5);
            }
            return "" + prefix(Math.trunc(val)) + recurse((val - Math.trunc(val)) * 60, ++index);
        };

        coords += position.latitude > 0 ? "N" : "S";
        coords += Math.round(Math.abs(position.latitude) * 100) / 100;
        coords += " " + (position.longitude > 0 ? "E" : "W");
        coords += Math.round(Math.abs(position.longitude) * 100) / 100;

        return coords;
    }

    /**
     * Format a position to a latitude and longitude string in Skydemon format.
     * @param position The position
     * @returns The lat/lon string in skydemon format
     */
    public static toSkydemon(position : PositionInterface) : string
    {
        let coords = "";

        let prefix = (val : number, precision : number = 0) => {
            if (precision) {
                return (val < 10 ? ("0" + val.toPrecision(precision)) : val.toPrecision(precision));
            }
            return (val < 10 ? ("0" + val) : val);
        }
        let recurse = (val : number, index : number = 0) => {
            if (index >= 2) {
                return ("" + prefix(Math.round(val * 100) / 100, 5)).substring(0, 5);
            }
            return "" + prefix(Math.trunc(val)) + recurse((val - Math.trunc(val)) * 60, ++index);
        };

        coords += position.latitude > 0 ? "N" : "S";
        coords += recurse(Math.abs(position.latitude));
        coords += " " + (position.longitude > 0 ? "E" : "W");
        if (position.longitude > -100 && position.longitude < 100) {
            coords += "0";
        }
        coords += recurse(Math.abs(position.longitude));

        return coords;
    }

    /**
     * Format a position to a latitude and longitude string in ICAO format.
     * @param position The position
     * @returns The lat/lon string in ICAO format
     */
    public static toICAO(position : PositionInterface) : string
    {
        let coords = "";

        let prefix = (val : number) => {
            return (val < 10 ? ("0" + val) : val);
        }

        let recurse = (val : number, index : number = 0) => {
            if (index >= 1) {
                return ("" + prefix(Math.round(val))).substring(0, 5);
            }
            return "" + prefix(Math.trunc(val)) + recurse((val - Math.trunc(val)) * 60, ++index);
        };

        
        coords += recurse(Math.abs(position.latitude));
        coords += position.latitude > 0 ? "N" : "S";
        if (position.longitude > -100 && position.longitude < 100) {
            coords += "0";
        }
        coords += recurse(Math.abs(position.longitude));
        coords += (position.longitude > 0 ? "E" : "W");

        return coords;
    }
}
