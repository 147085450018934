import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AsyncTranslateService } from 'src/app/services/async-translate.service';
import { MissionService } from 'src/app/services/mission.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateMissionGuard implements CanDeactivate<unknown>  {

	constructor(
		private _missionService : MissionService,
		private _alertController : AlertController,
		private _translateService : AsyncTranslateService
	)
	{

	}

	public canDeactivate(
		component: unknown,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
			return new Observable<boolean>((subscriber) => {
				this._missionService.get(currentRoute.paramMap.get('id')).then(async mission => {
					if (!mission.needsSaving) {
						subscriber.next(true);
						return;
					}

					const l10n = await this._translateService.get([
						"common.warning",
						"mission.unsavedChangesAlert",
						"common.ok",
						"common.cancel"
					]);
					const alert = await this._alertController.create({
						header: l10n["common.warning"],
						message: l10n["mission.unsavedChangesAlert"],
						buttons: [
							{
								text: l10n["common.ok"],
								handler: () => { subscriber.next(true); }
							},
							{
								text: l10n["common.cancel"],
								role: "cancel",
								handler: () => { subscriber.next(false); }
							}
						]
					});
					await alert.present();
				});
			});
	}
  
}
