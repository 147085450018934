import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { AirportWaypoint } from "../route/airport-waypoint";
import { TargetWaypoint } from "../route/target-waypoint";
import { TurnWaypoint } from "../route/turn-waypoint";
import { FlowStatus } from "../flow-status";
import { TargetService } from "src/app/services/target.service";
import { Target } from "../target";
import { TargetVisitorInterface } from "src/app/interfaces/target-visitor-interface";
import { TargetsGroup } from "../targets-group";

/**
 * @class ChangeTargetStatusVisitor
 * 
 * Update a set of waypoints and set their flow status to the specified one.
 */
export class ChangeStatusWaypointVisitor implements WaypointVisitorInterface, TargetVisitorInterface {

    private _targets : Target[];

    /**
     * 
     * @param _targetFlow The target flow status to assign to the waypoints
     */
    constructor(private _targetFlow : FlowStatus)
    {
        this._targets = [];
    }

    public visitTarget(target: Target): void {
        this._targets.push(target);
        target.flowStatus = this._targetFlow;
    }

    public visitTargetGroup(target: TargetsGroup): void {
        for (let t of target.targets) {
            t.acceptTargetVisitor(this);
        }
    }

    public visitTargetWaypoint(wp: TargetWaypoint): void
    {
        wp.target.acceptTargetVisitor(this);
    }

    public visitTurnWaypoint(wp: TurnWaypoint): void {}

    public visitAirportWaypoint(wp: AirportWaypoint): void {}

    /**
     * Save the changes made to the targets.
     * 
     * @param targetService The target service
     */
    public async save(targetService : TargetService)
    {
        await targetService.saveTargetBulk(this._targets);
        this._targets = [];
    }

}
