import { MassAndBalanceStationDataInterface } from "src/app/interfaces/data/mass-and-balance-station-data-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { FuelType } from "./fuel-type";
import { MassAndBalanceStation } from "./mass-and-balance-station";
import { MassAndBalanceStationType } from "./mass-and-balance-station-type";

export class FuelMassAndBalanceStation extends MassAndBalanceStation {

    /**
     * This station type
     */
    public get type() : MassAndBalanceStationType
    {
        return "fuel";
    }
    
    /**
     * Set the fuel load in Kg
     */
    public set mass(kg : number)
    {
        this._mass = Math.max(0, Math.min(kg, this.maxLoadKg));
        this.onChange.emit();
    }

    /**
     * Get the fuel load in kg
     */
    public get mass() : number
    {
        return this._mass;
    }

    /**
     * Get the fuel load in lt
     */
    public get volume() : number
    {
        return FuelMassAndBalanceStation.fuelMassToVolume(this.mass, this.fuelType);
    }

    /**
     * Set the fuel load in liters
     */
    public set volume(lt : number)
    {
        this.mass = FuelMassAndBalanceStation.fuelVolumeToMass(lt, this.fuelType);
    }

    public get maxVolume() : number
    {
        return FuelMassAndBalanceStation.fuelMassToVolume(this.maxLoadKg, this.fuelType);
    }

    private _mass : number;

    /**
     * A mass and balance station that manages the fuel
     * 
     * @param name The station name
     * @param arm The station arm in m
     * @param maxLoadKg The maximum load in Kg in this stations
     * @param fuelType The fuel type used
     */
    constructor(name : string, public readonly arm : number, public readonly maxLoadKg : number, public readonly fuelType : FuelType = "avgas")
    {
        super(name);
        this._mass = maxLoadKg;
    }

    public clone() : MassAndBalanceStation
    {
        const station = new FuelMassAndBalanceStation(this.name, this.arm, this.maxLoadKg, this.fuelType);
        station.mass = this.mass;
        return station;
    }

    public toDataInterface() : MassAndBalanceStationDataInterface
    {
        return {
            arm: this.arm,
            name: this.name,
            type: "fuel",
            payloads: [this.maxLoadKg, this.fuelType],
            defaults: []
        };
    }

    public acceptMassAndBalanceVisitor(visitor: MassAndBalanceVisitorInterface)
    {
        visitor.visitFuelStation(this);
    }

    public static fromDataInterface(int : MassAndBalanceStationDataInterface) : FuelMassAndBalanceStation
    {
        return new FuelMassAndBalanceStation(int.name, int.arm, int.payloads[0], int.payloads[1]);
    }

    /**
     * 
     * @param mass The fuel volume in Lt
     * @param type The fuel type
     * 
     * @returns The fuel mass in Kg
     */
     public static fuelVolumeToMass(volume : number, type : FuelType = "avgas") : number
     {
         switch (type) {
             case "jeta1": return volume * 0.78;
             default: return volume * 0.72;
         }
     }

    /**
     * 
     * @param mass The fuel mass in Kg
     * @param type The fuel type
     * 
     * @returns The fuel volume in lt
     */
    public static fuelMassToVolume(mass : number, type : FuelType = "avgas") : number
    {
        switch (type) {
            case "jeta1": return mass / 0.78;
            default: return mass / 0.72;
        }
    }
}
