import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { Mission } from 'src/app/classes/mission';
import { FlagDataInterface, FlagId } from 'src/app/interfaces/data/flag-data-interface';
import { MissionDataInterface } from 'src/app/interfaces/data/mission-data-interface';
import { PilotDataInterface } from 'src/app/interfaces/data/pilot-data-interface';
import { StorageServiceInterface } from 'src/app/interfaces/storage-service-interface';
import { TargetDataInterface } from 'src/app/interfaces/data/target-data-interface';
import { appwriteConfig } from 'src/environments/environment';
import { AppwriteService } from '../appwrite.service';
import { AirportDataInterface } from 'src/app/interfaces/data/airport-data-interface';
import { Models, Query } from 'appwrite';
import { AppwriteAuthService } from '../auth/appwrite-auth.service';
import { AircraftDataInterface } from 'src/app/interfaces/data/aircraft-data-interface';

interface TargetAppwriteInterface extends TargetDataInterface, Models.Document {}
interface MissionAppwriteInterface extends MissionDataInterface, Models.Document {}
interface AirportAppwriteInterface extends AirportDataInterface, Models.Document {}
interface PilotAppwriteInterface extends PilotDataInterface, Models.Document {}
interface AircraftAppwriteInterface extends PilotDataInterface, Models.Document {}

@Injectable({
  providedIn: 'root'
})
export class AppwriteStorageService implements StorageServiceInterface {

    public get isOnline() : Observable<boolean>
    {
        return this._appwrite.serviceIsAvailable;
    }

    private _missionsCollection : string;
    private _airportsCollection : string;
    private _pilotsCollection: string;
    private _targetsCollection : string;
    private _flagsCollection : string;
    private _aircraftsCollection : string;

    private _flagsSubject : Subject<{ event: string, payload: any }>;
    private _targetsSubject : Subject<{ event: string, payload: any }>;
    private _missionsSubject : Subject<{ event: string, payload: any }>;
    private _aircraftsSubject : Subject<{ event: string, payload: any }>;

    constructor(private _appwrite : AppwriteService, private _auth : AppwriteAuthService)
    {
        this._missionsCollection = appwriteConfig.missionsCollectionId;
        this._airportsCollection = appwriteConfig.airportsCollectionId;
        this._pilotsCollection = appwriteConfig.pilotsCollectionId;
        this._targetsCollection = appwriteConfig.targetsCollectionId;
        this._flagsCollection = appwriteConfig.flagsCollectionId;
        this._aircraftsCollection = appwriteConfig.aircraftsCollectionId;

        // Use a single subscription for all the updates
        this._flagsSubject = new Subject<{ event: string, payload: any }>();
        this._targetsSubject = new Subject<{ event: string, payload: any }>();
        this._missionsSubject = new Subject<{ event: string, payload: any }>();
        this._aircraftsSubject = new Subject<{ event: string, payload: any }>();
    }

    /**
     * Connect the service to the storage
     */
    public connect()
    {
        this._appwrite.client.subscribe([
            "databases." + appwriteConfig.database + ".collections." + this._flagsCollection + ".documents",
            "databases." + appwriteConfig.database + ".collections." + this._targetsCollection + ".documents",
            "databases." + appwriteConfig.database + ".collections." + this._missionsCollection + ".documents",
            "databases." + appwriteConfig.database + ".collections." + this._aircraftsCollection + ".documents"
        ], (data) => {
            // Flags update
            if (data.channels.indexOf("databases." + appwriteConfig.database + ".collections." + this._flagsCollection + ".documents") != -1) {
                this._flagsSubject.next({
                    event: data.events[0].substring(data.events[0].lastIndexOf(".") + 1),
                    payload: {
                        key: data.payload["key"],
                        value: data.payload["value"]
                    }
                });
            }
            // Targets update
            if (data.channels.indexOf("databases." + appwriteConfig.database + ".collections." + this._targetsCollection + ".documents") != -1) {
                this._targetsSubject.next({
                    event: data.events[0].substring(data.events[0].lastIndexOf(".") + 1),
                    payload: data.payload
                });
            }
            // Missions update
            if (data.channels.indexOf("databases." + appwriteConfig.database + ".collections." + this._missionsCollection + ".documents") != -1) {
                const missionInt : MissionDataInterface = data.payload as MissionDataInterface;
                missionInt.id = data.payload["$id"];
                this._missionsSubject.next({
                    event: data.events[0].substring(data.events[0].lastIndexOf(".") + 1),
                    payload: missionInt
                });
            }
            // Aircrafts update
            if (data.channels.indexOf("databases." + appwriteConfig.database + ".collections." + this._aircraftsCollection + ".documents") != -1) {
                const aircraftInt : AircraftDataInterface = data.payload as AircraftDataInterface;
                this._missionsSubject.next({
                    event: data.events[0].substring(data.events[0].lastIndexOf(".") + 1),
                    payload: aircraftInt
                });
            }
        });
    }

    public getAllAircrafts(): Observable<AircraftDataInterface[]>
    {
        return new Observable<AircraftDataInterface[]>(subscriber => {
            let appWriteUnsubscription = null;
            new Promise<void>(async resolve => {
                const getAll = async () => {
                    let results = [];
                    let res : Models.DocumentList<AircraftAppwriteInterface>;
                    let offset = 0;
                    let sum = 0;
                    do {
                        res = await this._appwrite.databases.listDocuments<AircraftAppwriteInterface>(appwriteConfig.database, this._aircraftsCollection, [
                            Query.limit(100),
                            Query.offset(offset)
                        ]);
                        if (sum == 0) {
                            sum = res["total"];
                        }
                        for (let data of this.missionResultsToCollection(res)) {
                            results.push(this.dbToInterface(data, ["massAndBalance"], ["climbPerformance", "cruisePerformance", "descentPerformance", "airworkPerformance"]));
                        }
                        if (!!res["documents"]) {
                            offset += res["documents"].length;
                        }
                    } while (!!res["documents"] && results.length < sum);
                    return results;
                };
                
                const results = await getAll();
                subscriber.next(results);
    
                // On update, update the cached data
                let timeout = null;
                appWriteUnsubscription = this._aircraftsSubject.subscribe(data => {
                    const index = results.findIndex(m => m.id == data.payload.id);
                    if (index != -1) {
                        results.splice(index, 1);
                    }
                    if (data.event != "delete") {
                        results.push(this.dbToInterface(data.payload, ["massAndBalance"], ["climbPerformance", "cruisePerformance", "descentPerformance", "airworkPerformance"]));
                    }
                    if (!!timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(async () => {
                        subscriber.next(results);
                        timeout = null;
                    }, 250);
                });
                resolve();
            });

            return {
                unsubscribe: () => {
                    if (appWriteUnsubscription) {
                        appWriteUnsubscription();
                    }
                }
            }
        });
    }

    public getFlag(key: FlagId): Observable<FlagDataInterface> 
    {
        return new Observable<FlagDataInterface>(subscriber => {
            this._appwrite.databases.listDocuments(appwriteConfig.database, this._flagsCollection, [Query.equal("key", key)]).then(result => {
                if (!result["documents"].length) {
                    console.error("AppwriteStorageService: Flag " + key + " is not in the database");
                    return;
                }
                subscriber.next({
                    key: result["documents"][0]["key"],
                    value: result["documents"][0]["value"]
                });
            });
            
            const sub = this._flagsSubject.subscribe(data => {
                if (data.payload.key == key) {
                    subscriber.next(data.payload);
                }
            });

            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
    }

    public async setFlag(key: FlagId, value: string | number | boolean): Promise<void>
    {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._flagsCollection, [Query.equal("key", key)]);
        if (result["documents"].length) {
            await this._appwrite.databases.updateDocument(appwriteConfig.database, this._flagsCollection, result["documents"][0]["$id"], {
                key: key,
                value: value + ""
            }, this._auth.permissionsArray);
        }
        else {
            await this._appwrite.databases.createDocument(appwriteConfig.database, this._flagsCollection, "unique()", {
                key: key,
                value: value + ""
            }, this._auth.permissionsArray);
        }
    }

    public getAllTargets(): Observable<TargetDataInterface[]> {
        return new Observable<TargetDataInterface[]>(subscriber => {
            let sub : Subscription = null;
            new Promise<void>(async resolve => {
                // Get all the targets iterating on the DB calls
                let results = [];
                let res : Models.DocumentList<TargetAppwriteInterface>;
                let offset = 0;
                let sum = 0;
                do {
                    res = await this._appwrite.databases.listDocuments<TargetAppwriteInterface>(appwriteConfig.database, this._targetsCollection, [
                        Query.limit(100),
                        Query.offset(offset)
                    ]);
                    if (sum == 0) {
                        sum = res["total"];
                    }
                    for (let data of this.targetResultsToCollection(res)) {
                        results.push(this.dbToInterface(data, [], ["path", "notes"]));
                    }
                    if (!!res["documents"]) {
                        offset += res["documents"].length;
                    }
                } while (!!res["documents"] && results.length < sum);

                // Reports the current results
                subscriber.next(results);
    
                // On update...
                let timeout = null;
                sub = this._targetsSubject.subscribe(data => {
                    const index = results.findIndex(t => t.uuid == data.payload.uuid);
                    if (index != -1) {
                        results.splice(index, 1);
                    }
                    if (data.event != "delete") {
                        results.push(this.dbToInterface(data.payload, [], ["path", "notes"]));
                    }
                    if (!!timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(async () => {
                        subscriber.next(results);
                        timeout = null;
                    }, 250);
                });
                resolve();
            });

            return {
                unsubscribe: () => {
                    if (sub) {
                        sub.unsubscribe();
                    }
                }
            }
        });
    }
    
    public async getTarget(id: string): Promise<TargetDataInterface>
    {
        const result = await this._appwrite.databases.listDocuments<TargetAppwriteInterface>(appwriteConfig.database, this._targetsCollection, [Query.equal("uuid", id)]);
        if (result["documents"].length) {
            return this.dbToInterface(result["documents"][0], [], ["path", "notes"]);
        }
        return null;
    }

    public async saveTarget(target: TargetDataInterface): Promise<void> {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._targetsCollection, [Query.equal("uuid", target.uuid)]);
        if (result["documents"].length) {
            await this._appwrite.databases.updateDocument(appwriteConfig.database, this._targetsCollection, result["documents"][0]["$id"], this.interfaceToDb(target, [], ["path", "notes"]), this._auth.permissionsArray);
        }
        else {
            await this._appwrite.databases.createDocument(appwriteConfig.database, this._targetsCollection, "unique()", this.interfaceToDb(target, [], ["path", "notes"]), this._auth.permissionsArray);
        }
    }

    public async deleteTarget(target: TargetDataInterface): Promise<void> {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._targetsCollection, [Query.equal("uuid", target.uuid)]);
        if (result["documents"].length) {
            await this._appwrite.databases.deleteDocument(appwriteConfig.database, this._targetsCollection, result["documents"][0]["$id"]);
        }
    }
    
    public getAllMissions(): Observable<MissionDataInterface[]> {
        return new Observable(subscriber => {
            let appWriteUnsubscription = null;
            new Promise<void>(async resolve => {
                const getAll = async () => {
                    let results = [];
                    let res : Models.DocumentList<MissionAppwriteInterface>;
                    let offset = 0;
                    let sum = 0;
                    do {
                        res = await this._appwrite.databases.listDocuments<MissionAppwriteInterface>(appwriteConfig.database, this._missionsCollection, [
                            Query.limit(100),
                            Query.offset(offset)
                        ]);
                        if (sum == 0) {
                            sum = res["total"];
                        }
                        for (let data of this.missionResultsToCollection(res)) {
                            results.push(this.dbToInterface(data, ["smartbayConfig", "route", "photogrammetricData"], ["targetOptions", "massAndBalanceValues"]));
                        }
                        if (!!res["documents"]) {
                            offset += res["documents"].length;
                        }
                    } while (!!res["documents"] && results.length < sum);
                    return results;
                };
                
                const results = await getAll();
                subscriber.next(results);
    
                // On update, update the cached data
                let timeout = null;
                appWriteUnsubscription = this._missionsSubject.subscribe(data => {
                    const index = results.findIndex(m => m.id == data.payload.id);
                    if (index != -1) {
                        results.splice(index, 1);
                    }
                    if (data.event != "delete") {
                        results.push(this.dbToInterface(data.payload, ["smartbayConfig", "route", "photogrammetricData"], ["targetOptions", "massAndBalanceValues"]));
                    }
                    if (!!timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(async () => {
                        subscriber.next(results);
                        timeout = null;
                    }, 250);
                });
                resolve();
            });

            return {
                unsubscribe: () => {
                    if (appWriteUnsubscription) {
                        appWriteUnsubscription();
                    }
                }
            }
        });
    }
    
    public async saveMission(mission: MissionDataInterface): Promise<string> {
        mission = this.interfaceToDb(mission, ["smartbayConfig", "route", "photogrammetricData"], ["targetOptions", "massAndBalanceValues"]);
        try {
            if (!!mission.id) {
                await this._appwrite.databases.updateDocument(appwriteConfig.database, this._missionsCollection, mission.id, mission, this._auth.permissionsArray);
                return mission.id;
            }
            const result = await this._appwrite.databases.createDocument(appwriteConfig.database, this._missionsCollection, "unique()", mission, this._auth.permissionsArray);
            return result["$id"];
        } catch (e) {
            console.error("Cannot write mission " + mission.id, mission, e);
        }
        return null;
    }
    
    public async getMission(id: string): Promise<MissionDataInterface> {
        let res = await this._appwrite.databases.getDocument<MissionAppwriteInterface>(appwriteConfig.database, this._missionsCollection, id);
        res = this.dbToInterface(res, ["smartbayConfig", "route", "photogrammetricData"], ["targetOptions", "massAndBalanceValues"]);
        return res;
    }
    
    public async deleteMission(id: string): Promise<void> {
        await this._appwrite.databases.deleteDocument(appwriteConfig.database, this._missionsCollection, id);
    }
    
    public getAllAirports(): Observable<AirportDataInterface[]> {
        return new Observable<AirportDataInterface[]>(subscriber => {
            let appWriteUnsubscription = null;
            new Promise<void>(async resolve => {
                const getAll = async () => {
                    let results = [];
                    let res : Models.DocumentList<AirportAppwriteInterface>;
                    let offset = 0;
                    do {
                        res = await this._appwrite.databases.listDocuments<AirportAppwriteInterface>(appwriteConfig.database, this._airportsCollection, [
                            Query.limit(100),
                            Query.offset(offset)
                        ]);
                        for (let data of this.airportResultsToCollection(res)) {
                            results.push(this.dbToInterface(data, ["position"]));
                        }
                        if (!!res["documents"]) {
                            offset += res["documents"].length;
                        }
                    } while (!!res["documents"] && res["documents"].length >= 100);
                    results.sort((a : AirportDataInterface, b : AirportDataInterface) => {
                        if (a.icao == b.icao) {
                            return 0;
                        }
                        return a.icao > b.icao ? 1 : -1;
                    });
                    return results;
                };
    
                const results = await getAll();
                subscriber.next(results);
    
                // On update, read the database again
                let timeout = null;
                appWriteUnsubscription = this._appwrite.client.subscribe("databases." + appwriteConfig.database + ".collections." + this._airportsCollection + ".documents", () => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(async () => {
                        const results = await getAll();
                        subscriber.next(results);
                    }, 250);
                });

                resolve();
            });

            return {
                unsubscribe: () => {
                    if (appWriteUnsubscription) {
                        appWriteUnsubscription();
                    }
                }
            }
        });
    }
    
    public async getAirport(icao: string): Promise<AirportDataInterface> {
        const result = await this._appwrite.databases.listDocuments<AirportAppwriteInterface>(appwriteConfig.database, this._airportsCollection, [Query.equal("icao", icao)]);
        if (result["documents"].length) {
            return this.dbToInterface(result["documents"][0], ["position"]);
        }
        return null;
    }
    
    public async saveAirport(airport: AirportDataInterface): Promise<void> {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._airportsCollection, [Query.equal("icao", airport.icao)]);
        if (result["documents"].length) {
            await this._appwrite.databases.updateDocument(appwriteConfig.database, this._airportsCollection, result["documents"][0]["$id"], this.interfaceToDb(airport, ["position"]), this._auth.permissionsArray);
        }
        else {
            await this._appwrite.databases.createDocument(appwriteConfig.database, this._airportsCollection, "unique()", this.interfaceToDb(airport, ["position"]), this._auth.permissionsArray);
        }
    }
    
    public async deleteAirport(airport: AirportDataInterface): Promise<void> {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._airportsCollection, [Query.equal("icao", airport.icao)]);
        if (result["documents"].length) {
            await this._appwrite.databases.deleteDocument(appwriteConfig.database, this._airportsCollection, result["documents"][0]["$id"]);
        }
    }
    
    public getAllPilots(): Observable<PilotDataInterface[]> {
        return new Observable<PilotDataInterface[]>(subscriber => {
            let appWriteUnsubscription = null;
            new Promise<void>(async resolve => {
                const getAll = async () => {
                    let results = [];
                    let res : Models.DocumentList<PilotAppwriteInterface>;
                    let offset = 0;
                    let sum = 0;
                    do {
                        res = await this._appwrite.databases.listDocuments<PilotAppwriteInterface>(appwriteConfig.database, this._pilotsCollection, [
                            Query.limit(25),
                            Query.offset(offset)
                        ]);
                        if (sum == 0) {
                            sum = res["total"];
                        }
                        for (let data of this.pilotResultsToCollection(res)) {
                            results.push(data);
                        }
                        if (!!res["documents"]) {
                            offset += res["documents"].length;
                        }
                    } while (!!res["documents"] && results.length < sum);
                    results.sort(Mission.orderAscending);
                    return results;
                };
    
                const results = await getAll();
                subscriber.next(results);
    
                // On update, read the database again
                let timeout = null;
                appWriteUnsubscription = this._appwrite.client.subscribe("databases." + appwriteConfig.database + ".collections." + this._pilotsCollection + ".documents", () => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(async () => {
                        const results = await getAll();
                        subscriber.next(results);
                    }, 250);
                });

                resolve();
            });

            return {
                unsubscribe: () => {
                    if (appWriteUnsubscription) {
                        appWriteUnsubscription();
                    }
                }
            }
        });
    }

    private pilotResultsToCollection(results) : PilotDataInterface[]
    {
        const arr : PilotDataInterface[] = [];
        for (let document of results["documents"]) {
            arr.push(document);
        }
        return arr;
    }
    
    public async getPilot(email: string): Promise<PilotDataInterface> {
        const result = await this._appwrite.databases.listDocuments<PilotAppwriteInterface>(appwriteConfig.database, this._pilotsCollection, [Query.equal("email", email)]);
        if (result["documents"].length) {
            return result["documents"][0];
        }
        return null;
    }
    
    public async savePilot(pilot: PilotDataInterface): Promise<void> {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._pilotsCollection, [Query.equal("email", pilot.email)]);
        if (result["documents"].length) {
            await this._appwrite.databases.updateDocument(appwriteConfig.database, this._pilotsCollection, result["documents"][0]["$id"], pilot, this._auth.permissionsArray);
        }
        else {
            await this._appwrite.databases.createDocument(appwriteConfig.database, this._pilotsCollection, "unique()", pilot, this._auth.permissionsArray);
        }
    }
    
    public async deletePilot(pilot: PilotDataInterface): Promise<void> {
        const result = await this._appwrite.databases.listDocuments(appwriteConfig.database, this._pilotsCollection, [Query.equal("email", pilot.email)]);
        if (result["documents"].length) {
            await this._appwrite.databases.deleteDocument(appwriteConfig.database, this._pilotsCollection, result["documents"][0]["$id"]);
        }
    }

    /**
    * Convert the result of the database to an array of TargetDataInterface
    * 
    * @param results The results data as returned by the Appwrite database calls
    * @returns an array of target database items (not yet converted to interface)
    **/
     private targetResultsToCollection(results) : any[]
     {
         let arr : TargetDataInterface[] = [];
         for (let int of results["documents"]) {
             arr.push(int);
         }
         return arr;
     }

    /**
     * Convert the result of the database to an array of MissionDataInterface
     * 
     * @param results The results data as returned by the Appwrite database calls
     * @returns an array of airport mission items (not yet converted to interface)
     **/
    private missionResultsToCollection(results) : any[]
    {
        let arr : MissionDataInterface[] = [];
        for (let int of results["documents"]) {
            if (!int.targets) {
                int["targets"] = [];
            }
            int.id = int.$id;
            arr.push(int);
        }
        return arr;
    }

    /**
     * Convert a collection from the database to an array of airport data interface
     * 
     * @param results The database query results array
     * @returns an array of airport database items (not yet converted to interface)
     */
    private airportResultsToCollection(results) : any[]
    {
        const arr : AirportDataInterface[] = [];
        for (let document of results["documents"]) {
            arr.push(document);
        }
        return arr;
    }

    /**
     * Convert an item from the database format (child documents as string) to the interface format (child documents as object)
     * 
     * @param data A single item database data
     * @param objectKeys The keys to convert as single object
     * @param arrayKeys The keys to convert as array of string
     */
    private dbToInterface(data : any, objectKeys : string[], arrayKeys : string[] = []) : any
    {
        const copy = JSON.parse(JSON.stringify(data));
        for (let key of objectKeys) {
            if (typeof copy[key] == "string") {
                copy[key] = JSON.parse(copy[key]);
            }
        }

        for (let key of arrayKeys) {
            if (typeof copy[key] == "object") {
                const convertedData = [];
                for (let item of copy[key]) {
                    if (typeof item == "string") {
                        if (item.length > 0) {
                            convertedData.push(JSON.parse(item));
                        }
                    }
                    else {
                        convertedData.push(item);
                    }
                }
                copy[key] = convertedData;
            }
        }

        return copy;
    }

    /**
     * Convert an item from the interface format (child documents as object) to the db format (child documents as string)
     * 
     * @param data The single item data
     * @param objectKeys The keys to convert as single object
     * @param arrayKeys The keys to convert as array of string
     */
    private interfaceToDb(data : any, objectKeys : string[], arrayKeys : string[] = []) : any
    {
        const copy = JSON.parse(JSON.stringify(data));
        for (let key of objectKeys) {
            if (typeof copy[key] == "object") {
                copy[key] = JSON.stringify(copy[key]);
            }
        }

        for (let key of arrayKeys) {
            const convertedData : string[] = [];
            if (typeof copy[key] == "object") {
                for (let item of copy[key]) {
                    convertedData.push(JSON.stringify(item));
                }
                copy[key] = convertedData;
            }
        }

        return copy;
    }
}
