import { TargetVisitorInterface } from "src/app/interfaces/target-visitor-interface";
import { TargetsGroup } from "../targets-group";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { AirportWaypoint } from "../route/airport-waypoint";
import { TargetWaypoint } from "../route/target-waypoint";
import { TurnWaypoint } from "../route/turn-waypoint";
import { Route } from "../route/route";
import { TargetInterface } from "src/app/interfaces/target-interface";
import { GeoJSON } from "geojson";

import * as shpWrite from '@mapbox/shp-write';
import * as JSZip from "jszip";

export class Shapefile implements TargetVisitorInterface, WaypointVisitorInterface
{

    private _targets : TargetInterface[];

    /**
     * Convert a set of targets to an zip with SHP, DBF...
     * 
     * @param data The targets array or the Route to transform to SHP
     */
    public constructor(data : TargetInterface[] | Route)
    {
        this._targets = [];

        if ((data as TargetInterface[]).length != undefined) {
            for (let target of (data as TargetInterface[])) {
                target.acceptTargetVisitor(this);
            }
        }
        if ((data as Route).waypoints != undefined) {
            (data as Route).acceptWaypointVisitor(this);
        }
    }
    
    public visitTargetWaypoint(wp: TargetWaypoint): void
    {
        wp.target.acceptTargetVisitor(this);
    }
    
    public visitTurnWaypoint(wp: TurnWaypoint): void {}
    
    public visitAirportWaypoint(wp: AirportWaypoint): void {}

    public visitTarget(target: TargetInterface): void
    {
        this._targets.push(target);
    }

    public visitTargetGroup(target: TargetsGroup): void
    {
        for (let subt of target.targets) {
            subt.acceptTargetVisitor(this);
        }
    }

    /**
     * Save the targets to a Zip blob containing 
     * a shapefile for each target.
     * 
     * @returns A Blob containing the zipped files
     */
    public async toGranularZip() : Promise<Blob>
    {
        const zip = new JSZip();
        const data = this.toGeoJSON();

        // Create the files
        this.transform(data).map((l, index) => {
            if (l.geometries.length && l.geometries[0].length) {
                const key = l.properties[0].unicode;
                shpWrite.write(
                    // field definitions
                    l.properties,
                    // geometry type
                    l.type,
                    // geometries
                    l.geometries,
                    function (err, files) {

                        const folder = zip.folder(key);
                        folder.file(key + ".shp", files.shp.buffer);
                        folder.file(key + ".shx", files.shx.buffer);
                        folder.file(key + ".dbf", files.dbf.buffer);
                    }
                );
            }
        });

        // Generate and download
        return await zip.generateAsync({ "type": "blob" });
    }

    /**
     * Save the targets to a Zip blob containing
     * a single SHP file.
     * 
     * @return a Blob containing the zipped file
     */
    public async toMonolithicZip() : Promise<Blob>
    {
        const options : shpWrite.DownloadOptions & shpWrite.ZipOptions = {
            folder: "shapes",
            outputType: "blob",
            compression: "DEFLATE",
            types: {
                point: "points",
                polygon: "polygon",
                line: "lines",
                multipolygon: "multipolygon"
            }
        };
        return shpWrite.zip<"blob">(this.toGeoJSON(), options);
    }
    
    /**
     * Convert the contents to a TargetSHPResult
     * 
     * @return TargetSHPResult
     */
    private toGeoJSON() : GeoJSON.FeatureCollection
    {
        const gj : GeoJSON.FeatureCollection = {
            "type": "FeatureCollection",
            "features": []
        };

        for (let target of this._targets) {
            const feature : GeoJSON.Feature<GeoJSON.Polygon, {[name: string]: any; }> = {
                type: 'Feature',
                id: target.uuid,
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        []
                    ]
                },
                properties: {
                    name: target.uuid,
                    unicode: target.uuid
                }
            };
            for (let position of target.vertices) {
                feature.geometry.coordinates[0].push([position.longitude, position.latitude]);
            }
            gj.features.push(feature);
        }

        return gj;
    }

    private transform(gj) {
        return gj.features.map(feature => {
            return {
                geometries: [feature.geometry.coordinates],
                properties: [feature.properties],
                type: feature.geometry.type.toUpperCase()
            }
        });
    }
}
