import { AirportDataInterface } from "src/app/interfaces/data/airport-data-interface";
import { WaypointDataInterface } from "src/app/interfaces/data/waypoint-data-interface";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";
import { PositionInterface } from "src/app/interfaces/position-interface";
import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { AirportService } from "src/app/services/airport.service";
import { Polygon } from "../polygon";
import { Position } from "../position";
import { Waypoint } from "./waypoint";
import { WaypointType } from "./waypoint-type";

export class AirportWaypoint extends Waypoint {

    public get name(): string {
        return this._airport.icao;
    }

    public get position(): PositionInterface {
        return this._airport.position;
    }

    public get type(): WaypointType {
        return "airport";
    }

    public get delayMs(): number {
        return 0;
    }

    public get altitudeM(): number {
        return this._airport.elevationM;
    }

    public get boundarybox(): PolygonInterface {
        return this._box;
    }

    public get fixed(): boolean {
        return true;
    }

    public acceptWaypointVisitor(visitor: WaypointVisitorInterface): void {
        visitor.visitAirportWaypoint(this);
    }

    public toDataInterface(): WaypointDataInterface {
        return super.toGenericDataInterface(this._airport.icao);
    }

    private _box : PolygonInterface;

    constructor(private _airport : AirportDataInterface)
    {
        super();

        const cPosition = Position.fromInterface(this.position);
        const range = 250;
        this._box = new Polygon([
            cPosition.offset(315, range),
            cPosition.offset(45, range),
            cPosition.offset(135, range),
            cPosition.offset(225, range)
        ]);
    }

    public static async fromAirportDataInterface(wp : WaypointDataInterface, airportService : AirportService) : Promise<AirportWaypoint>
    {
        const apt = await airportService.get(wp.payload);
        return new AirportWaypoint(apt);
    }
}
