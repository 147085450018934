import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Helpers } from 'src/app/classes/helpers';
import { AuthServiceInterface, TeamInterface } from 'src/app/interfaces/auth-service-interface';
import { AppwriteService } from '../appwrite.service';
import { Permission, Role } from 'appwrite';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

/** An Auth service based on AppWrite */
export class AppwriteAuthService implements AuthServiceInterface {

    public onSignin: Subject<void>;
    public onBeforeSignout: Subject<void>;
    public signedIn: Observable<boolean>;

    /**
     * An array of permissions of this user in the appwrite format
     */
    public get permissionsArray() : string[]
    {
        if (this._teams.length) {
            const team = this._teams[0];
            return [Permission.read(Role.team(team.id)), Permission.update(Role.team(team.id)), Permission.delete(Role.team(team.id))];
        }
        if (this._userId) {
            return [Permission.read(Role.user(this._userId)), Permission.update(Role.user(this._userId)), Permission.delete(Role.user(this._userId))];
        }
        throw "Cannot build the permission array";
    }

    public get currentUserEmail(): string
    {
        return this._userEmail;
    }

    private _userId : string;
    private _userEmail : string;
    private _teams : TeamInterface[];

    constructor(
        private _appwrite : AppwriteService,
        private _translate : TranslateService
    )
    {
        this.onSignin = new Subject<void>();
        this.onBeforeSignout = new Subject<void>();
        this._teams = [];
        this.signedIn = new Observable<boolean>(subscriber => {
            Helpers.sleep(300).then(() => {
                this._appwrite.account.get().then(async account => {           
                    const teams = await this._appwrite.teams.list();
                    this._teams = [];
                    for (let team of teams.teams) {
                        this._teams.push({
                            id: team.$id,
                            name: team.name
                        });
                    }

                    this._userId = "";
                    this._appwrite.account.get().then(account => {
                        this._userId = account.$id;
                        this._userEmail = account.email;
                    });
                    
                    subscriber.next(!!account);
                }, () => {
                    subscriber.next(false);
                });
            });
            return {
                unsubscribe: () => {}
            }
        });
    }


    public getUserTeams() : TeamInterface[]
    {
        return this._teams;
    }

    public async signInWithCredential(email: string, password: string): Promise<any> {
        await this._appwrite.account.createEmailPasswordSession(email, password);
    }

    public async signOut(): Promise<any> {
        await this._appwrite.account.deleteSession('current');
    }

    public async sendPasswordRecoveryEmail(email : string): Promise<void> {
        await this._appwrite.account.createRecovery(email, environment.url + 'auth/password-recovery');
    }

    public async recoverPasswordWithSecret(password: string, userId : string, secret : string): Promise<void> {
        await this._appwrite.account.updateRecovery(
            userId,
            secret,
            password
        );
    }

    public async updatePassword(oldPassword : string, password: string, control: string): Promise<void>
    {
        try {
            await this._appwrite.account.updatePassword(password, oldPassword);
        }
        catch (e) {
            throw { message: e.message};
        }
    }

    public async validateNewPassword(password: string, control: string): Promise<void>
    {
        return new Promise<void>((resolve, reject) => {
            this._translate.get([
                "auth.passwordMismatch",
                "auth.passwordLongHint"
            ]).subscribe(values => {
                if (password != control) {
                    reject({ message: values["auth.passwordMismatch"] });
                }
                if (password.length < 8) {
                    reject({ message: values["auth.passwordLongHint"] });
                }
                resolve();
            });
        });
    }
}
