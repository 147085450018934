import { PositionInterface } from "../interfaces/position-interface";
import { SegmentInterface } from "../interfaces/segment-interface";
import { Helpers } from "./helpers";
import { Position } from "./position";

export class Segment implements SegmentInterface{

    /**
     * The heading from the starting to the ending point
     */
    public readonly heading : number;

    /**
     * The heading from the ending to the starting point
     */
    public readonly reverseHeading : number

    /**
     * The length of this segment in meters
     */
    public readonly length : number

    /**
     * The point in the middle of the segment
     */
    public readonly center : PositionInterface;

    /**
     * The segment path made of the two vertices.
     */
    public get path() : number[][]
    {
        return [
            [this.start.latitude, this.start.longitude],
            [this.end.latitude, this.end.longitude]
        ];
    }

    constructor(public readonly start : PositionInterface, public readonly end : PositionInterface)
    {
        const startP = Position.fromInterface(this.start);
        this.heading = startP.headingTo(this.end);
        this.reverseHeading = (this.heading + 180) % 360;
        this.length = startP.distanceTo(this.end);
        this.center = startP.offset(this.heading, this.length / 2);
    }

    /**
     * Get the distance in m of this segment from a point
     * not on the segment itself.
     * 
     * @param p The point
     * 
     * @returns The distance of the point from the segment
     */
    public distanceTo(p : PositionInterface) : number
    {
        const start = Position.fromInterface(this.start);
        const end = Position.fromInterface(this.end);
        const ab = start.distanceTo(p);
        const rilpo = start.rilpoTo(p, this.heading);
        if (Math.abs(rilpo) < 90 && Math.abs(end.rilpoTo(p, this.reverseHeading)) < 90) {
            // Use the triangle rule
            return Math.abs(ab * Math.sin(Helpers.degsToRads(rilpo)));
        }
        // Return the lowest distance from the start or the end
        const ac = end.distanceTo(p);
        return Math.min(ac, ab);
    }

    /**
     * 
     * @param path The array of points creating the path of this segment.
     * @throws Error if the path has the wrong format.
     * @returns 
     */
    public static fromPath(path : number[][]) : Segment
    {
        if (path.length != 2) {
            throw "Segment: invalid path length.";
        }

        if (path[0].length != 2) {
            throw "Segment: invalid start coordinates array size";
        }

        if (path[1].length != 2) {
            throw "Segment: invalid end coordinates array size";
        }

        return new Segment({
            latitude: path[0][0],
            longitude: path[0][1]
        }, {
            latitude: path[1][0],
            longitude: path[1][1]
        });
    }
}
