import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanDeactivateMissionGuard } from './pages/mission/show-mission/can-deactivate-mission.guard';
import { AuthGuard } from './services/auth/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/mission/list',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
      },
      {
        path: 'logout',
        loadChildren: () => import('./pages/auth/logout/logout.module').then(m => m.LogoutPageModule)
      },
      {
        path: 'password-recovery',
        loadChildren: () => import('./pages/auth/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
      },
      {
        path: 'then',
        redirectTo: 'dashboard/mission/list',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    children: [
      {
        path: 'mission',
        children: [
          {
            path: 'list',
            loadChildren: () => import('./pages/mission/missions-list/missions-list.module').then(m => m.MissionsListPageModule)
          },
          {
            path: ':id',
            canDeactivate: [CanDeactivateMissionGuard],
            loadChildren: () => import('./pages/mission/show-mission/show-mission.module').then(m => m.ShowMissionPageModule)
          }
        ]
      },
      {
        path: "targets",
        children: [
          {
            path: 'manage',
            loadChildren: () => import('./pages/areas/manage-targets/manage-targets.module').then(m => m.ManageTargetsPageModule)
          },
          {
            path: 'manage/:id',
            loadChildren: () => import('./pages/areas/manage-targets/manage-targets.module').then(m => m.ManageTargetsPageModule)
          }
        ]
      }
    ]
  },
  // Old redirects
  {
    path: "mission/list",
    redirectTo: "dashboard/mission/list",
    pathMatch: "prefix"
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenancePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
