import { MassAndBalanceValueDataInterface } from "src/app/interfaces/data/mass-and-balance-value-data-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { FuelMassAndBalanceStation } from "./fuel-mass-and-balance-station";
import { GenericMassAndBalanceStation } from "./generic-mass-and-balance-station";
import { MassAndBalance } from "./mass-and-balance";
import { OccupantsMassAndBalanceStation } from "./occupants-mass-and-balance-station";
import { SmartbayMassAndBalanceStation } from "./smartbay-mass-and-balance-station";

/**
 * A container of the mass and balance values set by the user in the mission.
 */
export class MassAndBalanceValues implements MassAndBalanceVisitorInterface {

    /** The mass and balance values */
    private _values : MassAndBalanceValueDataInterface[];

    /** True if the visitor is reading. False if it's writing. */
    private _reading : boolean;

    constructor()
    {
        this._values = [];
        this._reading = false;
    }

    /**
     * Build a new value set starting from a mass and balance.
     * 
     * @param mb The mass and balance from wich the values are extracted
     */
    public readFromMassAndBalance(mb : MassAndBalance) : void
    {
        this._reading = true;
        this._values = [];
        mb.acceptMassAndBalanceVisitor(this);
    }

    /**
     * Put the values of this container in the provided mass and balance.
     * 
     * @param mb The mass and balance to wich the values are set
     */
    public writeToMassAndBalance(mb : MassAndBalance) : void
    {
        this._reading = false;
        mb.acceptMassAndBalanceVisitor(this);
    }

    public visitOccupantsStation(station: OccupantsMassAndBalanceStation): void 
    {
        if (this._reading) {
            this._values.push({
                stationName: station.name,
                type: station.type,
                payload: station.count
            });
        }
        else {
            const val = this._values.find(v => v.type == station.type && v.stationName == station.name);
            if (val) {
                station.count = val.payload;
            }
        }
    }

    public visitFuelStation(station: FuelMassAndBalanceStation): void 
    {
        if (this._reading) {
            this._values.push({
                stationName: station.name,
                type: station.type,
                payload: station.mass
            });
        }
        else {
            const val = this._values.find(v => v.type == station.type && v.stationName == station.name);
            if (val) {
                station.mass = val.payload;
            }
        }
    }

    public visitGenericStation(station: GenericMassAndBalanceStation): void 
    {
        if (this._reading) {
            this._values.push({
                stationName: station.name,
                type: station.type,
                payload: station.mass
            });
        }
        else {
            const val = this._values.find(v => v.type == station.type && v.stationName == station.name);
            if (val) {
                station.mass = val.payload;
            }
        }
    }

    public visitSmartbayStation(station: SmartbayMassAndBalanceStation): void {}

    /**
     * Convert this container to a data interface suitable to be saved in the database
     */
    public toDataInterface() : MassAndBalanceValueDataInterface[]
    {
        return this._values;
    }

    /**
     * Build a new value set starting from a data interface
     * 
     * @param int The database data
     */
    public static fromDataInterface(int : MassAndBalanceValueDataInterface[]) : MassAndBalanceValues
    {
        const cont = new MassAndBalanceValues();
        cont._values = int;
        return cont;
    }
}
