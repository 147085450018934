import { Injectable } from '@angular/core';
import { Aircraft } from '../classes/aircraft';
import { AircraftDataInterface } from '../interfaces/data/aircraft-data-interface';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {

    public get aircrafts() : Promise<Aircraft[]>
    {
        return new Promise<Aircraft[]>(async resolve => {
            await this._loadingPromise;
            resolve(this._aircrafts);
        });
    }

    private _aircrafts : Aircraft[];
    private _loadingPromise : Promise<void>;

    public constructor(private _storage : StorageService)
    {
        this._aircrafts = [];
        this._loadingPromise = new Promise<void>(resolve => {
            this._storage.getAllAircrafts().subscribe(aircrafts => {
                for (let data of aircrafts) {
                    this._aircrafts.push(new Aircraft(data));
                }
                resolve();
            });
        });
    }

    /**
     * Find an aircraft given its registration.
     * 
     * @param registration The aircraft registration
     * @returns The aircraft or null if not found
     */
    public async fromRegistration(registration : string) : Promise<Aircraft>
    {
        const aircrafts = await this.aircrafts;
        for (let aircraft of aircrafts) {
            if (aircraft.registration == registration) {
                return aircraft;
            }
        }

        console.error("Cannot find aircraft " + registration);

        return null;
    }
}
