import { LegInterface } from "../interfaces/area-strategy-results-interface";
import { PositionInterface } from "../interfaces/position-interface";
import { Segment } from "./segment";

export class Leg extends Segment implements LegInterface {

    /**
     * @param start The leg starting point
     * @param end The leg ending point
     * @param altitude The leg altitude in meters
     */
    constructor(start : PositionInterface, end : PositionInterface, public readonly altitude: number)
    {
        super(start, end);
    }
}
