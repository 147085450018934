import { EventEmitter } from "@angular/core";
import { MassAndBalanceDataInterface } from "src/app/interfaces/data/mass-and-balance-data-interface";
import { MassAndBalanceAcceptorInterface } from "src/app/interfaces/mass-and-balance-acceptor-interface";
import { MassAndBalanceVisitorInterface } from "src/app/interfaces/mass-and-balance-visitor-interface";
import { PointInterface } from "src/app/interfaces/point-interface"
import { XYFunctionInterface } from "src/app/interfaces/xyfunction-interface";
import { SmartbayConfiguration } from "../smartbay-configuration/smartbay-configuration";
import { LinearInterpolation } from "../xyfunctions/linear-interpolation";
import { FuelMassAndBalanceStation } from "./fuel-mass-and-balance-station";
import { GenericMassAndBalanceStation } from "./generic-mass-and-balance-station";
import { MassAndBalanceStation } from "./mass-and-balance-station"
import { OccupantsMassAndBalanceStation } from "./occupants-mass-and-balance-station";
import { SmartbayMassAndBalanceStation } from "./smartbay-mass-and-balance-station";

export class MassAndBalance implements MassAndBalanceAcceptorInterface {

    /**
     * The total moment of this M&B in kg*m.
     */
    public get rampMoment() : number
    {
        let moment = this.emptyArm * this.emptyMass;
        for (let station of this.stations) {
            moment += station.moment;
        }
        return moment;
    }
    
    /**
     * The total arm of this M&B in m.
     */
    public get rampArm() : number
    {
        let mass = this.emptyMass;
        let moment = this.emptyArm * this.emptyMass;
        for (let station of this.stations) {
            mass += station.mass;
            moment += station.moment;
        }
        return moment / mass;
    }

    /**
     * The ramp mass of this M&B in kg.
     */
    public get rampMass() : number
    {
        let mass = this.emptyMass;
        for (let station of this.stations) {
            mass += station.mass;
        }
        return mass;
    }

    /**
     * The zero fuel mass in Kg.
     */
    public get zeroFuelMass() : number
    {
        return this.rampMass - this._fuelMass;
    }


    /**
     * The zero fuel mass arm in m.
     */
    public get zeroFuelArm() : number
    {
        return (this.rampMoment - this._fuelMoment) / this.zeroFuelMass;
    }

    /**
     * True if this mass and balance is valid for flight
     */
    public get isValid() : boolean
    {
        return !this.rampArmOutOfLimitations && !this.rampMassOutOfLimitations;
    }

    /**
     * True if the ramp arm is out of the mass and balance envelope.
     */
    public get rampArmOutOfLimitations() : boolean
    {
        const cg = this.rampArm;
        return cg < this.minArm || cg > this.maxArm;
    }

    /**
     * True if the ramp mass is out of the mass and balance envelope.
     */
    public get rampMassOutOfLimitations() : boolean
    {
        const mass = this.rampMass;
        const cg = this.rampArm;
        return this._massFunc.getYValue(cg) < mass;
    }

    /**
     * Get the maximum usable fuel for this airplane in Kg
     */
    public get maxUsableFuelKg() : number
    {
        let mass = 0;
        this.acceptMassAndBalanceVisitor({
            visitOccupantsStation(station : OccupantsMassAndBalanceStation) : void {},
            visitFuelStation(station : FuelMassAndBalanceStation) : void {
                mass += station.maxLoadKg;
            },
            visitGenericStation(station : GenericMassAndBalanceStation) : void {},
            visitSmartbayStation(station : SmartbayMassAndBalanceStation) : void {}
        });
        return mass;
    }

    public get emptyMoment() : number
    {
        return this.emptyArm * this.emptyMass;
    }

    /**
     * Fired when the data contained in this mass and balance is changed
     */
    public onChange : EventEmitter<void>;

    /**
     * The minimum arm in the mass and balance envelope in meters.
     */
    public readonly minArm : number;

    /**
     * The maximum arm in the mass and balance envelope in meters.
     */
    public readonly maxArm : number;

    /**
     * The maximum takeoff mass in kg.
     */
    public readonly maxRampMass : number;

    private _massFunc : XYFunctionInterface;
    private _fuelMass : number;
    private _fuelMoment : number;

    /**
     * 
     * @param envelope The allowed CG envelope as a sequence of points where x is the arm and y the weight
     * @param emptyMass The airplane BEM in kg
     * @param emptyArm The airplane empty weight CG in m
     * @param stations A list of mass and balance stations
     */
    constructor(
        public readonly envelope : PointInterface[],
        public readonly emptyMass : number,
        public readonly emptyArm: number,
        public readonly stations : MassAndBalanceStation[])
    {
        this.minArm = Number.MAX_SAFE_INTEGER;
        this.maxArm = Number.MIN_SAFE_INTEGER;
        this.maxRampMass = Number.MIN_SAFE_INTEGER;
        this._fuelMass = 0;
        this._fuelMoment = 0;
        this.onChange = new EventEmitter<void>();

        for (let point of envelope) {
            if (point.x > this.maxArm) {
                this.maxArm = point.x;
            }
            if (point.x < this.minArm) {
                this.minArm = point.x;
            }
            if (point.y > this.maxRampMass) {
                this.maxRampMass = point.y;
            }
        }

        for (let station of stations) {
            station.onChange.subscribe(() => {
                this.updateFuelData();
                this.onChange.emit();
            });
        }

        this._massFunc = new LinearInterpolation(envelope);
        this.updateFuelData();
    }

    public acceptMassAndBalanceVisitor(visitor: MassAndBalanceVisitorInterface) {
        for (let station of this.stations) {
            station.acceptMassAndBalanceVisitor(visitor);
        }
    }

    public clone() : MassAndBalance
    {
        const stations : MassAndBalanceStation[] = [];
        for (let station of this.stations) {
            stations.push(station.clone());
        }
        return new MassAndBalance(this.envelope, this.emptyMass, this.emptyArm, stations);
    }

    private updateFuelData()
    {
        const me = this;
        this._fuelMass = 0;
        this._fuelMoment = 0;
        this.acceptMassAndBalanceVisitor({
            visitOccupantsStation: function (station: OccupantsMassAndBalanceStation): void {},
            visitFuelStation: function (station: FuelMassAndBalanceStation): void {
                me._fuelMass += station.mass;
                me._fuelMoment += station.moment;
            },
            visitGenericStation: function (station: GenericMassAndBalanceStation): void {},
            visitSmartbayStation: function (station: SmartbayMassAndBalanceStation): void {}
        });
    }

    public static fromDataInterface(int : MassAndBalanceDataInterface, smartbayConfig : SmartbayConfiguration) : MassAndBalance
    {
        const stations : MassAndBalanceStation[] = [];

        for (let stationInt of int.stations) {
            switch (stationInt.type) {
                case "fuel":
                    stations.push(FuelMassAndBalanceStation.fromDataInterface(stationInt));
                    break;
                case "generic":
                    stations.push(GenericMassAndBalanceStation.fromDataInterface(stationInt));
                    break;
                case "occupants":
                    stations.push(OccupantsMassAndBalanceStation.fromDataInterface(stationInt));
                    break;
                case "smartbay":
                    stations.push(SmartbayMassAndBalanceStation.fromDataInterface(stationInt, smartbayConfig));
                    break;
            }
        }

        return new MassAndBalance(int.envelope, int.basicEmptyMass, int.basicEmptyArm, stations);
    }
}
