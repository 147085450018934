import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AsyncTranslateService {

    constructor(private _translateService : TranslateService) {}

    public get(key : string | string[], interpolateParams : Object = null) : Promise<any>
    {
        return new Promise<any>(resolve => {
            this._translateService.get(key, interpolateParams).subscribe(l10n => {
                resolve(l10n);
            });
        });
    }

    public getDefaultLang() : string
    {
        return this._translateService.getDefaultLang();
    }
}
