import { AreaStrategyResultsInterface } from "../interfaces/area-strategy-results-interface";
import { PhotogrammetryStrategyInterface } from "../interfaces/photogrammetry-strategy-interface";
import { WaypointVisitorInterface } from "../interfaces/waypoint-visitor-interface";
import { GroundElevationService } from "../services/ground-elevation.service";
import { Mission } from "./mission";
import { ParallelLegsStrategy } from "./photogrammetry-strategies/parallel-legs-strategy";
import { ManualLegsStrategy } from "./photogrammetry-strategies/manual-legs-strategy";
import { AirportWaypoint } from "./route/airport-waypoint";
import { TargetWaypoint } from "./route/target-waypoint";
import { TurnWaypoint } from "./route/turn-waypoint";
import { TranslatedGenericError } from "./errors/translated-generic-error";

/**
 * Build the photogrammetry data of a specific mission.
 * This class uses @class BasePhotogrammetryStrategy to build
 * the photogrammetric data of each area in the mission.
 */
export class MissionPhotogrammetryDataBuilder implements WaypointVisitorInterface {

    private _targetWaypoints : TargetWaypoint[];

    constructor(private _elevationService? : GroundElevationService)
    {
        this._targetWaypoints = [];
    }

    /**
     * @param mission The mission to build
     * @returns True if the mission can be properly built
     */
    public canBuild(mission : Mission) : boolean
    {
        const strat = this.getStrategies(mission);
        for (let uid in strat) {
            if (!strat[uid].isValid) {
                return false;
            }
        }
        return true;
    }

    /**
     * Build the photogrammetric data of a specific mission.
     * 
     * @param mission The mission to build
     * @param progressCallback A progress callback used to provide updates on the building process.
     *                         The callback receives progress (0 -> 1) parameter and a labelId that identifies the localization to show
     * @returns The built photogrammetric data
     */
    public async build(mission : Mission, progressCallback : (progress : number, labelId : string) => Promise<void> = null)
        : Promise<{"strategies": { (key : string) : PhotogrammetryStrategyInterface } | {}, "legsData" : { (key : string) : AreaStrategyResultsInterface } | {}}>
    {
        // 1. Build the strategies
        const strategies = this.getStrategies(mission);
        const strat : PhotogrammetryStrategyInterface[] = [];

        for (let uid in strategies) {
            strat.push(strategies[uid]);
        }

        // 2. Actually build the legs
        let totalProgress = 0;
        const results : { (key : string) : AreaStrategyResultsInterface } | {} = {};
        for (let uuid in strategies) {
            let partialProgress = 0;
            results[uuid] = await strategies[uuid].generate(async (progress : number) => {
                partialProgress = totalProgress + progress;
                if (!!progressCallback) {
                    await progressCallback((partialProgress / strat.length), "mission.calculatingLegs");
                }
            });

            if (results[uuid].legs.length == 0) {
                throw new TranslatedGenericError("leg.warning.cannotCalculateSolution", { "uuid": uuid });
            }
            totalProgress = partialProgress;
        }

        // 4. Update the waypoint data
        for (let wp of this._targetWaypoints) {
            wp.delayMs = results[wp.target.uuid].sensorsData[0].overflyTime;
            wp.altitudeM = results[wp.target.uuid].sensorsData[0].altitude;
        }

        return {
            "legsData": results,
            "strategies": strategies
        };
    }

    public visitTargetWaypoint(wp: TargetWaypoint): void
    {
        this._targetWaypoints.push(wp);
    }

    public visitTurnWaypoint(wp: TurnWaypoint): void {}
    public visitAirportWaypoint(wp : AirportWaypoint) : void {}

    /**
     * 
     * @param mission The mission for wich the strategies are built for
     * @returns An object as target UUID => strategy
     */
    private getStrategies(mission : Mission) : { (key : string) : PhotogrammetryStrategyInterface } | {}
    {
        this._targetWaypoints = [];
        const strategies : { (key : string) : PhotogrammetryStrategyInterface } | {} = {};

        // Get the necessary targets
        mission.route.acceptWaypointVisitor(this);
        let strat : PhotogrammetryStrategyInterface[] = [];
        for (let wp of this._targetWaypoints) {
            const targetOptions = mission.getTargetOptions(wp.target);
            let strategy : PhotogrammetryStrategyInterface;
            
            switch (targetOptions.strategyType) {
                case "manual-legs": 
                    strategy = new ManualLegsStrategy(wp.target, mission.smartBayConfig, targetOptions, mission.aircraft, this._elevationService);
                    break;
                default: 
                    strategy = new ParallelLegsStrategy(wp.target, mission.smartBayConfig, mission.latDeviation, targetOptions, mission.aircraft, this._elevationService);
                    break;
            }
            
            strategies[wp.target.uuid] = strategy;
            strat.push(strategy);
        }

        return strategies;
    }

}
