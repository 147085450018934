import { EventEmitter } from "@angular/core";
import { CustomLegDataInterface, LegsSpacingType, PhotogrammetryStrategyType, TargetOptionsDataInterface } from "../interfaces/data/target-options-data-interface";
import { TargetService } from "../services/target.service";
import { TranslatedGenericError } from "./errors/translated-generic-error";

export class TargetOptions {

    /** The desired max GSD on the target */
    public get gsd() : number
    {
        return this._gsd;
    }

    /** The desired max GSD on the target */
    public set gsd(v : number)
    {
        this._gsd = v;
        this._activeLegs = [];
        this.onChange.emit();
    }

    /** The desired sidelap on the target */
    public get sidelap() : number
    {
        return this._sidelap;
    }

    /** The desired sidelap on the target */
    public set sidelap(v : number)
    {
        this._sidelap = v;
        this._activeLegs = [];
        this.onChange.emit();
    }

    /** The desired overlap on the target */
    public get overlap() : number
    {
        return this._overlap;
    }

    /** The desired overlap on the target */
    public set overlap(v : number)
    {
        this._overlap = v;
        this._activeLegs = [];
        this.onChange.emit();
    }

    public get primarySensorIndex(): number
    {
        return this._primarySensorIndex;
    }

    public set primarySensorIndex(v : number)
    {
        this._primarySensorIndex = v;
        this._activeLegs = [];
        this.onChange.emit();
    }

    public get activeSensorsIndexes(): number[]
    {
        return this._activeSensorsIndexes;
    }

    public set activeSensorsIndexes(v : number[])
    {
        this._activeSensorsIndexes = v;
        this._activeLegs = [];
        this.onChange.emit();
    }

    /** Heading offset for the target */
    public get headingOffset() : number
    {
        return this._headingOffset;
    }

    /** Heading offset for the target */
    public set headingOffset(v : number)
    {
        this._headingOffset = v;
        this._activeLegs = [];
        this.onChange.emit();
    }

    /**
     * Get the used legs spacing type
     */
    public get legsSpacingType() : LegsSpacingType
    {
        return this._legsSpacingType;
    }

    /**
     * Set the legs spacing type
     */
    public set legsSpacingType(l : LegsSpacingType)
    {
        if (l != this._legsSpacingType) {
            this._legsSpacingType = l;
            this._activeLegs = [];
            this.onChange.emit();
        }
    }

    /**
     * An array with the indexes of the active legs.
     * If empty, all legs are active.
     * 
     * This property is reset if other properties
     * that affect the legs count change.
     */
    public get activeLegs() : number[]
    {
        return this._activeLegs;
    }

    public set activeLegs(array : number[]) 
    {
        this._activeLegs = array;
        this.onChange.emit();
    }

    /**
     * The custom legs manually created for this target.
     * These legs are used instead of the automatic ones
     * if the useCustomLegs attribute is set to true.
     */
    public get customLegs() : CustomLegDataInterface[]
    {
        return this._customLegs;
    }

    /**
     * The custom legs manually created for this target.
     * These legs are used instead of the automatic ones
     * if the useCustomLegs attribute is set to true.
     */
    public set customLegs(legs : CustomLegDataInterface[])
    {
        this._customLegs = legs;
        this.onChange.emit();
    }

    /**
     * The photogrammetric strategy type used by this target
     */
    public get strategyType() : PhotogrammetryStrategyType
    {
        return this._strategyType;
    }

    /**
     * The photogrammetric strategy type used by this target
     */
    public set strategyType(type : PhotogrammetryStrategyType)
    {
        this._strategyType = type;
        this.onChange.emit();
    }

    /**
     * True to draw the legs in the Skydemon flight plan
     */
    public set drawLegsOnFlightPlan(draw : boolean)
    {
        this._drawLegsInFPL = draw;
        this.onChange.emit();
    }

    /**
     * True to draw the legs in the Skydemon flight plan
     */
    public get drawLegsOnFlightPlan() : boolean
    {
        return this._drawLegsInFPL;
    }

    /**
     * True to use the manual elevations.
     */
    public set useManualElevations(use : boolean)
    {
        this._useManualElevations = use;
        this.onChange.emit();
    }

    public get useManualElevations() : boolean
    {
        return this._useManualElevations;
    }

    /**
     * Set the minimum manual elevation to use for
     * this target (meters)
     */
    public set minManualElevation(elevationM : number)
    {
        this._minManualElevation = elevationM;
        this.onChange.emit();
    }

    public get minManualElevation() : number
    {
        return this._minManualElevation;
    }

    /**
     * Set the maximum manual elevation to use
     * for this target (meters)
     */
    public set maxManualElevation(elevationM : number)
    {
        this._maxManualElevation = elevationM;
        this.onChange.emit();
    }

    public get maxManualElevation() : number
    {
        return this._maxManualElevation;
    }

    public onChange : EventEmitter<void>;

    private _gsd : number;
    private _sidelap : number;
    private _overlap : number;
    private _primarySensorIndex: number;
    private _activeSensorsIndexes: number[];
    private _legsSpacingType : LegsSpacingType;
    private _headingOffset : number;
    private _activeLegs : number[];
    private _drawLegsInFPL : boolean;
    private _customLegs : CustomLegDataInterface[];
    private _strategyType : PhotogrammetryStrategyType;
    private _useManualElevations : boolean;
    private _minManualElevation : number;
    private _maxManualElevation : number;

    /**
     * True if this options use a custom sensors configuration
     */
    public get hasCustomSensorsConfig() : boolean
    {
        return this._primarySensorIndex > -1 || this._activeSensorsIndexes.length > 0;
    }

    constructor(public targetId : string)
    {
        this._strategyType = "basic-dsa";
        this._legsSpacingType = "fixed-spacing";
        this._gsd = 7.5;
        this._primarySensorIndex = -1;
        this._activeSensorsIndexes = [];
        this._overlap = 0.7;
        this._sidelap = 0.2;
        this._headingOffset = 0;
        this._drawLegsInFPL = false;
        this._activeLegs = [];
        this._customLegs = [];
        this._useManualElevations = false;
        this._minManualElevation = 0;
        this._maxManualElevation = 0;
        this.onChange = new EventEmitter<void>();
    }

    public clone() : TargetOptions
    {
        const options = TargetOptions.fromDataInterface(this.toDataInterface());
        options._activeSensorsIndexes = [];
        for (let index of this._activeSensorsIndexes) {
            options._activeSensorsIndexes.push(index);
        }
        options._customLegs = [];
        for (let customLeg of this._customLegs) {
            options._customLegs.push({
                start: customLeg.start,
                end: customLeg.end,
                initialAltitude: customLeg.initialAltitude,
                finalAltitude: customLeg.finalAltitude
            });
        }
        return options;
    }

    public toDataInterface() : TargetOptionsDataInterface
    {
        return {
            targetId: this.targetId, 
            strategyType: this._strategyType,
            gsd: this.gsd,
            primarySensorIndex : this.primarySensorIndex,
            activeSensorsIndexes: this.activeSensorsIndexes,
            overlap: this.overlap,
            sidelap: this.sidelap,
            headingOffset: this.headingOffset,
            activeLegs: this.activeLegs,
            legsSpacingType: this._legsSpacingType,
            customLegs: this._customLegs,
            drawLegsInFPL: this._drawLegsInFPL,
            useManualElevation: this._useManualElevations,
            minManualElevation: this._minManualElevation,
            maxManualElevation: this._maxManualElevation
        };
    }

    /**
     * Populate the custom legs array using the provided CSV text.
     * 
     * The CSV must contain the following columns:
     * - "start latitude"
     * - "start longitude"
     * - "start altitude"
     * - "stop latitude"
     * - "stop longitude"
     * - "stop altitude"
     * 
     * The numbers must use the point as decimal separator
     * The columns must be separated by a comma
     * 
     * @param csv The CSV legs data
     * @throw TranslatedGenericError
     */
    public populateCustomLegsFromCSV(csv : string) : void
    {
        const lines = csv.split("\n");
        let initialLatIndex = -1;
        let initialLonIndex = -1;
        let initialAltIndex = -1;
        let finalLatIndex = -1;
        let finalLonIndex = -1;
        let finalAltIndex = -1;

        for (let i = 0; i < lines.length; i++) {

            // Skip last empty line
            if (i == lines.length - 1 && lines[i] == "") {
                continue;
            }

            const columns = lines[i].split(/[;,]/);
            if (!columns.length || columns.length < 2) {
                throw new TranslatedGenericError("mission.csvHeaderFormatError");
            }

            // Identify the header indexes
            if (i == 0) {
                for (let c = 0; c < columns.length; c++) {
                    switch (columns[c].trim().toLowerCase()) {
                        case "start latitude": initialLatIndex = c; break;
                        case "start longitude": initialLonIndex = c; break;
                        case "stop latitude": finalLatIndex = c; break;
                        case "stop longitude": finalLonIndex = c; break;
                        case "start altitude": initialAltIndex = c; break;
                        case "stop altitude": finalAltIndex = c; break;
                    }
                }

                // Ensure all the columns are present
                if (initialLatIndex == -1) {
                    throw new TranslatedGenericError("mission.csvMissingColumn", {"column": "start latitude"});
                }
                if (initialLonIndex == -1) {
                    throw new TranslatedGenericError("mission.csvMissingColumn", {"column": "start longitude"});
                }
                if (initialAltIndex == -1) {
                    throw new TranslatedGenericError("mission.csvMissingColumn", {"column": "start altitude"});
                }
                if (finalLatIndex == -1) {
                    throw new TranslatedGenericError("mission.csvMissingColumn", {"column": "stop latitude"});
                }
                if (finalLonIndex == -1) {
                    throw new TranslatedGenericError("mission.csvMissingColumn", {"column": "stop longitude"});
                }
                if (finalAltIndex == -1) {
                    throw new TranslatedGenericError("mission.csvMissingColumn", {"column": "stop altitude"});
                }

                this.customLegs = [];
                
                continue;
            }

            if (columns.length < 6) {
                throw new TranslatedGenericError("mission.csvInvalidColumnsCount", {"line": i});
            }

            // Push the legs
            this.customLegs.push({
                start: {
                    latitude: parseFloat(columns[initialLatIndex]),
                    longitude: parseFloat(columns[initialLonIndex])
                },
                end: {
                    latitude: parseFloat(columns[finalLatIndex]),
                    longitude: parseFloat(columns[finalLonIndex])
                },
                initialAltitude: parseFloat(columns[initialAltIndex]),
                finalAltitude: parseFloat(columns[finalAltIndex])
            });
        }
    }

    public static fromDataInterface(int : TargetOptionsDataInterface) : TargetOptions
    {
        let options = new TargetOptions(TargetService.cleanTargetId(int.targetId));
        
        // Some values have been stored as string in the database because of wrong UI
        // $event.detail.value type. Let's convert them back to numbers if needed.
        
        options._gsd = typeof int.gsd == "string" ? parseFloat(int.gsd) : int.gsd;
        options._sidelap = typeof int.sidelap == "string" ? parseFloat(int.sidelap) : int.sidelap;
        options._overlap = typeof int.overlap == "string" ? parseFloat(int.overlap) : int.overlap;
        //options._inversionTime = int.inversionTime;
        options._primarySensorIndex = int.primarySensorIndex ?? -1;
        options._activeSensorsIndexes = int.activeSensorsIndexes ?? [];
        options._headingOffset = typeof int.headingOffset == "string" ? parseFloat(int.headingOffset) : int.headingOffset;
        options._activeLegs = int.activeLegs ?? [];
        if (!!int.legsSpacingType) {
            options._legsSpacingType = int.legsSpacingType;
        }
        else {
            options._legsSpacingType = int.dynamicLegs ? "dynamic-hor" : "fixed-spacing";
        }
        options._strategyType = !!int.strategyType ? int.strategyType : "basic-dsa";
        options._drawLegsInFPL = !!int.drawLegsInFPL;
        if (!!int.customLegs && Array.isArray(int.customLegs)) {
            options._customLegs = int.customLegs;
        }
        // Valid only if the key is actually there
        if (int.useManualElevation === false || int.useManualElevation === true) {
            options._useManualElevations = int.useManualElevation;
            options._minManualElevation = int.minManualElevation;
            options._maxManualElevation = int.maxManualElevation;
        }

        return options;
    }
}
