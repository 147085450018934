import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PilotDataInterface } from '../interfaces/data/pilot-data-interface';
import { StorageService } from './storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class PilotService {

    private _loadingPromise : Promise<void>
    private _pilots : PilotDataInterface[];

    constructor(private _storage : StorageService)
    {
        this._pilots = [];
        this._loadingPromise = new Promise(async resolve => {
            // Put some test data
            if (!environment.production) {
                await this.save({
                    email: "claudio.nicolotti@gmail.com",
                    name: "Claudio",
                    surname: "Nicolotti",
                    langId: "it"
                });
                await this.save({
                    email: "ardix89@gmail.com",
                    name: "Diego",
                    surname: "Ardissone",
                    langId: "it"
                });
            }
            this._storage.getAllPilots().subscribe(pilots => {
                this._pilots = pilots;
                resolve();
            });
        });
    }

    public getAll() : Promise<PilotDataInterface[]>
    {
        return new Promise<PilotDataInterface[]>(async resolve => {
            await this._loadingPromise;
            resolve(this._pilots);
        });
    }

    public async get(email : string) : Promise<PilotDataInterface>
    {
        await this._loadingPromise;
        const pilot = this._pilots.find(p => p.email == email);
        return pilot;
    }

    public async save(pilot : PilotDataInterface) : Promise<void>
    {
        await this._storage.savePilot(pilot);
    }
}
