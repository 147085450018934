import { WaypointVisitorInterface } from "src/app/interfaces/waypoint-visitor-interface";
import { Helpers } from "../helpers";
import { AirportWaypoint } from "../route/airport-waypoint";
import { TargetWaypoint } from "../route/target-waypoint";
import { TurnWaypoint } from "../route/turn-waypoint";
import { FlowStatus } from "../flow-status";

/**
 * @class MinTargetStatusVisitor
 * 
 * Given a set of waypoints, find the lowest TargetFlowStatus of the
 * targets set.
 */
export class MinStatusWaypointVisitor implements WaypointVisitorInterface {

    /** The minimum flow status obtained by sweeping on the collection */
    public get minFlowStatus() : FlowStatus
    {
        return this._minFlowStatus;
    }

    private _minFlowStatus : FlowStatus;

    constructor()
    {
        this.reset();
    }

    /**
     * Reset this visitor so it can be reused
     */
    public reset()
    {
        this._minFlowStatus = FlowStatus.Validated;
    }

    public visitTargetWaypoint(wp: TargetWaypoint): void
    {
        if (wp.target.flowStatus.compareTo(this._minFlowStatus) == -1) {
            this._minFlowStatus = wp.target.flowStatus;
        }
    }

    public visitTurnWaypoint(wp: TurnWaypoint): void
    {
        //
    }

    public visitAirportWaypoint(wp: AirportWaypoint): void 
    {
        //
    }
}
