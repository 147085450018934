import { AircraftDataInterface } from "../interfaces/data/aircraft-data-interface";
import { PerformanceDataInterface } from "../interfaces/data/performance-data-interface";

export class AircraftPerformance {
    
    constructor(private _dataInterface : AircraftDataInterface) {

    }

    /**
     * Get the taxi fuel
     * 
     * @returns The taxi fuel in Kg
     */
    public getTaxiFuel() : number
    {
        return this._dataInterface.taxiFuelKg;
    }

    /**
     * Get the airwork speed in meters per second
     * 
     * @param altitude The airwork altitude in meters
     */
    public getAirworkSpeed(altitude : number) : number
    {
        const perf = this.getPerformanceAtAltitude(this._dataInterface.airworkPerformance, altitude);
        return perf.speed;
    }

    /**
     * Get the airwork fuel flow
     * 
     * @param altitude The airwork altitude in meters
     * @returns The fuel flow at the given altitude in Kg/hr
     */
    public getAirworkFuelFlow(altitude : number) : number
    {
        const perf = this.getPerformanceAtAltitude(this._dataInterface.airworkPerformance, altitude);
        return perf.fuelFlow;
    }

    /**
     * Get the holding fuel flow
     * 
     * @param altitude The holding altitude in meters
     * @returns The fuel flow at the given altitude in Kg/hr
     */
    public getHoldingFuelFlow(altitude : number) : number
    {
        const perf = this.getPerformanceAtAltitude(this._dataInterface.cruisePerformance, altitude);
        return perf.fuelFlow;
    }

    /**
     * Get the cruise fuel flow 
     * 
     * @param altitude The altitde in meters
     * @returns The fuel flow in kg/hr
     */
    public getCruiseFuelFlow(altitude : number) : number
    {
        const perf = this.getPerformanceAtAltitude(this._dataInterface.cruisePerformance, altitude);
        return perf.fuelFlow;
    }

    /**
     * Get the climb distance in meters
     * 
     * @param initialAltitude The initial altitude in meters
     * @param finalAltitude The final altitude in meters
     * @returns The distance required to climb from initial to final altitude in meters
     */
    public getClimbDistance(initialAltitude : number, finalAltitude : number) : number
    {   
        if (initialAltitude > finalAltitude) {
            throw "Invalid altitudes";
        }

        const altitude = initialAltitude + (finalAltitude - initialAltitude) * 2 / 3;
        const perf = this.getPerformanceAtAltitude(this._dataInterface.climbPerformance, altitude);
        const time = (finalAltitude - initialAltitude) / Math.abs(perf.verticalSpeed);
        return time * perf.speed;
    }

    /**
     * Get the climb fuel in Kg
     * 
     * @param initialAltitude The initial altitude in meters
     * @param finalAltitude The final altitude in meters
     * @returns The fuel required to climb from initial to final altitude in Kg
     */
    public getClimbFuel(initialAltitude : number, finalAltitude : number) : number
    {
        if (initialAltitude > finalAltitude) {
            throw "Invalid altitudes";
        }

        const altitude = initialAltitude + (finalAltitude - initialAltitude) * 2 / 3;
        const perf = this.getPerformanceAtAltitude(this._dataInterface.climbPerformance, altitude);
        const time = (finalAltitude - initialAltitude) / Math.abs(perf.verticalSpeed);
        return time * perf.fuelFlow / 3600;
    }

    /**
     * Get the climb time in milliseconds
     * 
     * @param initialAltitude Initial altitude in meters
     * @param finalAltitude Final altitude in meters
     * @returns The time required to climb from initial to final altitude in seconds
     */
    public getClimbTime(initialAltitude : number, finalAltitude : number) : number
    {
        if (initialAltitude > finalAltitude) {
            throw "Invalid altitudes";
        }

        const altitude = initialAltitude + (finalAltitude - initialAltitude) * 2 / 3;
        const perf = this.getPerformanceAtAltitude(this._dataInterface.climbPerformance, altitude);
        return (finalAltitude - initialAltitude) / Math.abs(perf.verticalSpeed) * 1000;
    }

    /**
     * Get the cruise time given a distance and an altitude
     * 
     * @param distance The cruise distance in meters
     * @param altitude The cruise altitude in meters
     * @returns The cruise time in milliseconds
     */
    public getCruiseTime(distance : number, altitude : number) : number
    {
        const perf = this.getPerformanceAtAltitude(this._dataInterface.cruisePerformance, altitude);
        return distance / perf.speed * 1000;
    }

    /**
     * 
     * @param distance The distance in meters
     * @param altitude The cruise altitude in meters
     * @returns The fuel required for the cruise in Kg
     */
    public getCruiseFuel(distance: number, altitude: number) : number
    {
        const perf = this.getPerformanceAtAltitude(this._dataInterface.cruisePerformance, altitude);
        return (distance / perf.speed) * perf.fuelFlow / 3600;
    }

    /**
     * Calculate the distance needed to descend from initial altitude to final altitude.
     * 
     * @param initialAltitude Initial altitude in meters
     * @param finalAltitude Final altitude in meters
     * @returns The distance required to descend from initial altitude to final altitude in meters
     */
    public getDescentDistance(initialAltitude : number, finalAltitude : number) : number
    {
        if (initialAltitude < finalAltitude) {
            throw "Invalid altitudes";
        }

        const altitude = (initialAltitude + finalAltitude) / 2;
        const perf = this.getPerformanceAtAltitude(this._dataInterface.descentPerformance, altitude);
        const time = (initialAltitude - finalAltitude) / Math.abs(perf.verticalSpeed);
        return time * perf.speed;
    }

    /**
     * Calculate the descent fuel
     * 
     * @param initialAltitude The initial altitude in meters
     * @param finalAltitude The final altitude in meters
     * @returns The fuel required to descend from inital altitude to final altitude in Kg
     */
    public getDescentFuel(initialAltitude : number, finalAltitude : number) : number
    {
        if (initialAltitude < finalAltitude) {
            throw "Invalid altitudes";
        }

        const altitude = (initialAltitude + finalAltitude) / 2;
        const perf = this.getPerformanceAtAltitude(this._dataInterface.descentPerformance, altitude);
        const time = (initialAltitude - finalAltitude) / Math.abs(perf.verticalSpeed);
        return time * perf.fuelFlow / 3600;
    }

    /**
     * Calculate the descent time
     * 
     * @param initialAltitude The initial altitude in meters
     * @param finalAltitude The final altitude in meters
     * @returns The time needed to descend from initial altitude to final altitude in milliseconds
     */
    public getDescentTime(initialAltitude : number, finalAltitude : number) : number
    {
        if (initialAltitude < finalAltitude) {
            throw "Invalid altitudes";
        }

        const altitude = (initialAltitude + finalAltitude) / 2;
        const perf = this.getPerformanceAtAltitude(this._dataInterface.descentPerformance, altitude);
        return (initialAltitude - finalAltitude) / Math.abs(perf.verticalSpeed) * 1000;
    }

    /**
     * Calculate the performance at a specific altitude given a set of performance.
     * 
     * @param performance The array of performance data
     * @param altitude The altitude to which the performance is calculated 
     */
    private getPerformanceAtAltitude(performance : PerformanceDataInterface[], altitude : number) : PerformanceDataInterface
    {
        if (performance.length == 1) {
            return performance[0];
        }
        
        // Sort the performance array
        performance.sort((a, b) => {
            if (a.level < b.level) {
                return -1;
            }
            if (a.level > b.level) {
                return 1;
            }
            return 0;
        });

        if (performance[0].level >= altitude) {
            return performance[0];
        }

        if (performance[performance.length - 1].level <= altitude) {
            return performance[performance.length - 1];
        }

        for (let i = 0; i < performance.length - 1; i++) {
            if (performance[i].level < altitude && performance[i + 1].level > altitude) {
                const ratio = (altitude - performance[i].level) / (performance[i + 1].level - performance[i].level);
                return {
                    fuelFlow: performance[i].fuelFlow + (performance[i + 1].fuelFlow - performance[i].fuelFlow) * ratio,
                    level: altitude,
                    speed: performance[i].speed + (performance[i + 1].speed - performance[i].speed) * ratio,
                    verticalSpeed: performance[i].verticalSpeed + (performance[i + 1].verticalSpeed - performance[i].verticalSpeed) * ratio
                }
            }
        }

        return null;
    }
}
