import { Injectable } from '@angular/core';
import { Databases, Client, Account, Teams, Query } from "appwrite";
import { Observable, Subject } from 'rxjs';
import { appwriteConfig } from 'src/environments/environment';

@Injectable({
providedIn: 'root'
})
export class AppwriteService {

	/** Get the app write instance */
	public get databases() : Databases
	{
		if (this._databases == null) {
			this._databases = new Databases(this.client);
		}
		return this._databases;
	}

	/**
	 * An observable that returns true if the Appwrite service is currently online and available
	 */
	public get serviceIsAvailable() : Observable<boolean>
	{
		return new Observable<boolean>(subscriber => {
			subscriber.next(this._status);
			const subcription = this._statusSubject.subscribe(value => {
				subscriber.next(value);
			});
			return {
				unsubscribe: () => { subcription.unsubscribe() }
			}
		});
	}

	public get client() : Client
	{
		if (!this._client) {
			this._client = new Client();
			this._client.setEndpoint(appwriteConfig.url);
			this._client.setProject(appwriteConfig.project);

			this.checkStatus();
		}
		return this._client;
	}

	public get account() : Account
	{
		if (!this._account) {
			this._account = new Account(this.client);
		}
		return this._account;
	}

	public get teams() : Teams
	{
		if (!this._teams) {
			this._teams = new Teams(this.client);
		}
		return this._teams;
	}

	private _databases : Databases;
	private _status : boolean;
	private _statusSubject : Subject<boolean>;
	private _client : Client;
	private _account : Account;
	private _teams : Teams;

	constructor() {
		this._status = true;
		this._statusSubject = new Subject<boolean>();
		this._databases = null;
		this._client = null;
		this._account = null;
		this._teams = null;
	}

	private async checkStatus()
	{
		// To check if the system works, try to retrieve the flags
		return new Promise<void>(resolve => {
			this.databases.listDocuments(appwriteConfig.database, appwriteConfig.flagsCollectionId, [Query.limit(1)]).then(() => {
				this._status = true;
				this._statusSubject.next(this._status);
				resolve();
			}).catch(() => {
				this._status = false;
				this._statusSubject.next(this._status);
				setTimeout(async () => {
					await this.checkStatus();
				}, 60000);
				resolve();
			});
		});
	}
}
