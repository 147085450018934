import { Observable, Subject } from "rxjs";
import { PilotDataInterface } from "../interfaces/data/pilot-data-interface";
import { GroundElevationService } from "../services/ground-elevation.service";
import { Aircraft } from "./aircraft";
import { CustomerInterface } from "../interfaces/customer-interface";
import { SmartbayConfiguration } from "./smartbay-configuration/smartbay-configuration";
import { TargetOptions } from "./target-options";
import { MissionDataInterface } from "../interfaces/data/mission-data-interface";
import { TargetOptionsDataInterface } from "../interfaces/data/target-options-data-interface";
import { Waypoint } from "./route/waypoint";
import { MissionPhotogrammetryDataBuilder } from "./mission-photogrammetry-data-builder";
import { Route } from "./route/route";
import { FlightPlanningHelper } from "./flight-planning-helper";
import { MassAndBalance } from "./mass-and-balance/mass-and-balance";
import { MassAndBalanceValues } from "./mass-and-balance/mass-and-balance-values";
import { RiskEvaluationLevel } from "./risk-evaluation-level";
import { EventEmitter } from "@angular/core";
import { FlowStatus } from "./flow-status";
import { MinStatusWaypointVisitor } from "./visitors/min-status-waypoint-visitor";
import { AreaStrategyResultsInterface } from "../interfaces/area-strategy-results-interface";
import { AreaStrategyResultsDataInterface } from "../interfaces/data/area-strategy-results-data-interface";
import { Segment } from "./segment";
import { AirportService } from "../services/airport.service";
import { TargetService } from "../services/target.service";
import { PilotService } from "../services/pilot.service";
import { Polygon } from "./polygon";
import { TargetWaypoint } from "./route/target-waypoint";
import { AircraftService } from "../services/aircraft.service";
import { ChangeStatusWaypointVisitor } from "./visitors/change-status-waypoint-visitor";
import { TargetInterface } from "../interfaces/target-interface";
import { ContainsTargetWaypointVisitor } from "./visitors/contains-target-waypoint-visitor";
import { Leg } from "./leg";
import { TranslatedGenericError } from "./errors/translated-generic-error";

export class Mission {

    /**
     * Called once the mission has been built
     */
    public get onAfterBuild() : Observable<void>
    {
        return this._onAfterBuid;
    }

    /**
     * The current flow status of this mission.
     * It is actually the lowest flowstatus of the waypoints in this mission.
     */
    public get flowStatus() : FlowStatus
    {
        if (this._targetOptions.length == 0) {
            return this._defaultFlowStatus;
        }
        const visitor = new MinStatusWaypointVisitor();
        this.route.acceptWaypointVisitor(visitor);
        return visitor.minFlowStatus;
    }

    public get customer() : CustomerInterface
    {
        return null;
    }

    public get smartBayConfig() : SmartbayConfiguration
    {
        return this._smartBayConfig;
    }

    public get massAndBalance() : MassAndBalance
    {
        return this._massAndBalance;
    }

    /** The minimum fuel required onboard to complete the mission */
    public get minimumFuelMass() : number
    {
        return this._minimumFuelKg;
    }

    /**
     * True if this mission contains valid targets data
     * to be used in a SPO
     */
    public get isSPO() : boolean
    {
        return this.built && Object.keys(this._cachedLegsData).length > 0;
    }

    /** The estimated flight time without accounting for alternate, contingency etc in ms... */
    public get estimatedFlightTime() : number
    {
        return this._estimatedFlightTime;
    }

    public get route() : Route
    {
        return this._route;
    }

    /**
     * The default GSD used when adding a new target
     * to this mission.
     * This value is ignored if the target contains
     * information about a more specific GSD to use.
     */
    public defaultGsd : number;

    /**
     * The default SideLap (%) used when adding a new target
     * to this mission.
     */
    public defaultSidelap : number;

    /**
     * The default OverLap (%) used when adding a new target
     * to this mission.
     */
    public defaultOverlap : number;

    public set route(r : Route)
    {
        this._route = r;
        this._route.onWaypointAdded.subscribe((waypoint : Waypoint) => {
            this._needsRebuild = true;
            this._needsSaving = true;
            if (waypoint.type == "target") {
                // Use the waypoint name to identify the options
                const opt = new TargetOptions(waypoint.name);
                opt.overlap = this.defaultOverlap;
                opt.sidelap = this.defaultSidelap;
                opt.resolution = this.defaultGsd;
                // Set the default GSD based on the target data, if present
                const target = (waypoint as TargetWaypoint).target;
                if (target && target.resolution > 0) {
                    opt.resolution = target.resolution;
                }
                this.setTargetOptions(opt);
            }
        });
        this._route.onWaypointRemoved.subscribe((waypoint : Waypoint) => {
            if (waypoint.type == "target") {
                // Use the waypoint name to identify the options
                const index = this._targetOptions.findIndex(opt => opt.targetId == waypoint.name);
                this._needsRebuild = true;
                this._needsSaving = true;
                if (index != -1) {
                    this._targetOptions.splice(index, 1);
                }
            }
        });
        this._route.onRouteChanged.subscribe(() => {
            this._needsRebuild = true;
            this._needsSaving = true;
        });
    }

    public get aircraft() : Aircraft
    {
        return this._aircraft;
    }

    public set aircraft(acf : Aircraft)
    {
        if (!acf.equals(this._aircraft)) {
            this._aircraft = acf;
            this._minimumFuelKg = 0;
            this._needsRebuild = true;
            this._needsSaving = true;
            this._massAndBalance = MassAndBalance.fromDataInterface(this._aircraft.massAndBalanceData, this._smartBayConfig);
        }
    }

    /**
     * Get the lateral deviation in meter allowed in the
     * legs (tunnel) of this mission
     */
    public get latDeviation() : number
    {
        return this._latDeviation;
    }

    /**
     * Set the lateral deviation in meter allowed in the
     * legs (tunnel) of this mission
     */
    public set latDeviation(v : number)
    {
        if (this._latDeviation != v) {
            this._latDeviation = v;
            this._needsRebuild = true;
            this._needsSaving = true;
        }
    }

    /**
     * The final reserve fuel in minutes
     */
    public get finalReserveMinutes() : number
    {
        return this._finalReserverMinutes;
    }

    /**
     * Set the final reserve fuel in minutes
     */
    public set finalReserveMinutes(minutes : number)
    {
        if (this._finalReserverMinutes != minutes) {
            this._finalReserverMinutes = minutes;
            this._needsRebuild = true;
            this._needsSaving = true;
        }
    }

    public get name() : string
    {
        return this._name;
    }

    public set name(n : string)
    {
        this._name = n;
        this._needsSaving = true;
    }

    public get takeoffTime() : number
    {
        return this._takeoffTime;
    }

    /**
     * The estimated takeoff UTC timestamp
     */
    public set takeoffTime(t : number)
    {
        if (this._takeoffTime != t) {
            this._takeoffTime = t;
            this._needsSaving = true;
        }
    }

    /**
     * The estimated landing UTC timestamp
     */
    public get landingTime() : number
    {
        if (this._takeoffTime > 0 && this.estimatedFlightTime > 0) {
            return this._takeoffTime + this.estimatedFlightTime;
        }
        return 0;
    }

    public get executionTime() : number
    {
        return this._executionTime;
    }

    public set executionTime(t : number)
    {
        if (this._executionTime != t) {
            this._executionTime = t;
            this._needsSaving = true;
        }
    }

    public get uploadTime() : number
    {
        return this._uploadTime;
    }

    public set uploadTime(t : number)
    {
        if (this._uploadTime != t) {
            this._uploadTime = t;
            this._needsSaving = true;
        }
    }

    public get pic() : PilotDataInterface
    {
        return this._pic;
    }

    public set pic(p : PilotDataInterface) {
        if ((!p && !!this._pic) || (!!p && p.email != this._pic?.email)) {
            this._pic = p;
            this._needsSaving = true;
        }
    }

    public get dispatcher() : PilotDataInterface
    {
        return this._dispatcher;
    }

    public set dispatcher(p : PilotDataInterface) {
        if ((!p && !!this._dispatcher) || (!!p && p.email != this._dispatcher?.email)) {
            this._dispatcher = p;
            this._needsSaving = true;
        }
    }

    public get groundSupport() : PilotDataInterface
    {
        return this._groundSupport;
    }

    public set groundSupport(g : PilotDataInterface)
    {
        if ((!g && !!this._groundSupport) || (!!g && g.email != this._groundSupport?.email)) {
            this._groundSupport = g;
            this._needsSaving = true;
        }
    }
    
    public get authorityRefCode() : string
    {
        return this._authorityRefCode;
    }

    public set authorityRefCode(c : string)
    {
        this._authorityRefCode = c;
        this._needsSaving = true;
    }

    public get customerRefCode() : string
    {
        return this._customerRefCode;
    }

    public set customerRefCode(c : string)
    {
        this._customerRefCode = c;
        this._needsSaving = true;
    }

    public get internalRefCode() : string
    {
        return this._internalRefCode;
    }

    public set internalRefCode(c : string)
    {
        this._internalRefCode = c;
        this._needsSaving = true;
    }

    public get ppr(): string
    {
        return this._ppr;
    }

    public set ppr(p : string)
    {
        this._ppr = p;
        this._needsSaving = true;
    }

    public get notes() : string
    {
        return this._notes;
    }

    public set notes(n : string)
    {
        this._notes = n;
        this._needsSaving = true;
    }

    public get riskEvaluationLevel() : RiskEvaluationLevel
    {
        return this._riskEvaluationLevel;
    }

    public set riskEvaluationLevel(l : RiskEvaluationLevel)
    {
        this._riskEvaluationLevel = l;
        this._needsSaving = true;
    }

    public get riskEvaluationNotes() : string
    {
        return this._riskEvaluationNotes;
    }

    public set riskEvaluationNotes(n : string)
    {
        this._riskEvaluationNotes = n;
        this._needsSaving = true;
    }

    /**
     * True if the mission configuration is changed and it needs a rebuild
     */
    public get needsRebuild() : boolean
    {
        return this._needsRebuild || Object.keys(this._cachedLegsData).length != this._targetOptions.length;
    }

    /**
     * True if the mission configuration is changed and it needs to be saved
     */
    public get needsSaving() : boolean
    {
        return this._needsSaving;
    }

    public readonly onTargetOptionsChange : EventEmitter<void>;

    public built : boolean;

    private _name : string
    private _takeoffTime : number;
    private _executionTime : number;
    private _uploadTime : number;
    private _pic : PilotDataInterface;
    private _dispatcher : PilotDataInterface;
    private _groundSupport : PilotDataInterface;
    private _authorityRefCode : string;
    private _customerRefCode : string;
    private _internalRefCode : string;
    private _ppr: string;
    private _notes : string;
    private _riskEvaluationLevel : RiskEvaluationLevel;
    private _riskEvaluationNotes : string;
    private _targetOptions : TargetOptions[];
    private _onAfterBuid : Subject<void>;
    private _route : Route;
    private _minimumFuelKg : number;
    private _finalReserverMinutes: number;
    private _massAndBalance : MassAndBalance;
    private _latDeviation : number;
    private _needsRebuild : boolean;
    private _needsSaving : boolean;
    private _defaultFlowStatus : FlowStatus;
    private _cachedLegsData : { (key : string) : AreaStrategyResultsInterface } | {};

    constructor(
        public id : string,
        public readonly timestamp : number,
        private _aircraft : Aircraft,
        private _smartBayConfig : SmartbayConfiguration,
        private _estimatedFlightTime : number = 0
    )
    {
        this.defaultGsd = 7.5;
        this.defaultSidelap = 0.2;
        this.defaultOverlap = 0.7;
        this._authorityRefCode = "";
        this.built = false;
        this._finalReserverMinutes = 30;
        this._customerRefCode = "";
        this._defaultFlowStatus = FlowStatus.Todo;
        this._ppr = "";
        this._notes = "";
        this._cachedLegsData = {};
        this._internalRefCode = "";
        this._latDeviation = 30;
        this._targetOptions = [];
        this._onAfterBuid = new Subject<void>();
        this._massAndBalance = MassAndBalance.fromDataInterface(this._aircraft.massAndBalanceData, this._smartBayConfig);
        this._massAndBalance.onChange.subscribe(() => { this._needsSaving = true; });
        this.smartBayConfig.onChange.subscribe(() => { this._needsRebuild = true; this._needsSaving = true; });
        this._takeoffTime = 0;
        this._executionTime = 0;
        this._uploadTime = 0;
        this._riskEvaluationLevel = "no-action";
        this._riskEvaluationNotes = "";
        this._pic = null;
        this._dispatcher = null;
        this._groundSupport = null;
        this._minimumFuelKg = 0;
        this.onTargetOptionsChange = new EventEmitter();
        this.route = new Route();
        this._needsRebuild = false;
        this._needsSaving = false;
    }

    /**
     * Fully rebuild this mission
     * 
     * @param elevationService The ground elevation service
     * @param progressCallback A progress callback used to provide updates on the building process.
     *                         The callback receives progress (0 -> 1) parameter and a labelId that identifies the localization to show
    */
    public async build(elevationService? : GroundElevationService, progressCallback : (progress : number, labelId : string) => Promise<void> = null) : Promise<void>
    {
        const builder = new MissionPhotogrammetryDataBuilder(elevationService);

        if (!builder.canBuild(this)) {
            console.log("Cannot build mission: some strategies are not ready");
            this.built = false;
            throw new TranslatedGenericError("mission.invalidStrategy");
        }

        this._needsRebuild = false;
        this._needsSaving = true;

        if (!this._smartBayConfig.sensorsTrolleyPairs.length) {
            console.warn("Cannot build the mission because no sensor or trolleys are set");
            this.built = false;
            throw new TranslatedGenericError("mission.buildError");
        }

        // A target included in this mission could have been deleted from the areas management
        // and so the target options are to be cleaned up
        if (this._targetOptions.length != this._route.waypoints.filter(wp => wp.type == "target").length) {
            console.log("Cleaning unused target options before building the mission...");
            for (let i = 0; i < this._targetOptions.length; i++) {
                if (this._route.waypoints.findIndex(wp => wp.type == "target" && wp.name == this._targetOptions[i].targetId) == -1) {
                    this._targetOptions.splice(i, 1);
                }
            }
        }

        const results = await builder.build(this, progressCallback);

        this._cachedLegsData = results.legsData;

        if (Object.keys(this._cachedLegsData).length != this._targetOptions.length) {
            console.warn("Invalid builld. Cached targets count (" + Object.keys(this._cachedLegsData).length + ") and target options count (" + this._targetOptions.length + ") do not match.");
            this.built = false;
            throw new TranslatedGenericError("mission.buildError");
        }
        
        // Update the ETE
        if (!!this.route.departure && !!this.route.destination) {
            const helper = new FlightPlanningHelper(this.aircraft, this.finalReserveMinutes);
            this._estimatedFlightTime = helper.getRouteETE(this.route.toWaypointsArray(false));
            this._minimumFuelKg = helper.getMinimumRampFuel(this.route);
        }

        this.built = true;
        this._onAfterBuid.next();
    }

    /**
     * Set the status for this mission.
     * 
     * @param status The status to set
     * @param targetService The target service to use. If null, the mission's targets status won't be saved
     */
    public async setFlowStatus(status : FlowStatus, targetService : TargetService = null)
    {
        const visitor = new ChangeStatusWaypointVisitor(status);
        this.route.acceptWaypointVisitor(visitor);
        if (!!targetService) {
            await visitor.save(targetService);
        }
        this._defaultFlowStatus = status;
        this._needsSaving = true;
    }

    /**
     * Set the legs data for a specific target in this mission.
     * The data is overwritten the first time the mission is built.
     * 
     * @param uuid The target uuid
     * @param data The photogrammetry results for the specific area
     */
    public setLegsDataFor(uuid : string, data : AreaStrategyResultsInterface) : void
    {
        this._cachedLegsData[uuid] = data;
    }

    /**
     * Provide the legs data interface for a specific target.
     * If the mission has not been built, the data for this target is null.
     * 
     * @param target The target
     * @returns The AreaStrategyResultsInterface related to the provided target or null if the data is not available
     */
    public getLegsDataFor(target : TargetInterface) : AreaStrategyResultsInterface
    {
        if (this._cachedLegsData[target.uuid]) {
            return this._cachedLegsData[target.uuid];
        }
        return null;
    }

    /**
     * Get the target options for the specified target uuid
     * 
     * @param target The target
     * @returns The target options or null if target is not found
     */
    public getTargetOptions(target : TargetInterface) : TargetOptions
    {
        const options = this._targetOptions.find(o => o.targetId == target.uuid);
        if (options) {
            return options;
        }
        return null;
    }

    public setTargetOptions(options : TargetOptions)
    {
        const index = this._targetOptions.findIndex(o => o.targetId == options.targetId);
        if (index > -1) {
            this._targetOptions.splice(index, 1, options);
        }
        else {
            this._targetOptions.push(options);
        }
        options.onChange.subscribe(() => {
            this._needsRebuild = true;
            this._needsSaving = true;
            this.onTargetOptionsChange.emit();
        });
        this._needsRebuild = true;
        this._needsSaving = true;
    }

    /**
     * Search a target in this mission
     * 
     * @param target The target to look for
     * @return True if the target is in the mission targets list
     */
    public containsTarget(target : TargetInterface) : boolean
    {
        const visitor = new ContainsTargetWaypointVisitor(target);
        this.route.acceptWaypointVisitor(visitor);
        return visitor.result;
    }

    /** Get the Estimate Time Enroute of a waypoint in milliseconds */
    public getWaypointETE(waypoint : Waypoint) : number
    {
        if (!this.built) {
            console.warn("Mission.getTargetETE: mission is not built yet");
            return 0;
        }
        
        const helper = new FlightPlanningHelper(this.aircraft);
        const wps = this.route.toWaypointsArray(false);
        return helper.getRouteETE(wps, wps.findIndex(wp => wp.equals(waypoint)));
    }

    /** Get the Estimate Time of Arrival to a waypoint in milliseconds UTC */
    public getWaypointETA(waypoint : Waypoint) : number
    {
        if (!this.takeoffTime) {
            return 0;
        }

        return this.getWaypointETE(waypoint) + this.takeoffTime;
    }

    /**
     * Get the minimum fuel onboard at a specific waypoint
     * 
     * @param waypoint The waypoint to get the MFOB for
     */
    public getWaypointMFOB(waypoint: Waypoint) : number
    {
        if (!this.built) {
            console.warn("Mission.getTargetETE: mission is not built yet");
            return 0;
        }
        
        const helper = new FlightPlanningHelper(this.aircraft);
        const wps = this.route.toWaypointsArray(false);
        return helper.getMinimumFuelOnBoard(this.route, wps.findIndex(wp => wp.equals(waypoint)));
    }

    /**
     * Replace the current smartbay configuration.
     * 
     * @param config The new smartbay configuration
     */
    public async setSmartbayConfig(config : SmartbayConfiguration)
    {
        // Reset the custom settings if necessary
        if (!config.equals(this.smartBayConfig)) {
            this._needsRebuild = true;
            this._needsSaving = true;
            for (let option of this._targetOptions) {
                option.activeSensorsIndexes = [];
                option.primarySensorIndex = -1;
            }
        }
        this._smartBayConfig = config;
    }

    public toDataInterface() : MissionDataInterface
    {
        const targetOptions : TargetOptionsDataInterface[] = [];
        for (const t of this._targetOptions) {
            targetOptions.push(t.toDataInterface());
        }

        const mbValues = new MassAndBalanceValues();
        mbValues.readFromMassAndBalance(this.massAndBalance);

        const areaData = {};
        for (const key in this._cachedLegsData) {
            const legPaths : number[][][] = [];
            const legAltitudes : number[] = [];
            for (const leg of this._cachedLegsData[key].legs) {
                legPaths.push(leg.path);
                legAltitudes.push(leg.altitude);
            }
            const data : AreaStrategyResultsDataInterface = {
                legPaths: legPaths,
                legAltitudes: legAltitudes,
                maneuveringAreaPath: this._cachedLegsData[key].maneuveringArea.path,
                legsBoxPath: this._cachedLegsData[key].legsBox.path,
                sensorsData: this._cachedLegsData[key].sensorsData
            };
            areaData[key] = data;
        }
        
        return {
            aircraftRegistration: this.aircraft.registration,
            authorityRefCode: this.authorityRefCode,
            built: this.built,
            photogrammetricData: areaData,
            customerRefCode: this.customerRefCode,
            ete: this.estimatedFlightTime,
            groundSupport: this.groundSupport?.email ?? "",
            id: this.id,
            internalRefCode: this.internalRefCode,
            latDeviation: this.latDeviation,
            name: this.name,
            finResMins: this._finalReserverMinutes,
            pic: this.pic?.email ?? "",
            dispatcher: this.dispatcher?.email ?? "",
            smartbayConfig: this.smartBayConfig.toDataInterface(),
            takeoffTimeIso: (this.takeoffTime > 0 ? (new Date(this.takeoffTime)).toISOString() : null),
            executionTimeIso : (this._executionTime > 0 ? (new Date(this._executionTime)).toISOString() : null),
            uploadTimeIso: (this._uploadTime > 0 ? (new Date(this._uploadTime)).toISOString() : null),
            targetOptions: targetOptions,
            timestampIso: (this.timestamp > 0 ? (new Date(this.timestamp)).toISOString() : null),
            needsRebuild: this._needsRebuild,
            route: this.route.toDataInterface(),
            massAndBalanceValues: mbValues.toDataInterface(),
            ppr: this.ppr,
            minimumFuelKg: this._minimumFuelKg,
            notes: this.notes,
            riskEvaluationLevel: this.riskEvaluationLevel,
            riskEvaluationNotes: this.riskEvaluationNotes,
            defaultFlowStatus: this._defaultFlowStatus.toString()
        };
    }

    /**
     * To be called after a mission is saved
     */
    public onAfterSave() : void
    {
        this._needsSaving = false;
    }

    /**
     * Order two missions ascending based on the creation and takeoff times.
     * 
     * @param a The first mission
     * @param b The second mission
     * @returns 1, -1, or 0 depending on the missions takeoff and creation times
     */
    public static orderAscending(a : { takeoffTime: number, timestamp: number }, b : { takeoffTime: number, timestamp: number }) : number
    {
        if (!a.takeoffTime || !b.takeoffTime) {
            if (a.timestamp > b.timestamp) {
                return 1;
            }
            if (a.timestamp < b.timestamp) {
                return -1;
            }
            return 0;
        }
        if (a.takeoffTime > b.takeoffTime) {
            return 1;
        }
        if (a.takeoffTime < b.takeoffTime) {
            return -1;
        }
        return 0;
    }

    /**
     * Order two missions descending based on the creation and takeoff times.
     * 
     * @param a The first mission
     * @param b The second mission
     * @returns 1, -1, or 0 depending on the missions takeoff and creation times
     */
    public static orderDescending(a : { takeoffTime: number, timestamp: number }, b : { takeoffTime: number, timestamp: number }) : number
    {
        if (!a.takeoffTime || !b.takeoffTime) {
            if (a.timestamp < b.timestamp) {
                return 1;
            }
            if (a.timestamp > b.timestamp) {
                return -1;
            }
            return 0;
        }
        if (a.takeoffTime < b.takeoffTime) {
            return 1;
        }
        if (a.takeoffTime > b.takeoffTime) {
            return -1;
        }
        return 0;
    }

    public static getDefaultInterface() : MissionDataInterface
    {
        return {
            aircraftRegistration: "",
            authorityRefCode: "",
            built: false,
            minimumFuelKg: 0,
            customerRefCode: "",
            ete: 0,
            groundSupport: "",
            id: "",
            photogrammetricData: {},
            internalRefCode: "",
            latDeviation: 30,
            finResMins: 30,
            needsRebuild: false,
            name: "",
            pic: "",
            dispatcher: "",
            smartbayConfig: SmartbayConfiguration.defaultConfiguration.toDataInterface(),
            takeoffTimeIso: null,
            executionTimeIso: null,
            uploadTimeIso: null,
            targetOptions: [],
            timestampIso: null,
            ppr: "",
            riskEvaluationLevel: "no-action",
            riskEvaluationNotes: "",
            notes: "",
            massAndBalanceValues: [],
            defaultFlowStatus: FlowStatus.Todo.toString(),
            route: (new Route()).toDataInterface()
        };
    }

    public static async fromDataInterface(int : MissionDataInterface, airportService : AirportService, targetService : TargetService, pilotService : PilotService, aircraftService : AircraftService) : Promise<Mission>
    {
        let smartBayConfig = SmartbayConfiguration.defaultConfiguration;

        // New smartbay config
        if (!!int.smartbayConfig) {
            smartBayConfig = SmartbayConfiguration.fromDataInterface(int.smartbayConfig);
        }

        const acf = await aircraftService.fromRegistration(int.aircraftRegistration);
        if (acf == null) {
            console.error("Invalid aircraft for mission " + int.id + " (" + int.name + ")");
        }
        let mission = new Mission(int.id, (new Date(int.timestampIso)).getTime(), acf, smartBayConfig, int.ete ? int.ete : 0);
        mission._name = int.name;
        
        mission._takeoffTime = int.takeoffTimeIso ? (new Date(int.takeoffTimeIso)).getTime() : 0;
        mission._executionTime = int.executionTimeIso ? (new Date(int.executionTimeIso)).getTime() : 0;
        mission._uploadTime = int.uploadTimeIso ? (new Date(int.uploadTimeIso)).getTime() : 0;
        mission._pic = int.pic ? await pilotService.get(int.pic) : null;
        mission._dispatcher = !!int.dispatcher ? await pilotService.get(int.dispatcher) : null;
        mission._groundSupport = int.groundSupport ? await pilotService.get(int.groundSupport) : null;
        mission._authorityRefCode = int.authorityRefCode;
        mission.built = int.built;
        mission._customerRefCode = int.customerRefCode;
        mission._internalRefCode = int.internalRefCode;
        mission._latDeviation = int.latDeviation;
        mission._notes = int.notes;
        mission._ppr = int.ppr;
        mission._minimumFuelKg = int.minimumFuelKg ?? 0;
        mission._estimatedFlightTime = int.ete;
        mission._riskEvaluationLevel = !!int.riskEvaluationLevel ? int.riskEvaluationLevel : "no-action";
        mission._riskEvaluationNotes = int.riskEvaluationNotes;
        if (int.finResMins > 0) {
            mission._finalReserverMinutes = int.finResMins;
        }

        if (!!int.route) {
            mission.route = await Route.fromDataInterface(int.route, airportService, targetService);
        }

        if (!!int.defaultFlowStatus) {
            mission._defaultFlowStatus = FlowStatus.fromString(int.defaultFlowStatus);
        }

        if (!int.photogrammetricData) {
            int.photogrammetricData = {};
        }
        for (let key in int.photogrammetricData) {
            let legs : Leg[] = [];
            for (let i = 0; i < int.photogrammetricData[key].legPaths.length; i++) {
                const segment = Segment.fromPath(int.photogrammetricData[key].legPaths[i]);
                if (!!int.photogrammetricData[key].legAltitudes && int.photogrammetricData[key].legAltitudes.length) {
                    // Altitude for each leg
                    legs.push(new Leg(segment.start, segment.end, int.photogrammetricData[key].legAltitudes[i]));
                }
                else {
                    // Legacy segments. Use the sensor data altitude
                    legs.push(new Leg(segment.start, segment.end, int.photogrammetricData[key].sensorsData[0].altitude))
                }
            }
            mission.setLegsDataFor(key, {
                legs: legs,
                sensorsData: int.photogrammetricData[key].sensorsData,
                maneuveringArea: Polygon.fromPath(int.photogrammetricData[key].maneuveringAreaPath),
                legsBox: Polygon.fromPath(int.photogrammetricData[key].legsBoxPath)
            });
        }
        
        if (!int.targetOptions) {
            //console.warn("Target options not found for mission " + int.id + " (" + int.name + ")", int);
            int.targetOptions = [];
        }
        for (let option of int.targetOptions) {
            mission.setTargetOptions(TargetOptions.fromDataInterface(option));
        }

        if (!!int.massAndBalanceValues) {
            const mbValues = MassAndBalanceValues.fromDataInterface(int.massAndBalanceValues);
            mbValues.writeToMassAndBalance(mission.massAndBalance);
        }
        
        mission._needsRebuild = !!int.needsRebuild;
        mission._needsSaving = false;

        return mission;
    }
}
