import { Color } from "./color";

/**
 * Represent a status in the mission/target execution flow.
 * 
 * @class FlowStatus
 */
export class FlowStatus {

    /**
     * Get the next status.
     * Null if next status is not available.
     */
    public get next() : FlowStatus
    {
        switch (this._strStatus) {
            case "submission-pending": return FlowStatus.SubmissionAccepted;
            case "submission-rejected": return null;
            case "submission-accepted": return FlowStatus.Todo;
            case "planned": return FlowStatus.Done;
            case "done": return FlowStatus.Validated;
            case "validated": return null;
            default: return FlowStatus.Planned;
        }
    }

    constructor(private _strStatus : "submission-pending" | "submission-accepted" | "submission-rejected" | "to-do" | "planned" | "done" | "validated")
    {

    }

    /**
     * @param other The other flow statys
     * @returns True if the other status equals this.
     */
    public equals(other : FlowStatus) : boolean
    {
        return other._strStatus == this._strStatus;
    }

    /**
     * 
     * @param other The other status
     * @returns 1 if this status is higher that the other. -1 if lower. 0 if equals.
     */
    public compareTo(other : FlowStatus) : number
    {
        if (other._strStatus == this._strStatus) {
            return 0;
        }
        return other.toNumber() > this.toNumber() ? -1 : 1;
    }

    /**
     * Convert a target flow status to number.
     * The higher the status, the higher the number.
     * 
     * @return a number representing the flow status.
    */
    public toNumber() : number
    {
        switch (this._strStatus) {
            case "submission-pending": return 0;
            case "submission-rejected": return 1;
            case "submission-accepted": return 2;
            case "to-do": return 3;
            case "planned": return 4;
            case "done": return 5;
            case "validated": return 6;
        }
    }

    /**
     * Convert this status to string.
     * 
     * @returns A string representing this status
     */
    public toString() : string
    {
        return this._strStatus;
    }

    /**
     * Convert this status to a L10N key useful to get the proper localization
     */
    public toL10nKey() : string
    {
        switch (this._strStatus) {
            case "submission-pending": return "target.flowStatus.submissionPending";
            case "submission-rejected": return "target.flowStatus.submissionRejected";
            case "submission-accepted": return "target.flowStatus.submissionAccepted";
            case "to-do": return "target.flowStatus.todo";
            case "planned": return "target.flowStatus.planned";
            case "done": return "target.flowStatus.done";
            case "validated": return "target.flowStatus.validated";
        }
    }

    /**
     * Convert this status to a CSS color
     */
    public toFillColor() : string
    {
        switch (this._strStatus) {
            case "submission-pending": return Color.Gray;
            case "submission-rejected": return Color.Red;
            case "submission-accepted": return Color.Green;
            case "to-do": return Color.Red;
            case "planned": return Color.Yellow;
            case "done": return Color.Blue;
            case "validated": return Color.Green;
        }
    }

    /**
     * Convert this status to a CSS color
     */
     public toBorderColor() : string
     {
        switch (this._strStatus) {
            case "submission-pending": return Color.Gray;
            case "submission-rejected": return Color.Gray;
            case "submission-accepted": return Color.Gray;
            case "to-do": return Color.Red;
            case "planned": return Color.Yellow;
            case "done": return Color.Blue;
            case "validated": return Color.Green;
        }
     }

    /**
     * Create a new status starting from a number
     * 
     * @param status The status number
     * @returns The flow status instance
     */
    public static fromNumber(status : number) : FlowStatus
    {
        switch (status)
        {
            case 0: return FlowStatus.SubmissionPending;
            case 1: return FlowStatus.SubmissionRejected;
            case 2: return FlowStatus.SubmissionAccepted;
            case 3: return FlowStatus.Todo;
            case 4: return FlowStatus.Planned;
            case 5: return FlowStatus.Done;
            case 6: return FlowStatus.Validated;
        }
    }

    /**
     * Create a new status starting from a string
     * 
     * @param status The status string
     * @returns The flow status instance
     */
    public static fromString(status : string) : FlowStatus
    {
        switch (status)
        {
            case "submission-pending": return FlowStatus.SubmissionPending;
            case "submission-rejected": return FlowStatus.SubmissionRejected;
            case "submission-accepted": return FlowStatus.SubmissionAccepted;
            case "planned": return FlowStatus.Planned;
            case "done": return FlowStatus.Done;
            case "validated": return FlowStatus.Validated;
            default: return FlowStatus.Todo;
        }
    }

    public static get All() : FlowStatus[]
    {
        return [
            FlowStatus.SubmissionPending,
            FlowStatus.SubmissionAccepted,
            FlowStatus.SubmissionRejected,
            FlowStatus.Todo,
            FlowStatus.Planned,
            FlowStatus.Done,
            FlowStatus.Validated,
        ];
    }

    public static get SubmissionPending() : FlowStatus
    {
        return new FlowStatus("submission-pending");
    }
    
    public static get SubmissionAccepted() : FlowStatus
    {
        return new FlowStatus("submission-accepted");
    }
    
    public static get SubmissionRejected() : FlowStatus
    {
        return new FlowStatus("submission-rejected");
    }

    public static get Todo() : FlowStatus
    {
        return new FlowStatus("to-do");
    }

    public static get Planned() : FlowStatus
    {
        return new FlowStatus("planned");
    }

    public static get Done() : FlowStatus
    {
        return new FlowStatus("done");
    }

    public static get Validated() : FlowStatus
    {
        return new FlowStatus("validated");
    }
}
