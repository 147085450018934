import { EventEmitter } from "@angular/core";
import { NoteDataInterface } from "../interfaces/data/note-data-interface";
import { TargetDataInterface } from "../interfaces/data/target-data-interface";
import { PositionInterface } from "../interfaces/position-interface";
import { TargetInterface, TargetLandscapeType, TargetObservableProperties, TargetOverflySlot, TargetOverflyWeather } from "../interfaces/target-interface";
import { TargetVisitorInterface } from "../interfaces/target-visitor-interface";
import { FlowStatus } from "./flow-status";
import { Polygon } from "./polygon";
import { Target } from "./target";

export class TargetsGroup extends Polygon implements TargetInterface {

    public readonly uuid: string;

    public get joinable() : boolean
    {
        return false;
    }

    public get requestType(): string
    {
        return this.targets[0].requestType;
    }

    public readonly organizationTag: string;

    public readonly partnerTag: string;

    public get passThroughParams()
    {
        return [];
    }

    public readonly deadline: Date;
    
    public get outputType(): string[]
    {
        return this.targets[0].outputType;
    }
    
    public readonly resolution: number
    
    public get overflySlot(): TargetOverflySlot
    {
        return this.targets[0].overflySlot;
    }

    public get landscapeType(): TargetLandscapeType
    {
        return this.targets[0].landscapeType;
    }
    
    public get overflyWeather(): TargetOverflyWeather
    {
        return this.targets[0].overflyWeather;
    }
    
    public get callbackUrl(): string
    {
        return "";
    }
    
    public readonly validationNotes: string[];
    
    public get creationTime(): string
    {
        return this.targets[0].creationTime;
    }
    
    public get updateTime(): string
    {
        return this.targets[0].updateTime;
    }
    
    public readonly notes : NoteDataInterface[];

    public get length() : number
    {
        return this.targets.length;
    }

    /** Get the area in square meters */
    public get area() : number
    {
        if (!this._mArea) {
            for (let t of this.targets) {
                this._mArea += t.area;
            }
        }
        return this._mArea;
    }

    /**
     * The target status in the mission flow.
     */
    public get flowStatus() : FlowStatus
    {
        return this._mflowStatus;
    }

    public set flowStatus(status : FlowStatus)
    {
        for (let target of this.targets) {
            target.flowStatus = status;
        }
    }

    public get countryCode(): string {
        return this.targets[0].countryCode;
    }

    public readonly commercialArea: number;

    public readonly onChange: EventEmitter<TargetObservableProperties>;

    private _mArea : number;
    private _mflowStatus : FlowStatus;

    constructor(public targets : TargetInterface[])
    {
        if (targets.length == 0) {
            throw "TargetsGroup: Invalid targets count";
        }

        super(TargetsGroup.targetsToPositions(targets));
        this._mArea = 0;
        this.uuid = TargetsGroup.targetsToName(targets);

        const organizationTags = [];
        const partnerTags = [];
        this.deadline = null;
        this.validationNotes = [];
        this.notes = [];
        this.commercialArea = 0;
        this.onChange = new EventEmitter<TargetObservableProperties>();

        for (let target of targets) {

            if (organizationTags.indexOf(target.organizationTag) == -1) {
                organizationTags.push(target.organizationTag);
            }

            if (partnerTags.indexOf(target.partnerTag) == -1) {
                partnerTags.push(target.partnerTag);
            }

            if (!!target.deadline && (!this.deadline || target.deadline.getTime() < this.deadline.getTime())) {
                this.deadline = target.deadline;
            }

            for (let note of target.validationNotes) {
                this.validationNotes.push(target.uuid + " - " + note);
            }

            this.commercialArea += target.commercialArea;

            // Subscribe to flow updates
            target.onChange.subscribe((property) => {
                if (property == "flowStatus") {
                    this.updateFlowStatus();
                }
            });

            for (let note of target.notes) {
                const mnote : NoteDataInterface = JSON.parse(JSON.stringify(note));
                mnote.text = target.uuid + " - " + mnote.text;
                this.notes.push(mnote);
            }
        }

        this.organizationTag = organizationTags.join(", ");
        this.partnerTag = partnerTags.join(", ");

        this.updateFlowStatus();
    }

    public toDataInterface(): TargetDataInterface {
        throw new Error("Method not supported");
    }

    public equals(other: TargetInterface): boolean {
        return other.uuid == this.uuid;
    }

    public acceptTargetVisitor(visitor: TargetVisitorInterface): void {
        visitor.visitTargetGroup(this);
    }

    public includes(target : TargetInterface) {
        for (let t of this.targets) {
            if (t.equals(target)) {
                return true;
            }
        }
        return false;
    }

    public includesAny(others : TargetInterface[]) {
        for (let other of others) {
            for (let t of this.targets) {
                if (other.equals(t)) {
                    return true;
                }
            }
        }
        return false;
    }

    private updateFlowStatus()
    {
        const targets : TargetInterface[] = [];
        for (let target of this.targets) {
            targets.push(target);
        }
        targets.sort(Target.orderByFlowStatus);
        this._mflowStatus = targets[0].flowStatus;
        this.onChange.emit("flowStatus");
    }

    private static targetsToPositions(targets : TargetInterface[]) : PositionInterface[]
    {
        let vertexes = [];
        for (let target of targets) {
            for (let vertex of target.vertices) {
                vertexes.push(vertex);
            }
        }
        return vertexes;
    }
    
    private static targetsToName(targets : TargetInterface[]) : string
    {
        const names = [];
        const sortedTargets = targets.slice();
        sortedTargets.sort(Target.orderByUUID);
        for (let target of sortedTargets) {
            names.push(target.uuid);
        }
        return names.join("-");
    }

    /**
     * Build a new TargetsGroup based on the desired uuid and a targets pool
     * 
     * @param uuid The desired uuid
     * @param targets The targets pool
     */
    public static fromUuidAndTargets(uuid : string, targets : TargetInterface[]) : TargetsGroup
    {        
        const parts = uuid.split("-");
        if (parts.length <= 1) {
            return null;
        }

        const groupTargets : TargetInterface[] = [];
        let prev = "";
        for (const part of parts) {
            const target = targets.find(t => t.uuid.toUpperCase() == (prev + part).toUpperCase());
            // Look for unique targets with a dash in the name
            if (!target) {
                prev += part + "-";
                continue;
            }
            prev = "";
            if (groupTargets.findIndex(t => t.uuid.toUpperCase() == target.uuid) == -1) {
                groupTargets.push(target);
            }
        }
        if (groupTargets.length == 0) {
            console.log("Cannot build target group", uuid);
            return null;
        }
        return new TargetsGroup(groupTargets);
    }
}
