import { Injectable } from '@angular/core';
import { Observable, Subject, subscribeOn } from 'rxjs';
import { Helpers } from 'src/app/classes/helpers';
import { AuthServiceInterface, TeamInterface } from 'src/app/interfaces/auth-service-interface';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TestAuthService implements AuthServiceInterface {

    public onSignin: Subject<void>;
    public onBeforeSignout: Subject<void>;
    public signedIn: Observable<boolean>;
    public currentUserEmail: string;

    private _signedInSubject : Subject<boolean>;
    private _signedIn : boolean;

    constructor(private _storage : LocalStorageService)
    {
        this._signedInSubject = new Subject<boolean>();
        this.onSignin = new Subject();
        this.onBeforeSignout = new Subject();
        this.currentUserEmail = "nicolotti@digisky.it";
        this._signedIn = undefined;
        this.signedIn = new Observable(subscriber => {
            const sub = this._signedInSubject.subscribe(next => {
                subscriber.next(next);
            });
            if (this._signedIn != undefined) {
                subscriber.next(this._signedIn);
            }
            return {
                unsubscribe: () => { sub.unsubscribe(); }
            }
        });
        this._storage.get("auth.signedin").then(val => {
            this._signedIn = !!val;
            this._signedInSubject.next(this._signedIn);
        });
    }

    public async signInWithCredential(email: string, password: string): Promise<any>
    {
        this._storage.set("auth.signedin", true);
        this._signedIn = true;
        this._signedInSubject.next(true);
        this.onSignin.next();
    }

    public async signOut(): Promise<any>
    {
        this.onBeforeSignout.next();
        this._storage.set("auth.signedin", false);
        this._signedIn = false;
        this._signedInSubject.next(false);
    }

    public getUserTeams(): TeamInterface[]
    {
        return [{
            id: "digisky",
            name: "Digisky"
        }];
    }
    
    public async sendPasswordRecoveryEmail(email : string): Promise<void> {}

    public async recoverPasswordWithSecret(password: string, userId : string, secret : string): Promise<void> {}

    public async updatePassword(oldPassword: string, password: string, control: string): Promise<void> {}

    public async validateNewPassword(password: string, control: string): Promise<void> {}
}
