import { AreaStrategyResultsInterface } from "src/app/interfaces/area-strategy-results-interface";
import { PhotogrammetryStrategyInterface } from "src/app/interfaces/photogrammetry-strategy-interface";
import { PositionInterface } from "src/app/interfaces/position-interface";
import { SensorStrategyResultsInterface } from "src/app/interfaces/sensor-strategy-results-interface";
import { TargetInterface } from "src/app/interfaces/target-interface";
import { GroundElevationService } from "src/app/services/ground-elevation.service";
import { Aircraft } from "../aircraft";
import { TranslatedGenericError } from "../errors/translated-generic-error";
import { Helpers } from "../helpers";
import { Leg } from "../leg";
import { Polygon } from "../polygon";
import { Position } from "../position";
import { Segment } from "../segment";
import { SmartbayConfiguration } from "../smartbay-configuration/smartbay-configuration";
import { TargetOptions } from "../target-options";
import { PolygonInterface } from "src/app/interfaces/polygon-interface";

/**
 * Provide the photogrammetry data based on a set of custom 
 * legs manually inserted in the target options.
 */
export class ManualLegsStrategy implements PhotogrammetryStrategyInterface {

    public get isValid() : boolean
    {
        return this._targetOptions.customLegs.length > 0;    
    }

    public get maneuveringArea(): Polygon {
        // Extend the buffered box for 1km on every long side and 500m on every short side
        if (!this._maneuveringArea) {
            const longSideBuffer = 1500;
            const shortSideBuffer = 300;
            let vertexes = [];
            let longestSide = this.bufferedBox.longestSide;
            let shortestSide = this.bufferedBox.shortestSide;
            let point = Position.fromInterface(longestSide.start);
            point = Position.fromInterface(point.offset(longestSide.reverseHeading, longSideBuffer));
            point = Position.fromInterface(point.offset(shortestSide.reverseHeading, shortSideBuffer));
            vertexes.push(point);
            point = Position.fromInterface(point.offset(longestSide.heading, 2 * longSideBuffer + longestSide.length));
            vertexes.push(point);
            point = Position.fromInterface(point.offset(shortestSide.heading, 2 * shortSideBuffer + shortestSide.length));
            vertexes.push(point);
            point = Position.fromInterface(point.offset(longestSide.reverseHeading, 2* longSideBuffer + longestSide.length));
            vertexes.push(point);
            this._maneuveringArea = new Polygon(vertexes);
        }
        return this._maneuveringArea;
    }

    public get legsBox(): Polygon {
        if (!this._legsBox) {
            const points : PositionInterface[] = [];

            // Build a special legs box
            if (this._targetOptions.customLegs.length == 1) {
                const segment = new Segment(this._targetOptions.customLegs[0].start, this._targetOptions.customLegs[0].end);
                
                points.push(Position.fromInterface(segment.start).offset((segment.heading + 90) % 360, 100));
                points.push(Position.fromInterface(segment.end).offset((segment.heading + 90) % 360, 100));
                points.push(Position.fromInterface(segment.start).offset((segment.heading + 270) % 360, 100));
                points.push(Position.fromInterface(segment.end).offset((segment.heading + 270) % 360, 100));
            }
            else {
                for (let leg of this._targetOptions.customLegs) {
                    points.push({
                        latitude: leg.start.latitude,
                        longitude: leg.start.longitude 
                    });
                    points.push({
                        latitude: leg.end.latitude,
                        longitude: leg.end.longitude 
                    });
                }
            }

            let box = (new Polygon(points)).getMinimumBoundingBox();
            box = box.expandOnMainSideDirection(45);
            box = box.expandOnSecondarySideDirection(45);
            this._legsBox = box;
        }

        return this._legsBox;
    }
    
    public get bufferedBox(): Polygon {
        if (!this._bufferedBox) {
            // Extend the minimum bounding box 150 meters on the longest sides
            const extendM = 150;
            this._bufferedBox = this.legsBox.expandOnMainSideDirection(extendM);
        }
        return this._bufferedBox;
    }

    private _bufferedBox : Polygon;
    private _maneuveringArea : Polygon;
    private _legsBox : Polygon;

    public constructor(
        private _target : TargetInterface, 
        private _config : SmartbayConfiguration,
        private _targetOptions : TargetOptions,
        private _aircraft : Aircraft,
        private _elevationService : GroundElevationService = null
    )
    {
        this._maneuveringArea = null;
        this._bufferedBox = null;
        this._legsBox = null;
    }

    public async generate(progressCallback: (progress: number) => Promise<void>): Promise<AreaStrategyResultsInterface>
    {
        const legs : Leg[] = [];
        let data : SensorStrategyResultsInterface[];
        if (!this._targetOptions.customLegs.length) {
            return {
                legs: [],
                legsBox: null,
                maneuveringArea: null,
                sensorsData: null
            }
        }

        if (progressCallback) {
            await progressCallback(0);
        }

        for (let leg of this._targetOptions.customLegs) {
            legs.push(new Leg(leg.start, leg.end, leg.initialAltitude));
        }

        if (this._elevationService) {
            let targetMinMax = await this._elevationService.getMinMaxElevations(this.legsBox, 75, async (progress : number) => {
                if (progressCallback) {
                    await progressCallback(progress / 3);
                }
            });
            let manMinMax = await this._elevationService.getMinMaxElevations(this.maneuveringArea, 200, async (progress : number) => {
                if (progressCallback) {
                    await progressCallback((1 / 3) + (progress / 3));
                }
            });
            if (targetMinMax !== false && manMinMax !== false) {
                data = await this.getPhotogrammetryData(targetMinMax.min, targetMinMax.max, manMinMax.max);
            }
        }
        else {
            data = await this.getPhotogrammetryData(0, 0, 0);
            if (progressCallback) {
                await progressCallback(0.667);
            }
        }

        if (!data || !data.length) {
            console.warn("Empty photogrammetry data for " + this._target.uuid);
            return;
        }

        return {
            legs: legs,
            legsBox: this.legsBox,
            maneuveringArea: this.maneuveringArea,
            sensorsData: data
        }; 
    }

    /**
     * Get the photogrammetric data calculated using this target, a specific sensor and targetOptions
     * 
     * @param minTargetElevation The minimum ground elevation in the target area
     * @param maxTargetElevation The maximum ground elevation in the target area
     * @param maxAreaElevation The maximum ground elevation in the maneuvering area
     * @param progressCallback A progress callback used to provide updates on the building process.
     */
     public async getPhotogrammetryData(
        minTargetElevation: number,
        maxTargetElevation: number,
        maxAreaElevation: number
    ) : Promise<SensorStrategyResultsInterface[]>
    {
        let results : SensorStrategyResultsInterface[] = [];
        let xStep : number;
        const compxSteps : number[] = [];
        let yStep : number = 0;
        let zStep : number[] = [];

        // Ensure that the primary sensor is the first in line
        const activeConfig = this._config.getActiveSensorsConfiguration(this._targetOptions);
        const trolleySensors = activeConfig.activeSensors;

        for (let sensorIndex = 0; sensorIndex < trolleySensors.length; sensorIndex++) {

            const pair = trolleySensors[sensorIndex];
            if (!pair.sensor.isValid) {
                continue;
            }
            const lensIndex = pair.sensor.lensIndex;
            const warnings : TranslatedGenericError[] = [];
            const errors : TranslatedGenericError[] = [];
            // Altitude over the target
            const altitudeM = this._targetOptions.customLegs[0].initialAltitude;
            const heightM = altitudeM - maxTargetElevation;
            const airworkSpeedMs = this._aircraft.performance.getAirworkSpeed(altitudeM);
            const targetTerrainSeparation = altitudeM - maxTargetElevation;
            const areaTerrainSeparation = altitudeM - maxAreaElevation;
            const reducedTargetTerrainSep = targetTerrainSeparation < Helpers.feetToMeters(500);
            const reducedManAreaTerrainSep = areaTerrainSeparation < Helpers.feetToMeters(500);
            const warnTargetTerrainSep = targetTerrainSeparation < Helpers.feetToMeters(1000);
            const warnManAreaTerrainSep = areaTerrainSeparation < Helpers.feetToMeters(1000);

            if (reducedTargetTerrainSep) {
                warnings.push(new TranslatedGenericError("leg.warning.obsSepTarget", {
                    meters: Math.round(targetTerrainSeparation),
                    feet: Math.round(Helpers.metersToFeet(targetTerrainSeparation))
                }));
            }

            if (reducedManAreaTerrainSep) {
                warnings.push(new TranslatedGenericError("leg.warning.obsSepManArea", {
                    meters: Math.round(areaTerrainSeparation),
                    feet: Math.round(Helpers.metersToFeet(areaTerrainSeparation))
                }));
            }

            // Footprint width
            const SLb = pair.sensor.ccdWidthCm / pair.sensor.lensFocals[lensIndex] * heightM;
            const SLa = pair.sensor.ccdWidthCm / pair.sensor.lensFocals[lensIndex] * targetTerrainSeparation;
            const longestSide = this.bufferedBox.longestSide;

            // Footprint height
            // const ff = pair.sensor.ccdWidthCm / pair.sensor.ccdHeightCm;
            // let SCb = SLb * ff;
            // let SCa = SLa * ff;

            // Distance between legs
            let initialOffsetX = 0;
            let compxStep = 0;
            let totalDistance = 0;

            for (let l = 0; l < this._targetOptions.customLegs.length; l++) {
                if (this._targetOptions.activeLegs.length > 0 && this._targetOptions.activeLegs.findIndex(lg => lg == l) == -1) {
                    continue;
                }
                const leg = this._targetOptions.customLegs[l];
                zStep.push(this._targetOptions.customLegs[l].initialAltitude);
                totalDistance += Position.fromInterface(leg.start).distanceTo(leg.end);
            }

            // console.log(this._target.name, compxSteps);

            // Distance between shots
            const longFOV = 2 * Helpers.radsToDegs(Math.atan(pair.sensor.ccdHeightCm/ (2 * pair.sensor.lensFocals[lensIndex])));
            const latFOV = 2 * Helpers.radsToDegs(Math.atan(pair.sensor.ccdWidthCm / (2 * pair.sensor.lensFocals[lensIndex])));
            const latBaseAngle = Helpers.degsToRads(180 - latFOV) / 2;
            const lonBaseAngle = Helpers.degsToRads(180 - longFOV) / 2;
            yStep = (2 * (1 - this._targetOptions.overlap) * targetTerrainSeparation) / Math.tan(lonBaseAngle);

            const ha = Helpers.squareMetersToHectares(this._target.area);
            const activeLegsCount = this._targetOptions.activeLegs.length == 0 ? this._targetOptions.customLegs.length : this._targetOptions.activeLegs.length;
            const legsCount = activeLegsCount;
            const shotsPerLeg = Math.ceil(longestSide.length / yStep) + 1;
            const shotTime = yStep / airworkSpeedMs;
            const totalShots = activeLegsCount * shotsPerLeg;
            const totalShotsSize = totalShots * pair.sensor.frameSizeMegaByte;
            const overflyTime = (totalDistance / airworkSpeedMs + this._aircraft.minimumInversionTime * activeLegsCount) * 1000;
            const productivity = (ha / overflyTime) * 3600000;
            const sidelapHighestPoint = (SLa - compxStep) / SLa;
            const sidelapLowestPoint = (SLb - compxStep) / SLb;
            const guaranteedSidelap = -(xStep * Math.tan(latBaseAngle)) / (2 * targetTerrainSeparation) + 1;
            const guaranteedOverlap = -(yStep * Math.tan(lonBaseAngle)) / (2 * targetTerrainSeparation) + 1;
            const gsdHighestPoint = SLa * 100 / pair.sensor.ccdWidthPx;
            const highLegsCount = activeLegsCount > 15;

            if (highLegsCount) {
                warnings.push(new TranslatedGenericError("leg.warning.tooManyLegs"));
            }

            let gliderange : PolygonInterface = null;

            // Get the glide range
            if (this._elevationService && this._targetOptions.customLegs.length && !!this._aircraft.glideRatio) {
                const altitude = this._targetOptions.customLegs[Math.floor(this._targetOptions.customLegs.length / 2)].initialAltitude;
                gliderange = await this._elevationService.getGlideRange(this._target.center, altitude, this._aircraft.glideRatio);

                if (!gliderange) {
                    console.error("Cannot get the glide range for " + this._target.uuid);
                }
            }

            results.push({
                sensorIndex: pair.sensorIndex,
                trolleyIndex: pair.trolleyIndex,
                warnings: warnings,
                minTargetElevation: minTargetElevation,
                maxTargetElevation: maxTargetElevation,
                errors: errors,
                uncompensatedXStep: xStep,
                xStep: compxSteps,
                yStep: yStep,
                zStep: zStep,
                initialOffsetX: initialOffsetX,
                totalLegsCount: legsCount,
                effectiveLegsCount: activeLegsCount,
                altitude: altitudeM,
                height: heightM,
                maneuveringObsSeparation: areaTerrainSeparation,
                targetObsSeparation: targetTerrainSeparation,
                reducedManAreaObsSep: reducedManAreaTerrainSep,
                reducedTargetAreaObsSep: reducedTargetTerrainSep,
                warnManAreaObsSep: warnManAreaTerrainSep,
                warnTargetAreaObsSep: warnTargetTerrainSep,
                highLegsCount: highLegsCount,
                shotTime: shotTime,
                shotsPerLeg: shotsPerLeg,
                totalDistance: totalDistance,
                overflyTime: overflyTime,
                totalShots: totalShots,
                productivity: productivity,
                totalShotsSize: totalShotsSize,
                sidelapHighestPoint: sidelapHighestPoint,
                sidelapLowestPoint: sidelapLowestPoint,
                guaranteedSidelap: guaranteedSidelap,
                guaranteedOverlap: guaranteedOverlap,
                gsdHighestPoint: gsdHighestPoint,
                footPrintWidthHighestPoint: SLa,
                footPrintWidthLowestPoint: SLb,
                glideRangeVertices: gliderange?.vertices || null
            });
        }

        return results;
    }
}
